import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import "../../styles/filterpopup.scss";
import React, { useEffect, useState } from "react";
import { Filter, NEW_FILTER } from "../../models/chart";
import { useSelector } from "react-redux";
import { GetChannel } from "../../models/channel";
// import { ChannelType } from "../../../models/channel";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import SelectSourceModal from "./SelectSourceModal";
import FilterPopup from "./filterpopup/FilterPopup";
import Scrollbars from "react-custom-scrollbars";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/Delete.svg";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/openFilter.svg";
import { transformFromFilterDataObject } from "./Utils";
import { deleteFilter, getAllFilters } from "../../api/filter/action";
import { useDispatch } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // maxHeight: "82vh",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column"
};

export default function FilterListModal({ modal, setModal, chart, chartFilter, onChange, handleAllChartsForceReload, blendChannelCols }) {
    const allFilterList = useSelector(state => state.filters.allFilters)
    const groupedData = chart?.channelType
        ? { [chart.channelType?.id ?? chart.channelType]: allFilterList.filter(filter => !chart?.table?.id || filter.reportType === chart.table.id + (chart?.dataLevel ? " - " + chart?.dataLevel : "")) }
        : allFilterList.reduce((group, filter) => ({ ...group, [filter.dataSource?.id ?? filter.dataSource]: (group[filter.dataSource?.id ?? filter.dataSource] ?? []).concat(filter) }), {})

    const [searchText, setSearchText] = useState("");
    const [nextModalOpen, setNextModalOpen] = React.useState(false);
    const [currentFilter, setCurrentFilter] = React.useState(null);
    const dispatch = useDispatch();
    const _getAllFilters = (_) => dispatch(getAllFilters(_));
    const _deleteFilter = (_) => dispatch(deleteFilter(_));

    useEffect(() => {
        _getAllFilters({ dataSource: chart?.channelType });
        setSearchText("");
    }, [modal])

    const applyFilter = (filter) => {
        const currentFilter = transformFromFilterDataObject(filter);
        onChange(Filter.new(
            chart,
            currentFilter.name,
            currentFilter.segment,
            currentFilter.dimensionFilters,
            currentFilter.metricFilters,
            currentFilter.id
        ));
    }

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(!modal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box style={{ padding: "30px", width: "100%", height: "40px", borderBottom: "1px solid #eee", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}> List of Filters</Typography>
                        <CloseIcon onClick={() => setModal(!modal)} />
                    </Box>
                    <Box style={{ padding: "30px" }}>
                        <Box>
                            <TextField
                                placeholder="Type to search"
                                id="outlined-basic"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                size="small"
                                onChange={event => setSearchText(event.target.value)}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0, borderRadius: "8px" }
                                }}
                                style={{ width: "80%" }}
                            />
                            <Button onClick={() => { setModal(false); setCurrentFilter(null); setNextModalOpen(true) }} style={{ width: "18%", marginLeft: "2%", textTransform: "none", height: "40px", borderRadius: "8px" }} variant="outlined" >
                                Create New Filter
                            </Button>
                        </Box>
                        <br />
                        <Box style={{ height: "60vh" }}>
                            <Scrollbars autoHide>
                                {
                                    Object.keys(groupedData)
                                        .map(dataSource => {
                                            const channel = GetChannel(groupedData[dataSource][0]?.dataSource);
                                            return (
                                                <Box style={{ marginBottom: "20px", width: "99%" }}>
                                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: "10px" }}>
                                                        <div>
                                                            <img style={{ marginRight: 16, height: 20, width: 20 }} src={channel?.icon} alt={channel?.title} /></div>
                                                        <div>
                                                            <Typography variant="body1" style={{ fontSize: "20px", fontWeight: 600 }}>
                                                                {channel?.title}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <Box style={{ border: "1px solid #DDDDDD", borderRadius: '4px', padding: "10px 20px" }}>
                                                        {
                                                            groupedData[dataSource]
                                                                .filter(filter => filter.name.toLowerCase().includes(searchText.toLowerCase()))
                                                                .map((appliedFilter, index, filters) => {
                                                                    const filterGroups = appliedFilter.filterGroups;
                                                                    const segments = appliedFilter.additionalFilters[0]?.values?.filter(val => val);
                                                                    const unify = appliedFilter.additionalFilters[0]?.unify_data
                                                                    return (
                                                                        <>
                                                                            <div style={{ padding: "10px 0px" }}>
                                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                    <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 600, marginBottom: "10px" }}>
                                                                                        {appliedFilter.name}
                                                                                    </Typography>
                                                                                    {chart &&
                                                                                        <Typography variant="body1"
                                                                                            style={{ fontSize: "16px", fontWeight: 600, marginBottom: "10px", marginRight: "8px", color: "#0869FB" }}
                                                                                            onClick={() => { applyFilter(appliedFilter) }}
                                                                                        >
                                                                                            Apply
                                                                                        </Typography>
                                                                                    }
                                                                                </div>
                                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                    <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 500, color: "#787878", marginRight: "20px", wordBreak: "break-all" }}>
                                                                                        Report Type: {appliedFilter.reportType ?? "-"},
                                                                                        Filter: {
                                                                                            filterGroups.reduce((filterString, filterGroup, index) => {
                                                                                                filterString += " " + filterGroup.filters.reduce((filterString, filter, index) => {
                                                                                                    return (
                                                                                                        filterString + " " + filter.fieldName + " " + (filter.not ? "NOT " : "") + filter.operator + " " + filter.expressions + " " +
                                                                                                        (filterGroup.filters.length === (index + 1) ? "" : filterGroup.operator)
                                                                                                    )
                                                                                                }, "");
                                                                                                if (filterGroups.length !== (index + 1)) {
                                                                                                    filterString += " " + appliedFilter.operator
                                                                                                }
                                                                                                return filterString;
                                                                                            }, "")
                                                                                        }
                                                                                        {(segments && segments.length) ?
                                                                                            <span><br />
                                                                                                Segments: {segments.join(", ")}
                                                                                                {unify && " Unify: " + unify.value}
                                                                                            </span> : null
                                                                                        }
                                                                                    </Typography>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", minWidth: "70px" }}>
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                _deleteFilter({ filterId: appliedFilter.id });
                                                                                                handleAllChartsForceReload(appliedFilter.id);
                                                                                                if (chart && chartFilter.id === appliedFilter.id) {
                                                                                                    onChange(Filter.new(chart))
                                                                                                }
                                                                                            }}
                                                                                            style={{ background: "#F8F8F8", borderRadius: 0, height: 30, width: 30 }}
                                                                                        >
                                                                                            <DeleteIcon style={{ height: "16px" }} />
                                                                                        </IconButton>
                                                                                        <IconButton onClick={() => { setModal(false); setCurrentFilter(appliedFilter); setNextModalOpen(true) }} style={{ background: "#F8F8F8", borderRadius: 0, height: 30, width: 30 }} >
                                                                                            <FilterIcon style={{ height: "16px", width: "16px" }} />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                filters.length !== (index + 1) &&
                                                                                <hr style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }} />
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                }
                            </Scrollbars>
                        </Box>
                    </Box>
                </Box>
            </Modal >
            {nextModalOpen &&
                (
                    (currentFilter || chart?.channelType)
                        ? <FilterPopup
                            chart={chart ?? {
                                channelType: currentFilter.dataSource, table: { id: currentFilter.reportType?.split(" - ")?.[0] },
                                dataLevel: currentFilter.reportType?.split(" - ")?.[1] ?? null,
                                reportFilter: true
                            }}
                            chartFilter={chartFilter}
                            onClose={() => setNextModalOpen(false)}
                            currentFilter={currentFilter
                                ? transformFromFilterDataObject(currentFilter)
                                : Filter.new(chart)
                            }
                            onChange={chart ?
                                ((filter, reload) => {
                                    onChange(filter);
                                    reload && handleAllChartsForceReload(filter.id);

                                }) :
                                ((filter) => {
                                    handleAllChartsForceReload(filter.id);
                                })
                            }
                            blendChannelCols={blendChannelCols}
                        />
                        : <SelectSourceModal
                            modal={nextModalOpen}
                            setModal={setNextModalOpen}
                            onClose={() => setNextModalOpen(false)}
                            blendChannelCols={blendChannelCols}
                        />
                )
            }
        </div >
    );
};
