import { CREATE_REPORT_SUCCESS, DELETE_REPORT_SUCCESS, GET_ALL_REPORTS_SUCCESS, GET_REPORT, GET_REPORT_FAILURE, GET_REPORT_SUCCESS, UPDATE_CHART_LOCAL, UPDATE_GRID_LOCAL, UPDATE_TAB_LOCAL, UPDATE_CHART_SUCCESS, UPDATE_REPORT_SUCCESS, GET_DEFAULT_REPORT, GET_DEFAULT_REPORT_SUCCESS, GET_DEFAULT_REPORT_FAILURE, SET_DEFAULT_REPORT_SUCCESS } from "./type"

const INITIAL_STATE = {
    allReports: [],
    reports: {},
    reportDownloaded: false,
    defaultReportId: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_REPORT_SUCCESS:
            const report = action.payload.report;
            const id = action.payload.id;
            const reportJSON = report.toJSON();
            reportJSON._id = id;
            return { ...state, allReports: state.allReports.concat({ id: id, report_name: report.name, report_type: report.type, chart_count: reportJSON.charts.length }), reports: { ...state.reports, [id]: reportJSON } };
        case UPDATE_REPORT_SUCCESS:
            const newAllReport = Array.from(state.allReports)
            const pos = newAllReport.findIndex(e => e.id === action.payload.reportId);
            newAllReport[pos] = { ...newAllReport[pos], ...action.payload.params };
            return { ...state, allReports: [...newAllReport], reports: { ...state.reports, [action.payload.reportId]: { ...state.reports[action.payload.reportId], ...action.payload.params, updated_at: new Date().getTime() } } }
        case DELETE_REPORT_SUCCESS:
            const newReports = { ...state.reports }
            delete newReports[action.payload.id]
            const newAllReports = Array.from(state.allReports)
            const index = newAllReports.findIndex(e => e.id === action.payload.id)
            if (index !== -1) newAllReports.splice(index, 1)
            return { ...state, allReports: [...newAllReports], reports: { ...newReports } }
        case GET_ALL_REPORTS_SUCCESS:
            return { ...state, allReports: action.payload };
        case GET_REPORT:
            return { ...state, reportDownloaded: false };
        case GET_REPORT_SUCCESS:
            action.payload.report.updated_at = Date.parse(action.payload.report.updated_at);
            if (!(action.payload.id in state.reports) || action.payload.report.updated_at > state.reports[action.payload.id].updated_at) {
                return { ...state, reports: { ...state.reports, [action.payload.id]: action.payload.report }, reportDownloaded: true };
            } else {
                return { ...state, reportDownloaded: true };
            }
        case GET_REPORT_FAILURE:
            return { ...state, reportDownloaded: true };
        case GET_DEFAULT_REPORT_SUCCESS:
            const reportId = action.payload.result
            return { ...state, defaultReportId: reportId }
        case GET_DEFAULT_REPORT_FAILURE:
            return { ...state, defaultReportId: null }
        case SET_DEFAULT_REPORT_SUCCESS:
            const setReportId = action.payload.result
            return { ...state, defaultReportId: setReportId }
        case UPDATE_CHART_LOCAL:
            var newCharts = Array.from(state.reports[action.payload.reportId].charts);
            action.payload.updates.forEach(update => {
                switch (update.update_type) {
                    case "update":
                        const index = newCharts.findIndex(chart => chart.id === update.chart_id);
                        newCharts[index] = { ...newCharts[index], ...update.params };
                        break;
                    case "delete":
                        newCharts = newCharts.filter(chart => chart.id !== update.chart_id);
                        break;
                    case "add":
                        newCharts.push(update.params);
                        break;
                }
            });
            return { ...state, reports: { ...state.reports, [action.payload.reportId]: { ...state.reports[action.payload.reportId], charts: newCharts, updated_at: new Date().getTime() } } };
        case UPDATE_GRID_LOCAL:
            var newGrids = Array.from(state.reports[action.payload.reportId].grids);
            action.payload.updates.forEach(update => {
                switch (update.update_type) {
                    case "update":
                        const index = newGrids.findIndex(grid => grid.id === update.grid_id);
                        newGrids[index] = { ...newGrids[index], ...update.params };
                        break;
                    case "delete":
                        newGrids = newGrids.filter(grid => grid.id !== update.grid_id);
                        break;
                    case "add":
                        newGrids.push({ id: update.grid_id, ...update.params })
                        break;
                }
            });
            return { ...state, reports: { ...state.reports, [action.payload.reportId]: { ...state.reports[action.payload.reportId], grids: newGrids, updated_at: new Date().getTime() } } };
        case UPDATE_TAB_LOCAL:
            var newTabs = Array.from(state.reports[action.payload.reportId].tabs)
            action.payload.updates.forEach(update => {
                switch (update.update_type) {
                    case "update":
                        const index = newTabs.findIndex(tab => tab.id === update.tab_id);
                        if (index !== -1) newTabs[index] = { ...newTabs[index], ...update.params }
                        break;
                    case "delete":
                        newTabs = newTabs.filter(tab => tab.id !== update.tab_id);
                        break;
                    case "add":
                        newTabs.push({ id: update.tab_id, ...update.params })
                        break;
                }
            });
            return { ...state, reports: { ...state.reports, [action.payload.reportId]: { ...state.reports[action.payload.reportId], tabs: newTabs, updated_at: new Date().getTime() } } };
        default:
            return state;
    }
};

export default reducer;
