import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj,{EVENTS} from "../../utils/mixpanel";

function* connectAccount(args) {
    yield window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_BING_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_BASE_URL}/${args.payload.redirect_url}/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fads.microsoft.com%2Fmsads.manage&state=12345`;
}

function* sendCode(args) {
    try {
        yield api.post("/api/bing-ads/auth", {
            code: args.payload.code,
            redirect_url: args.payload.redirect_url,
        });
        mixpanelObj.track(EVENTS.connect_source,{
            data_source:channelType,
            channel_type:"ads",
            connected:"Yes"
        },true);
        window.location.href = args.payload.redirect_url + `?channel=${channelType}`;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        mixpanelObj.track(EVENTS.connect_source,{
            data_source:channelType,
            channel_type:"ads",
            connected:"No"
         },true);
        window.location.href = args.payload.redirect_url + `?channel=${channelType}&failure`;
    }
}

function* getFields() {
    try {
        const response = yield api.post("/api/bing-ads/get-columns");
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/bing-ads/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* disconnectAccount(args) {
    try {
        yield api.post("/api/bing-ads/revoke", args.payload);
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY`,
        });
        mixpanelObj.track(EVENTS.remove_source,{data_source:channelType,channel_type:"ads"},true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/bing-ads/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.SEND_CODE`, sendCode),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
