import pixisLogo from "../../Assets/Icons/pixis.svg";
import { Typography, Grid, Button, Autocomplete, Box, TextField, Stack } from "@mui/material";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getOrganizationList, selectOrganization } from "../../api/super-admin";
import CloseIcon from "../Common/CloseIcon";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUserOrg } from "../../api/user/action";

const SelectOrganization = (props) => {
    const { user } = props;
    const history = useHistory();
    const [organizations, setOrganizations] = useState([]);
    const [orgId, setOrgId] = useState();
    const dispatch = useDispatch()
    const _updateUserOrg = (_) => dispatch(updateUserOrg(_))

    const organizationSelectHandler = (e, option) => {
        setOrgId(option?.id);
        _updateUserOrg(option)
    }

    const launchAppHandler = async () => {
        selectOrganization({ organization_id: orgId }).then(_ => {
            history.push("/managesources", { prevPath: history.location.pathname })
        });
    }

    const hasOrgId = () => {
        return orgId !== undefined && orgId !== ''
    }

    useEffect(() => {
        if (user?.isExternalEditor === true && user.role !== "viewer") {
            getOrganizationList().then(_organizations => {
                setOrganizations(_organizations);
            });
        } else {
            history.push("/selectchannels", { prevPath: history.location.pathname })
        }

    }, []);

    return (
        <div>
            <Grid container
                spacing={4}
                direction="column"
                alignItems="center"
                style={{ paddingTop: '75px' }}
            >
                <CloseIcon />
                <Grid item xs={12}>
                    <Stack spacing={0} alignItems="center">
                        <img src={pixisLogo} alt="Pixis icon" />
                        <Typography variant="h3" sx={{ color: "#0869FB", fontFamily: "lato", fontWeight: 700 }}>
                            Analytics
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Stack alignItems="center">
                        <Typography variant="h5">
                            "Please choose your organization for which you want to
                        </Typography>
                        <Typography variant="h5" style={{ fontWeight: 700 }}>
                            See, Download and Edit reports"
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack alignItems="center" justifyContent="center" spacing={2}>
                        <Autocomplete
                            options={organizations}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={organizationSelectHandler}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    label="Choose your organization"
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        >
                        </Autocomplete>
                        <Button
                            style={{ textTransform: 'none', fontSize: "16px", height: '40px', minWidth: '120px', borderRadius: "8px", padding: "0 20px" }}
                            onClick={launchAppHandler}
                            variant="contained"
                            disabled={!hasOrgId()}
                            color="primary"
                        >
                            Launch your app
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.user.user
});
export default (connect(mapStateToProps)(SelectOrganization));