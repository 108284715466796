import React from "react";
import { Box, Button, Modal, Typography } from '@mui/material';
import "../styles/modal.css";
import { ReactComponent as SuccessIcon } from "../../Assets/Images/success.svg";

const customStyles = {
    top: '30%',
    left: '35%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: "#ffff",
    borderRadius: "8px",
    width: "30%",
    minHeight: "auto",
    padding: "30px",
    zIndex: 9000000000000,
    position: "absolute",
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
};

const SuccessModal = (props) => {
    const { modal, setModal, message } = props;

    return (
        <div>
            <Modal
                open={modal === "APPLY_CHANGES"}
                onClose={() => setModal("none")}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={customStyles}>
                    <SuccessIcon />
                    <Typography variant="body1" style={{ fontSize: "28px", fontWeight: 600, marginBottom: "10px" }}>
                        Success
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 400, marginBottom: "10px", color: "#5A5A5A" }}>
                        {message}
                    </Typography>
                    <Button variant="contained" style={{ borderRadius: "6px", marginTop: "10px" }}
                        onClick={() => setModal("CHANGES_APPLIED")}
                    >
                        Done
                    </Button>
                </Box>

            </Modal >
        </div>
    )
}
export default SuccessModal;
