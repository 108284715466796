import { all, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj from "../../utils/mixpanel";

function* connectAccount(args) {
    try {
        const response = yield api.post("/api/google-ads/get-authorization-url", {
            success_redirect_url: args.payload.redirect_url + `?channel=${channelType}`,
            failure_redirect_url: args.payload.redirect_url + `?channel=${channelType}&failure`,
        });
        yield window.location.href = response.data.authorizationUrl;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/api/google-ads/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track("remove_source", { data_source: channelType, channel_type: "ads" }, true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/google-ads/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getColumns(args) {
    try {
        const { id: tableName } = args.payload;
        const response = yield api.post("/api/google-ads/get-fields", {
            table_name: tableName,
        });
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: {
                tableName: tableName,
                data: response.data ?? [],
            },
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_COLUMNS_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const response = yield api.post("/api/google-ads/get-fields");
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: {
                data: response.data ?? [],
            },
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_COLUMNS_FAILURE`,
            payload: error.response
        });
    }
}

function* getTables() {
    try {
        const tablesResponse = yield api.post("/api/google-ads/get-tables");
        const tables = tablesResponse.data.tables.map(table => ({
            id: table.name,
            name: table.uiName,
        }));
        // const fieldsResponse = yield api.post("/api/google-ads/get-fields");
        // yield put({
        //     type: `${channelType}.GET_FIELDS_SUCCESS`,
        //     payload: fieldsResponse.data,
        // });
        yield put({
            type: `${channelType}.GET_TABLES_SUCCESS`,
            payload: tables,
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/google-ads/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLeading(`${channelType}.GET_FIELDS`, getFields),
        takeEvery(`${channelType}.GET_COLUMNS`, getColumns),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
