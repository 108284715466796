import { GetChannel, GetChannelSortOrder } from "../../models/channel";
import { CHECK_AUTH_FAILURE, CHECK_AUTH_SUCCESS, SELECT_ACCOUNT, GET_ALL_COLUMNS_SUCCESS, UPDATE_COLUMN_SUCCESS, DELETE_COLUMN_SUCCESS, CREATE_COLUMN_SUCCESS, GET_ALL_DIMENSIONS_SUCCESS, IS_SAVE_AND_UPDATE_BUTTON_CLICK } from "./type"

const INITIAL_STATE = {
    authenticatedAccounts: [],
    selectedAccounts: {},
    allCustomCols: [],
    allCustomDims: [],
    isSaveAndUpdateClick: "nothing"
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHECK_AUTH_SUCCESS:
            const newAuthenticatedAccounts = Object.entries(action.payload)
                .filter(v => v[1])
                .map(v => GetChannel(v[0]))
                .filter(channel => channel?.isSupported)
                .map(channel => channel.type);
            newAuthenticatedAccounts.sort((a, b) => GetChannelSortOrder(a) - GetChannelSortOrder(b));
            if (state.authenticatedAccounts.length === newAuthenticatedAccounts.length && state.authenticatedAccounts.every((a, i) => a === newAuthenticatedAccounts[i])) {
                return state;
            }
            return { ...state, authenticatedAccounts: newAuthenticatedAccounts };
        case CHECK_AUTH_FAILURE:
            return { ...state, authenticatedAccounts: [] };
        case SELECT_ACCOUNT:
            const newSelectedAccounts = { ...state.selectedAccounts };
            if (action.payload.account) {
                newSelectedAccounts[action.payload.channel] = action.payload.account;
            } else {
                delete newSelectedAccounts[action.payload.channel];
            }
            const sortedSelectedAccounts = Object.keys(newSelectedAccounts)
                .sort((a, b) => GetChannelSortOrder(a) - GetChannelSortOrder(b))
                .reduce((obj, key) => {
                    obj[key] = newSelectedAccounts[key];
                    return obj;
                }, {});
            localStorage.setItem("selected_accounts", JSON.stringify(sortedSelectedAccounts));
            return { ...state, selectedAccounts: sortedSelectedAccounts };
        case GET_ALL_COLUMNS_SUCCESS:
            return { ...state, allCustomCols: action.payload };
        case CREATE_COLUMN_SUCCESS:
            const column = action.payload.column;
            const id = action.payload.id;
            return { ...state, allCustomCols: state.allCustomCols.concat({ id: id, ...column }) };
        case UPDATE_COLUMN_SUCCESS:
            const newCustomCols = Array.from(state.allCustomCols)
            const pos = newCustomCols.findIndex(e => e.id === action.payload.columnId);
            newCustomCols[pos] = { ...newCustomCols[pos], ...action.payload.params };
            return { ...state, allCustomCols: [...newCustomCols] }
        case DELETE_COLUMN_SUCCESS:
            const newCols = Array.from(state.allCustomCols)
            const index = newCols.findIndex(e => e.id === action.payload.id)
            if (index !== -1) newCols.splice(index, 1)
            return { ...state, allCustomCols: [...newCols] }
        case GET_ALL_DIMENSIONS_SUCCESS:
            return { ...state, allCustomDims: action.payload }
        case IS_SAVE_AND_UPDATE_BUTTON_CLICK:
            return { ...state, isSaveAndUpdateClick: action.payload }
        default:
            return state;
    }
};

export default reducer;
