import { all, put, takeLatest } from "redux-saga/effects"
import { api } from "..";
import { GOOGLE_LOGIN, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, SIGNUP, SIGNUP_FAILURE, SIGNUP_SUCCESS, RESET_FAILURE, RESET_ERROR } from "./type";
import mixpanelObj,{EVENTS,login_success} from "../../utils/mixpanel";

function* signup(args) {
    try {
        const response = yield api.post("/api/users/signup/", {
            name: `${args.payload.firstName} ${args.payload.lastName}`,
            email: args.payload.email,
            password: args.payload.password,
            mobile: args.payload.phone,
            company_name: args.payload.company,
            company_domain: (args.payload.domain.startsWith("www") ? "" : "www.") + args.payload.domain
        }, false);
        yield put({
            type: SIGNUP_SUCCESS,
            payload: response.data
        });
        mixpanelObj.login_success(args.payload.email);
        mixpanelObj.track(EVENTS.sign_up,{email:args.payload.email,organisation_name:args.payload.company,domain:(args.payload.domain.startsWith("www") ? "" : "www.") + args.payload.domain,signup_type:"custom"});
    } catch (error) {
        yield put({
            type: SIGNUP_FAILURE,
            payload: error.response.data.message
        });
    }
}

function* login(args) {
    try {
        const response = yield api.post("/api/users/login/", {
            email: args.payload.email,
            password: args.payload.password,
            auth_type: "custom",
        }, false);
        yield put({
            type: LOGIN_SUCCESS,
            payload: response.data
        });
        mixpanelObj.login_success(args.payload.email);
        mixpanelObj.track(EVENTS.sign_in,{email_id:args.payload.email,auth_type:"custom"});
    } catch (error) {
        yield put({
            type: LOGIN_FAILURE,
            payload: error.response?.data?.message
        });
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        }
    }
}

function* resetFailure() {
    try {
        yield put({
            type: RESET_FAILURE,
            payload: false
        });
    } catch (error) {
        yield put({
            type: LOGIN_FAILURE,
            payload: true,
        });
    }
}

export const checkGoogleLogin = (authToken) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/api/users/does-exists/", {
            auth_token: authToken,
            auth_type: "google",
        }, false);
        if (response.data.user_exists) {
            resolve(response.data);
        } else {
            reject();
        }
    } catch (error) {
        reject();
    }
});

function* googleLogin(args) {
    try {
        const response = yield api.post("/api/users/login/", {
            email: args.payload.email,
            auth_token: args.payload.credential,
            company_name: args.payload.company,
            company_domain: args.payload.domain,
            auth_type: "google"
        }, false);
        yield put({
            type: LOGIN_SUCCESS,
            payload: response.data
        });
        mixpanelObj.track(EVENTS.sign_in,{email_id:args.payload.email,auth_type:"google"});
    } catch (error) {
        yield put({
            type: LOGIN_FAILURE,
            payload: error.response?.data?.message
        });
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        }
    }
}

export const sendFingerprint = (visitorData, visitorId) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/api/users/fingerprint", {
            fingerprint_result: visitorData,
            visitor_id: visitorId,
        });
        resolve(response.data.responses);
    } catch (error) {
        reject(error.response);
    }
});

export default function* root() {
    yield all([
        takeLatest(SIGNUP, signup),
        takeLatest(LOGIN, login),
        takeLatest(GOOGLE_LOGIN, googleLogin),
        takeLatest(RESET_ERROR, resetFailure)
    ])
}
