import { Grid, Button, Typography, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { blendsCreate, updateBlend } from "../../../api/blends/action";
import { transformCreateBlendRequest } from "../Utils";
import { updateLoader } from "../../../api/user/action";

const JOIN = {
    inner: "Inner",
    left: "Left",
    right: "Right",
    outer: "Full Outer"
}

const MergedDataSource = (props) => {
    const { newSourceData, setCompletion, blendsCreate, updateBlend, blends, user, updateLoader } = props;
    const blendsNameList = blends.map(blend => blend.name);

    const [error, setError] = useState("");

    const checkAllSourcesdata = () => {
        let err = ""
        let maxKeyLength = newSourceData.dataSources.map(source => source.keys.length).reduce((a, b) => Math.max(a, b));
        if (!newSourceData.name) {
            err = "Name is mandatory!"
            return err;
        }
        if (newSourceData.name !== blends.find(blend => blend.id === newSourceData.id)?.name && blendsNameList.includes(newSourceData.name)) {
            err = "Name must be unique!"
            return err;
        }
        for (let source of newSourceData.dataSources) {
            for (let key of ["keys", "metrics"]) {
                if (!source[key] || !source[key].length) {
                    err = `At Least one ${key} is required to be added for ${source.source.type}`;
                    break;
                } else if (key === "keys") {
                    if (source[key].length < maxKeyLength) {
                        err = `All sources must have equal number of keys selected. Add ${(maxKeyLength - source.keys.length)} more key in ${source.source.type}`
                        break;
                    }
                }
            }
            if (err) { break; }
        }
        return err;
    }

    useEffect(() => {
        const err = checkAllSourcesdata()
        setError(err);
    }, [newSourceData])

    const updateSource = (blendId) => {
        updateLoader(true);
        const obj = transformCreateBlendRequest(newSourceData);
        if (blendId) {
            updateBlend({
                id: blendId, obj: obj,
                loading: (msg) => {
                    updateLoader(false);
                    setCompletion({ message: msg, operation: "Updation" });
                }
            });
        } else {
            blendsCreate({
                blendData: obj,
                loading: (msg) => {
                    updateLoader(false);
                    setCompletion({ message: msg, operation: "Creation" });
                }
            });
        }
    }

    return (
        <>
            <Grid item xs={12} style={{ height: "100%", position: "relative" }}>
                <div className="h18" > {newSourceData.name || "New Source name"} </div>
                <hr style={{ border: "1px solid #EAEAEC", margin: "10px -20px 15px" }} />
                <Scrollbars autoHide style={{ height: "16%", marginTop: "10px" }}>
                    {
                        newSourceData.dataSources.map(source => {

                            return <><div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                <div>
                                    <img style={{ marginRight: 8, height: 20, width: 20 }} src={source.source?.icon} alt={source.source?.title} /></div>
                                <div>
                                    <div className="h14_dark">
                                        {source.source?.title}
                                    </div>
                                </div>
                            </div>
                                {source.join && <div style={{ background: "#E5E5E5", margin: "8px 0px ", padding: "0px 8px", width: "max-content", borderRadius: "3px" }}>
                                    <span style={{ fontSize: "12px" }}> {JOIN[source.join]} Join</span>
                                </div>}
                            </>
                        })}
                </Scrollbars>
                <hr style={{ border: "1px solid #EAEAEC", margin: "15px -20px 10px" }} />
                <Scrollbars autoHide style={{ height: "60%" }}>
                    {
                        ["keys", "dimensions", "metrics"].map(key => {
                            return (
                                <div>
                                    {/* <hr style={{ border: "1px solid #22222211" }} /> */}
                                    <div style={{ margin: "5px 0px", display: "flex" }}>
                                        <div className="inter h14_light" style={{ margin: "auto 0px" }}>
                                            Included {key}
                                        </div>
                                        <Tooltip
                                            title={`Mentioned ${key} on which data blend is created and used in widgets.`}
                                            placement="right"
                                        >
                                            <InfoIcon style={{ color: "#999999", margin: "0px 5px" }} />
                                        </Tooltip>
                                    </div>
                                    {
                                        newSourceData.dataSources.map(source => {
                                            if (source[key]) {
                                                return source[key].map(value => {
                                                    if (value) {
                                                        value = key === "metrics" ? value.metric : value
                                                        return (
                                                            <div className="highlight1 displayRowSpaced">
                                                                <div className="h14_dark" style={{ width: "60%" }}> {value.name} ({source.source.title})  </div>
                                                                {value.dataType && <div style={{ height: "fit-content", width: "fit-content", maxWidth: "40%", textAlign: "right", color: "#0869FB", background: "#EDF4FF", borderRadius: "6px", fontSize: "12px", padding: "8px 12px" }}> {value.dataType} </div>}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        })}
                                </div>
                            )
                        })
                    }
                </Scrollbars>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", position: "absolute", right: 0, bottom: 0 }}>
                    {
                        error && <h4 style={{
                            color: "red",
                            textAlign: "right",
                            borderRadius: "10px",
                            width: "100%",
                            fontSize: "12px",
                            marginTop: "15px"
                        }}><b>{error}</b>
                        </h4>
                    }
                    <Tooltip title={user.role === "viewer" ? "Viewer does not have permission to update!" : ""} placement="top">
                        <div style={{ width: "100%" }}>
                            <Button
                                style={{ width: "100%", height: "45px", borderRadius: '8px', }}
                                sx={{ backgroundColor: "#0869FB", }}
                                variant="contained"
                                color="primary"
                                disabled={(user.role === "viewer" || error) ? true : false}
                                onClick={() => { updateSource(newSourceData.id) }}
                            >
                                {newSourceData.id ? "Update Source" : "Save new source"}
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </Grid>
        </>
    )
}

const mapStateToProps = state => ({
    blends: state.blends.blends,
    user: state.user.user
});

const mapDispatchToProps = dispatch => ({
    blendsCreate: (_) => dispatch(blendsCreate(_)),
    updateBlend: (_) => dispatch(updateBlend(_)),
    updateLoader: (_) => dispatch(updateLoader(_))
});

export default connect(mapStateToProps, mapDispatchToProps)(MergedDataSource);
