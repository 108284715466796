import { Box, Button, Modal, Typography } from "@mui/material";
import Find from "../../Assets/Icons/file-icon.svg";
import Close from "../../Assets/Icons/close.svg";
import Info from "../../Assets/Icons/info.svg";
import React, { useEffect, useState } from "react";
import DataList from "../CustomizingSources/BlendDataSource/DataList";
import { useSelector } from "react-redux";
import DateRangeModal from "./DateRangeModal";
import { viewDate } from "../../components/SuperMetrics/Utils";
import CalendarIcon from "../../Assets/Icons/calendar-icon.svg";

const ATTRIBUTES = [
  { id: "UPDATE_BULK_DATES", name: "Date" },
  { id: "UPDATE_BULK_SHEETS", name: "Sheet Name " },
];

const customStyles = {
  position: "absolute",
  top: "32%",
  left: "68%",
  width: "26.2%",
  bgcolor: "#FFF",
  borderRadius: "12px",
  boxShadow: 24,
  padding: "24px",
};

const FindandReplaceModal = (props) => {
  const { isopen, setIsOpen } = props;
  const [attribute, setAttribute] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [replaceWith, setReplaceWith] = useState(null);
  const availableSheets = useSelector((state) => state.extractor.availableSheets);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const list = attribute?.id === ATTRIBUTES[0].id ? Object.values(props.dates) : Object.values(props.sheets);
  const user = useSelector((state) => state.user.user);
  const handleClose = () => setIsOpen(false);

  const endDate = new Date();
  endDate.setDate(endDate.getDate() - 1);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  useEffect(() => {
    if (attribute?.id === ATTRIBUTES[0].id) {
      setReplaceWith({ label: null, startDate: null, endDate: null });
    } else {
      setReplaceWith(null);
    }
    setCurrentData(null);
  }, [attribute]);

  return (
    <>
      <Modal
        open={isopen}
        sx={{ "&.MuiModal-root": { backgroundColor: "transparent" } }}
      >
        <Box sx={customStyles}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <img src={Find} alt="" />
            <img
              src={Close}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </div>
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "32px",
              marginBottom: "16px",
            }}
          >
            Find & Replace
          </Typography>

          <div>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Select an attribute <img src={Info} alt="" />
            </Typography>
            <DataList
              metric={attribute}
              metrics={ATTRIBUTES}
              isEditable={true}
              onClick={setAttribute}
              defaultTitle="Select Attribute"
            />
          </div>

          <div style={{ marginTop: "16px" }}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              To Find <img src={Info} alt="" />
            </Typography>
            <DataList
              metric={currentData}
              metrics={list}
              isEditable={true}
              onClick={setCurrentData}
              defaultTitle="Select ‘value’ to find"
              disabled={!attribute}
            />
          </div>

          <div style={{ marginTop: "16px" }}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Replace with <img src={Info} alt="" />
            </Typography>
            {attribute?.id === ATTRIBUTES[0].id && replaceWith ? (
              <DataList
                key={replaceWith.startDate + "-" + replaceWith.endDate}
                metric={{
                  name: (!replaceWith || !replaceWith?.label) ? "Select Date Range" : viewDate(replaceWith.startDate) + " - " + viewDate(replaceWith.endDate)
                }}
                isEditable={false}
                startAdornment={{
                  onClick: () => setIsDatePickerOpen(true),
                  src: CalendarIcon,
                }}
                defaultTitle="Date Range"
                disabled={!attribute}
              />
            ) : (
              <DataList
                metric={replaceWith}
                metrics={availableSheets}
                isEditable={true}
                onClick={setReplaceWith}
                defaultTitle={
                  !attribute ? "Select Replacement Value" : "Select Sheet"
                }
                disabled={!attribute}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "16px",
              gap: "12px",
            }}
          >
            <Button
              onClick={handleClose}
              disableRipple
              sx={{
                width: "100%",
                height: "40px",
                background: "#FFF",
                color: "#394455",
                border: "1px solid #E0E3E5",
                borderRadius: "8px",
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Cancel
            </Button>
            <Button
              disableRipple
              sx={{
                width: "100%",
                height: "40px",
                background: !attribute || !currentData || !(replaceWith?.id || replaceWith?.label) || user.role === "viewer" ? "#eee" : "#0869FB",
                color: "#FFF",
                borderRadius: "8px",
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "14px",
                "&:hover": { backgroundColor: "#1976d2" },
              }}
              disabled={!attribute || !currentData || !(replaceWith?.id || replaceWith?.label) || user.role === "viewer"}
              onClick={() => {
                setIsOpen(false);
                props.handleBulkEditing("UPDATE_BULK", {
                  currentData,
                  replaceWith,
                  attributeType: attribute.id,
                });
              }}
            >
              Replace
            </Button>
          </div>
        </Box>
      </Modal>
      {isDatePickerOpen && (
        <DateRangeModal
          dateRange={replaceWith}
          setDateRange={setReplaceWith}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
        />
      )}
    </>
  );
};

export default FindandReplaceModal;
