import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj from "../../utils/mixpanel";

function* connectAccount(args) {
    try {
        const response = yield api.post("/api/google-analytics4/get-authorization-url", {
            success_redirect_url: args.payload.redirect_url + `?channel=${channelType}`,
            failure_redirect_url: args.payload.redirect_url + `?channel=${channelType}&failure`,
        });
        yield window.location.href = response.data.authorizationUrl;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/api/google-analytics4/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track("remove_source",{data_source:channelType,channel_type:"attribution"},true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/ga4/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getSegments() {
    try {
        const response = yield api.post("/api/ga/get-segment-list");
        yield put({
            type: `${channelType}.GET_SEGMENTS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_SEGMENTS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCustomFields(selectedAccount) {
    try {
        const response = yield api.post("/api/ga4/get-dimensions-metrics", {
            propertyId: selectedAccount.webPropertyId,
            customOnly: true
        });
        yield put({
            type: `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}


function* getDimensionsAndMetrics() {
    try {
        const response = yield api.post("/api/ga4/get-dimensions-metrics");
        yield put({
            type: `${channelType}.GET_DIMENSION_METRICS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_DIMENSION_METRICS_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    const state = yield select((state) => state);
    const { segments, metrics, dimensions } = state[channelType] || {};
    const dimensionFunction = () => getCustomFields(state.channel.selectedAccounts[channelType]);
    yield all([(!segments.length) && getSegments(), (!metrics.length || !dimensions.length) && getDimensionsAndMetrics(), dimensionFunction()]);
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/ga4/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
