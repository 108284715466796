import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { GetChannel, SupportedChannels } from "./models/channel";
import { selectAccount } from "./api/channel/action";
import { UPDATE_STATE } from "./redux/rootReducer";
import { logout } from "./api/auth/action";
import { REDUX_VERSION } from ".";

function unauthenticateAccount(state, dispatch, authenticatedAccounts, selectedAccounts) {
    SupportedChannels.forEach(channel => {
        if (!authenticatedAccounts.find(type => type === channel.type)) {
            // not authenticated

            if (state[`${channel.type}.hasAccountData`]) {
                // not removed account data
                dispatch(channel.actions.removeAccountData);
            }

            if (channel.type in selectedAccounts) {
                // not removed from selected accounts
                dispatch(selectAccount({ channel: channel.type, account: null }));
            }
        }
    });
}

function getAccountsAndMetrics(state, dispatch, authenticatedAccounts) {
    SupportedChannels.forEach(channel => {
        if (authenticatedAccounts.includes(channel.type)) {
            // connected
            if (!state[`${channel.type}.hasAccountData`]) {
                // fetch account data
                dispatch(channel.actions.getAccountSummary);
            }
            if (!state[`${channel.type}.metricsUpdated`]) {
                dispatch(channel.actions.getFields);
            }
        }
    });
}

function ChannelAuthenticator({ authenticatedAccounts, selectedAccounts, children, ...props }) {
    const dispatch = useDispatch();

    useEffect(() => {
        var currentVersion = localStorage.getItem("reduxVersion");
        if (!currentVersion) {
            currentVersion = REDUX_VERSION;
            dispatch(logout());
        }
        currentVersion = parseInt(currentVersion);
        const targetVersion = REDUX_VERSION;
        for (let i = currentVersion + 1; i <= targetVersion; i++) {
            dispatch({ type: UPDATE_STATE, payload: i });
        }
        localStorage.setItem("reduxVersion", REDUX_VERSION);
    }, []);

    useEffect(() => {
        unauthenticateAccount(props, dispatch, authenticatedAccounts, selectedAccounts);
        getAccountsAndMetrics(props, dispatch, authenticatedAccounts);
    }, [authenticatedAccounts]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < 5) {
                getAccountsAndMetrics(props, dispatch, authenticatedAccounts)
                setCount(prev => prev + 1);
            } else {
                clearInterval(interval);
            }
        }, 20 * 1000);
        return () => clearInterval(interval);
    }, [props, authenticatedAccounts]);

    return children;

}

const mapStateToProps = state => {
    const stateObject = {
        authenticatedAccounts: state.channel.authenticatedAccounts,
        selectedAccounts: state.channel.selectedAccounts,
    };
    SupportedChannels.forEach(channel => {
        stateObject[`${channel.type}.hasAccountData`] = Object.keys(state[channel.type].accountData).length > 0;
        stateObject[`${channel.type}.metricsUpdated`] = state[channel.type].metricsUpdated;
    });
    return stateObject;
};

export default (connect(mapStateToProps)(ChannelAuthenticator));
