import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { TextStyle } from "../Charts/ChartUtils";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

const GridSettings = ({ editDrawerState }) => {
  const { grid, handleGridChange } = editDrawerState;

  const [formats, setFormats] = React.useState(
    grid.gridStyle.titleStyle.fontFormat
  );

  const [subFormats, setSubFormats] = React.useState(
    grid.gridStyle.subTitleStyle.fontFormat
  );

  const [titleStyle, setTitleStyle] = useState(grid.gridStyle.titleStyle);
  const [subTitleStyle, setSubTitleStyle] = useState(
    grid.gridStyle.subTitleStyle
  );

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };
  const handleSubFormat = (event, newFormats) => {
    setSubFormats(newFormats);
  };

  useEffect(() => {
    handleTitleChange("fontFormat", formats);
  }, [formats]);

  useEffect(() => {
    handleSubTitleChange("fontFormat", subFormats);
  }, [subFormats]);

  useEffect(() => {
    handleGridChange(
      "gridStyle",
      { titleStyle: titleStyle, subTitleStyle: subTitleStyle },
      grid.id
    );
    grid.gridStyle.titleStyle = titleStyle;
  }, [titleStyle, subTitleStyle]);

  const handleTitleChange = (property, value) => {
    setTitleStyle({ ...titleStyle, [property]: value });
  };
  const handleSubTitleChange = (property, value) => {
    setSubTitleStyle({ ...subTitleStyle, [property]: value });
  };

  return (
    <div>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },
          width: "100%",
        }}
        defaultExpanded={true}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Header Title
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl
            sx={{ width: "100%", justifyContent: "center" }}
            size="small"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginBottom: "8px",
                  }}
                >
                  Font Style
                </Typography>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #EAEAEC",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                  }}
                  inputProps={{
                    sx: {
                      display: "flex",
                      alignItems: "center",
                      width: "220px",
                    },
                  }}
                  MenuProps={{
                    sx: {
                      display: "flex",
                      marginTop: "8px",
                      borderRadius: "12px",
                      "& .Mui-selected": {
                        borderRadius: "6px",
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: "10px !important",
                        borderRadius: "12px",
                      },
                    },
                  }}
                  style={{ width: "90%" }}
                  variant="outlined"
                  value={titleStyle.font}
                  onChange={(e) => {
                    handleTitleChange("font", e.target.value);
                  }}
                >
                  {TextStyle.FontStyle.map((pos, index) => (
                    <MenuItem key={index} value={pos}>
                      <div
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {pos}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginBottom: "8px",
                  }}
                >
                  Font Size
                </Typography>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #EAEAEC",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                  }}
                  inputProps={{
                    sx: {
                      display: "flex",
                      alignItems: "center",
                      width: "220px",
                    },
                  }}
                  MenuProps={{
                    sx: {
                      display: "flex",
                      marginTop: "8px",
                      borderRadius: "12px",
                      "& .Mui-selected": {
                        borderRadius: "6px",
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: "10px !important",
                        borderRadius: "12px",
                      },
                    },
                  }}
                  style={{ width: "90%" }}
                  variant="outlined"
                  value={titleStyle.fontSize}
                  onChange={(e) => {
                    handleTitleChange("fontSize", e.target.value);
                  }}
                >
                  {TextStyle.FontSize.map((pos, index) => (
                    <MenuItem key={index} value={pos}>
                      <div
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {pos}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid container style={{}}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginTop: "25px",
                    marginBottom: "8px",
                  }}
                >
                  Font Format
                </Typography>
                <ToggleButtonGroup
                  value={formats}
                  onChange={handleFormat}
                  aria-label="text formatting"
                >
                  <ToggleButton value="bold" aria-label="bold">
                    <FormatBoldIcon />
                  </ToggleButton>
                  <ToggleButton value="italic" aria-label="italic">
                    <FormatItalicIcon />
                  </ToggleButton>
                  <ToggleButton value="underline" aria-label="underlined">
                    <FormatUnderlinedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginTop: "25px",
                    marginBottom: "8px",
                  }}
                >
                  Font Color
                </Typography>
                <input
                  style={{ width: "90%", height: "45px" }}
                  type="color"
                  value={titleStyle.color}
                  onChange={(e) => {
                    handleTitleChange("color", e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded={true}
        sx={{
          boxShadow: "none",
          // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },
          width: "100%",
        }}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Header SubTitle
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl
            sx={{ width: "100%", justifyContent: "center" }}
            size="small"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginBottom: "8px",
                  }}
                >
                  Font Style
                </Typography>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #EAEAEC",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                  }}
                  inputProps={{
                    sx: {
                      display: "flex",
                      alignItems: "center",
                      width: "220px",
                    },
                  }}
                  MenuProps={{
                    sx: {
                      display: "flex",
                      marginTop: "8px",
                      borderRadius: "12px",
                      "& .Mui-selected": {
                        borderRadius: "6px",
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: "10px !important",
                        borderRadius: "12px",
                      },
                    },
                  }}
                  style={{ width: "90%" }}
                  variant="outlined"
                  value={subTitleStyle.font}
                  onChange={(e) => {
                    handleSubTitleChange("font", e.target.value);
                  }}
                >
                  {TextStyle.FontStyle.map((pos, index) => (
                    <MenuItem key={index} value={pos}>
                      <div
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {pos}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginBottom: "8px",
                  }}
                >
                  Font Size
                </Typography>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #EAEAEC",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                  }}
                  inputProps={{
                    sx: {
                      display: "flex",
                      alignItems: "center",
                      width: "220px",
                    },
                  }}
                  MenuProps={{
                    sx: {
                      display: "flex",
                      marginTop: "8px",
                      borderRadius: "12px",
                      "& .Mui-selected": {
                        borderRadius: "6px",
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: "10px !important",
                        borderRadius: "12px",
                      },
                    },
                  }}
                  style={{ width: "90%" }}
                  variant="outlined"
                  value={subTitleStyle.fontSize}
                  onChange={(e) => {
                    handleSubTitleChange("fontSize", e.target.value);
                  }}
                >
                  {TextStyle.FontSize.map((pos, index) => (
                    <MenuItem key={index} value={pos}>
                      <div
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {pos}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid container style={{}}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginTop: "25px",
                    marginBottom: "8px",
                  }}
                >
                  Font Format
                </Typography>
                <ToggleButtonGroup
                  value={subFormats}
                  onChange={handleSubFormat}
                  aria-label="text formatting"
                >
                  <ToggleButton value="bold" aria-label="bold">
                    <FormatBoldIcon />
                  </ToggleButton>
                  <ToggleButton value="italic" aria-label="italic">
                    <FormatItalicIcon />
                  </ToggleButton>
                  <ToggleButton value="underline" aria-label="underlined">
                    <FormatUnderlinedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                    marginTop: "25px",
                    marginBottom: "8px",
                  }}
                >
                  Font Color
                </Typography>
                <input
                  style={{ width: "90%", height: "45px" }}
                  type="color"
                  value={subTitleStyle.color}
                  onChange={(e) => {
                    handleSubTitleChange("color", e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GridSettings;
