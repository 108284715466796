import React from "react";
import Modal from 'react-modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { connect } from "react-redux";
import { checkGoogleLogin } from "../../api/auth/saga";
import { googleLogin } from "../../api/auth/action";
import jwt_decode from "jwt-decode";
import { Button, Grid, TextField } from "@mui/material";

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "30%",
		padding: "0",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const SignInModal = props => {
	const { googleResponse, setModal, googleLogin } = props;

	const [state, setState] = React.useState({
		company: "",
		domain: ""
	});
	const [isChecking, setIsChecking] = React.useState(true);

	const handleChange = (property, value) => {
		setState({ ...state, [property]: value });
	}

	const decoded = googleResponse ? jwt_decode(googleResponse.credential) : null;

	React.useEffect(() => {
		if (googleResponse && isChecking) {
			checkGoogleLogin(googleResponse.credential)
				.then(_ => googleLogin({
					email: decoded.email,
					credential: googleResponse.credential,
				}))
				.catch(_ => setIsChecking(false));
		}
	}, [googleResponse]);

	return (
		<Modal isOpen={googleResponse !== null} style={customStyles}>
			{!isChecking &&
				<div>
					<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<div>
							<b>Complete your Profile</b>
						</div>
						<div>
							<Button onClick={() => setModal(false)}>X</Button>
						</div>
					</div>
					<div style={{ width: "100%", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex" }}>
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "80%" }}>
							<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "5%", marginTop: "10%" }}>
								<TextField
									label="E-mail ID"
									value={decoded?.email}
									style={{ width: '100%' }}
									id="outlined-basic"
									InputLabelProps={{ shrink: true, style: { fontWeight: "500", color: 'black' } }}
									InputProps={{
										style: { color: 'black' }
									}}
									size="small"
									variant="outlined" padding
									disabled
								/>
							</Grid>
							<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "5%" }}>
								<TextField
									label="Company Name"
									placeholder="Company Name"
									style={{ width: '100%' }}
									id="outlined-basic"
									size="small"
									inputProps={{
										style: { color: 'rgb(50, 50, 50)' }
									}}
									InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "500" } }}
									InputProps={{ style: { borderRadius: '6px' } }}
									variant="outlined"
									onChange={(e) => handleChange("company", e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "5%" }}>
								<TextField
									label="Domain Name"
									placeholder="Domain Name"
									style={{ width: '100%' }}
									id="outlined-basic"
									size="small"
									InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "500" } }}
									InputProps={{
										endAdornment: <CheckCircleIcon style={{ color: "#eee" }} />, style: { borderRadius: '6px' }
									}}
									variant="outlined"
									onChange={(e) => handleChange("domain", e.target.value)}
								/>
							</Grid>
							<Grid citem xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "10%" }}>
								<Button
									style={{
										backgroundColor: "#0869FB",
										width: "100%",
										color: "white",
										padding: "10px",
										borderRadius: "8px"
									}}
									onClick={() => googleLogin({
										email: decoded.email,
										credential: googleResponse.credential,
										...state,
									})}
								>
									Sign In
								</Button>
							</Grid>
						</div>
					</div>
				</div>}
		</Modal>
	)
}

const mapDispatchToProps = dispatch => ({
	googleLogin: (_) => dispatch(googleLogin(_))
});

export default (connect(null, mapDispatchToProps)(SignInModal))
