import { GET_ALL_COLUMNS_SUCCESS, SELECT_ACCOUNT } from "../channel/type";
import { channelType } from "./transformer";

const INITIAL_STATE = {
    accountData: {},
    segments: [],
    dimensions: [],
    metrics: [],
    customDimensions: [],
    customMetrics: [],
    currency: null,
    metricsUpdated: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const username = action.payload.user.name;
            const email = action.payload.user.email;
            const accountList = [];
            action.payload.result.forEach(summary => {
                summary.properties.forEach(webproperty => {
                    accountList.push({
                        title: webproperty.propertyName,
                        subtitle: summary.accountName + " > " + webproperty.propertyName,
                        accountId: summary.accountId,
                        webPropertyId: webproperty.propertyId,
                        viewId: webproperty.propertyId
                    })
                })
            });
            return { ...state, accountData: { users: [{ username: username, email: email }], accountList: accountList } };
        case SELECT_ACCOUNT:
            if (action.payload.channel === channelType) {
                return { ...state, customDimensions: [], customMetrics: [], metricsUpdated: false };
            }
            return state;
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return JSON.parse(JSON.stringify(INITIAL_STATE));
        case `${channelType}.GET_SEGMENTS_SUCCESS`:
            return { ...state, segments: action.payload.segments };
        case `${channelType}.GET_DIMENSION_METRICS_SUCCESS`:
            const dimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const metrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`:
            const customDimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const customMetrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, customDimensions: customDimensions, customMetrics: customMetrics, metricsUpdated: state.metrics.length && state.dimensions.length };
        case GET_ALL_COLUMNS_SUCCESS:
            const cc = action.payload.filter(column => column.source === channelType).map(column => ({
                id: column.id,
                name: column.name,
                dataType: column.format ?? "FLOAT",
                group: "Customized",
            }));

            return { ...state, metrics: state.metrics.filter(m => m.group !== "Customized").concat(cc) };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        default:
            return state;
    }
};

export default reducer;
