import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChevronRightRounded,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { ReactComponent as ExportIcon } from "../../../Assets/Icons/export.svg";
import { ReactComponent as LinkIcon } from "../../../Assets/Icons/link-icon.svg";
import { ReactComponent as RunIcon } from "../../../Assets/Icons/run-icon.svg";
import { ReactComponent as RefreshIcon } from "../../../Assets/Icons/refresh.svg";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../../styles/profile.css";
import { formattedDate, StatusMapping } from "../Utils";
import SearchBar from "../../Common/SearchBarBE";
import { getAllLogs } from "../../../api/extractor/saga";
import { updateLoader } from "../../../api/user/action";

const Logs = ({ triggerId, setReRunModal }) => {
  const user = useSelector(state => state.user.user);
  const [allLogs, setAllLogs] = useState([]);
  const [total,setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText,setSearchText] = useState("");
  const dispatch = useDispatch();
  
  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = (current_page=0,limit_per_page=10,text="") => {
    dispatch(updateLoader(true));
    getAllLogs(triggerId,{current_page,limit_per_page,text})
      .then((data) => {
        setAllLogs(data.triggerRuns);
        setTotal(data.total);
      })
      .catch((err) => {
        console.log("🚀 ~ file: Logs.js:22 ~ dispatch ~ err:", err);
        setAllLogs([]);
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  }

  const handleChangePage = (_, newPage) => {
    getLogs(newPage,rowsPerPage,searchText);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    getLogs(0,event.target.value,searchText);
    setPage(0);
    setRowsPerPage(event.target.value);
  };


  return (
    <div
      className="grid-item"
      style={{ width: "100%", position: "relative", marginTop: "20px", backgroundColor: "#FFF" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: '17px'
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: 500 }}
          >
            Logs
          </Typography>
          {triggerId && (
            <>
              <ChevronRightRounded
                style={{ fontSize: "1.5em", color: "#5F6771" }}
              />
              <Typography
                style={{
                  color: "#5F6771",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: 250,
                  alignItems: "center",
                  // textTransform: "uppercase",
                }}
              >
                {allLogs[0]?.name ?? triggerId}
              </Typography>
            </>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "80%" }}>
            <SearchBar
              getData={(text) => getLogs(0,rowsPerPage,text)}
              setSearchText={setSearchText}
              searchText={searchText}
            />
          </div>
          <IconButton
            style={{
              marginLeft: "18px",
              height: "40px",
              width: "40px",
              padding: "0px"
            }}
            onClick={() => {
              getLogs(0,rowsPerPage,searchText);
              setPage(0);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "0",
          height: "100%",
          padding: "0px 16px",
          background: "#F8F9FD",
          borderRadius: "12px",
        }}
      >
        <TableContainer className="trigger-table" sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {!triggerId && <TableCell>TRIGGER NAME</TableCell>}
                <TableCell>SHEET NAME</TableCell>
                <TableCell>TAB NAME</TableCell>
                <TableCell>RUN TIME</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell>ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody key={allLogs.length + "-" + page}>
              {allLogs.map((row) => {
                const status = StatusMapping[row.status] ?? "";
                return (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {!triggerId && (
                      <TableCell scope="row">{row.name}</TableCell>
                    )}
                    <TableCell>{row.sheetName}</TableCell>
                    <TableCell>{row.tabName}</TableCell>
                    <TableCell style={{ minWidth: "150px" }} align="left">
                      {formattedDate(row.endedAt)}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex" }} className={status}>
                        <span className="statusText">{status}</span>
                        <Tooltip
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                backgroundColor: "transparent",
                                // filter:
                                //   "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                              },
                            },
                          }}
                          title={
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ width: "14px", height: "14px", borderRadius: "1px", background: "white", borderLeft: "2px solid #ECEEF1", borderBottom: "2px solid #ECEEF1", transform: `rotate(45deg)` }}></div>
                              <div style={{ width: "200px", borderRadius: "8px", background: "white", border: "2px solid #ECEEF1", marginLeft: "-7px", padding: "10px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 700, marginRight: "4px", color: (status === "Successfull") ? "#5ABCA3" : (status === "Failed") ? "#D7584E" : "black" }}>{status}</Typography>
                                  {status === "Successfull" ? (
                                    <CheckCircleIcon
                                      style={{ color: "#1ABFA1", fontSize: "16px" }}
                                    />
                                  ) : status === "Failed" ? (
                                    <ErrorIcon style={{ color: "#E84B47", fontSize: "16px" }} />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="trigger-table" style={{ width: "100%", maxHeight: "300px", overflowY: "auto", margin: (row?.additionalInfo?.failureReason) ? "6px 0px" : null }}>
                                  <Typography className="newText">{row?.additionalInfo?.failureReason}</Typography>
                                </div>
                                <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 600, color: "#585969" }}>{row?.additionalInfo?.message}</Typography>
                              </div>
                            </div>
                          }
                          placement="right"
                        >
                          <IconButton
                            style={{
                              background: "transparent",
                              padding: 0,
                              fontSize: "1em",
                              color: "inherit",
                            }}
                          >
                            <KeyboardDoubleArrowRightRounded
                              sx={{ fontSize: "1.5em", color: "inherit" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex" }}>
                        <IconButton
                          disabled={!row.dataUrl}
                          onClick={() => {
                            row.dataUrl && window.open(row.dataUrl);
                          }}
                          sx={{
                            height: "32px",
                            width: "32px",
                            background: "#FAFAFA",
                            ":disabled": { background: "#FAFAFA" },
                            ":hover": {
                              background: "#EBF3FF",
                              border: "1px solid #0869FB",
                            },
                          }}
                          disableRipple
                        >
                          <ExportIcon
                            stroke={!row.dataUrl ? "#82b4fd" : "#0869FB"}
                            style={{ height: "16px" }}
                          />
                        </IconButton>

                        <IconButton
                          disabled={!row.sheetId}
                          onClick={() => {
                            row.sheetId &&
                              window.open(
                                `https://docs.google.com/spreadsheets/d/${row.sheetId}`
                              );
                          }}
                          sx={{
                            height: "32px",
                            width: "32px",
                            background: "#FAFAFA",
                            ":disabled": { background: "#FAFAFA" },
                            ":hover": {
                              background: "#EBF3FF",
                              border: "1px solid #0869FB",
                            },
                          }}
                          disableRipple
                        >
                          <LinkIcon
                            stroke={!row.sheetId ? "#82b4fd" : "#0869FB"}
                            style={{ height: "16px" }}
                          />
                        </IconButton>

                        <IconButton
                          disabled={(status === "Successfull" || status === "Running" || user.role === "viewer")}
                          onClick={() => {
                            setReRunModal({
                              id: row.triggerId,
                              name: row.name,
                              logId: row._id,
                              open: true,
                              fromDate: row?.sourceReq?.startDate ?? null,
                              toDate: row?.sourceReq?.endDate ?? null
                            })
                          }}
                          sx={{
                            height: "32px",
                            width: "32px",
                            background: "#FAFAFA",
                            ":disabled": { background: "#FAFAFA" },
                            ":hover": {
                              background: "#EBF3FF",
                              border: "1px solid #0869FB",
                            },
                          }}
                          disableRipple
                        >
                          <RunIcon
                            stroke={
                              (status === "Successfull" || status === "Running" || user.role === "viewer") ? "#82b4fd" : "#0869FB"
                            }
                            fill={
                              (status === "Successfull" || status === "Running" || user.role === "viewer") ? "#A3C9FF" : "#0869FB"
                            }
                            style={{ height: "16px" }}
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="pagination"
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            MenuProps: {
              sx: {
                ".MuiTablePagination-menuItem": {
                  fontFamily: "Inter",
                  fontSize: "12px",
                  color: "#585969",
                  margin: "5px"
                }
              }
            }
          }}
        />
      </Paper>
    </div>
  );
};

export default Logs;
