import { useEffect } from "react";
import Appbar from "../Dashboard/AppBar";
import { useState } from "react";
import { getUser, updateUser } from "../../api/user/action";
import { connect } from "react-redux";
import MigrateReport from "./MigrateReport";
import ManageTeam from "./ManageTeam";
import ManageProfile from "./ManageProfile";
import IntroTour from "../Common/IntroTour";
import { useSelector } from "react-redux";
import mixpanelObj, { EVENTS } from "../../utils/mixpanel";
import { Box, Tab, Tabs } from "@mui/material";
import { a11yProps, TabPanel } from "../CustomizingSources/NavigationMenu";

const Profile = ({ user, getUser, updateUser }) => {
    const [profileTab, setProfileTab] = useState("profile");
    const [isError, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { showTutorial, run } = useSelector(state => state.user.tutorial) || {};

    useEffect(() => {
        getUser();
        if (showTutorial && run === true) {
            setProfileTab("manage");
            setValue(1);
        }
    }, []);

    return (
        <>
            {showTutorial && <IntroTour source={"profile"} />}
            <Appbar />
            <Box sx={{ width: "100%", padding: "124px 72px 10px 72px" }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: "100%",
                    background: '#FFFFFF',
                    borderRadius: '12px',
                    padding: '0px 8px',
                    position: 'relative',
                    boxShadow: '12px 12px 48px 0px rgba(80, 80, 159, 0.04)'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab-Bar'>
                        <Tab label="My Profile" {...a11yProps(0)} />
                        <Tab label="Manage Team" {...a11yProps(1)}
                            onClick={() => { mixpanelObj.track(EVENTS.click_manage_teams, {}, true) }}
                        />
                        <Tab label="Migrate Report" {...a11yProps(2)}
                            onClick={() => { mixpanelObj.track(EVENTS.click_migrate_reports, {}, true) }}
                        />
                        {/* <Tab label="Pixis Extractor" {...a11yProps(3)}
                            onClick={() => { mixpanelObj.track(EVENTS.click_extractor, {}, true) }}
                        /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ManageProfile user={user} updateUser={updateUser} isError={isError} setError={setError} message={message} setMessage={setMessage} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ManageTeam user={user} setError={setError} setMessage={setMessage} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MigrateReport user={user} />
                </TabPanel>
            </Box>
            {/* <Grid xs={7}>
                {
                    {
                        "profile": <ManageProfile user={user} updateUser={updateUser} isError={isError} setError={setError} message={message} setMessage={setMessage} />,
                        "manage": <ManageTeam user={user} setError={setError} setMessage={setMessage} />,
                        "extractor": <CustomSuperMetrics user={user} setError={setError} setMessage={setMessage} />,
                        "migrate": <MigrateReport user={user} />
                    }[profileTab]
                }
            </Grid> */}
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    getUser: () => dispatch(getUser()),
    updateUser: (_) => dispatch(updateUser(_))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
