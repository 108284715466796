import { GET_ALL_COLUMNS_SUCCESS } from "../channel/type";
import { channelType } from "./transformer";

const INITIAL_STATE = {
    accountData: {},
    tables: null,
    dimensions: [],
    metrics: [],
    currency: null,
    metricsUpdated: false,
    tablesUpdated: {}
};

const metricTypes = ["INTEGER", "FLOAT", "TIME", "PERCENT", "CURRENCY", "Number"];
const dimensionTypes = ["BOOLEAN", "DATE", "ENUM", "DATETIME", "OBJECT", "UNKNOWN", "STRING"];

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const username = action.payload.user.name;
            const email = action.payload.user.email;
            const accountList = action.payload.result.reduce((prev, curr) => prev.concat(curr), []).map(data => ({
                title: data.customer_name ?? data.customer_id,
                subtitle: data.login_customer_name,
                customerId: data.customer_id,
                loginCustomerId: data.login_customer_id,
            }))
            return { ...state, accountData: { users: [{ username: username, email: email }], accountList: accountList } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return JSON.parse(JSON.stringify(INITIAL_STATE));
        case `${channelType}.GET_TABLES_SUCCESS`:
            return { ...state, tables: action.payload, metricsUpdated: true };
        case `${channelType}.GET_COLUMNS_SUCCESS`:
            const metrics = action.payload.data.filter(c => c.type === "METRIC").map(field => ({
                name: field.name,
                id: field.id,
                dataType: field.dataType,
                type: field.type,
                group: field.group,
            }));
            const dimensions = action.payload.data.filter(c => c.type === "DIMENSION").map(field => ({
                name: field.name,
                id: field.id,
                dataType: field.dataType,
                type: field.type,
                group: field.group,
            }));
            return {
                ...state, metrics, dimensions, metricsUpdated: true,
                // metrics: { ...state.metrics, [action.payload.tableName]: metrics },
                // dimensions: { ...state.dimensions, [action.payload.tableName]: dimensions },
                // tablesUpdated: { ...state.tablesUpdated, [action.payload.tableName]: true }
            };
        case `${channelType}.GET_FIELDS_SUCCESS`:
            const allFields = {};
            Object.entries(action.payload).map(([tableName, fields]) => {
                allFields[tableName] = fields.map(field => ({
                    name: field.name,
                    id: field.id,
                    dataType: field.dataType,
                    type: field.type,
                    group: field.group,
                }));
            });
            return {
                ...state,
                dimensions: Object.entries(allFields).reduce((obj, [tableName, fields]) => ({ ...obj, [tableName]: fields.filter(c => c.type === "DIMENSION") }), {}),
                metrics: Object.entries(allFields).reduce((obj, [tableName, fields]) => ({ ...obj, [tableName]: fields.filter(c => c.type === "METRIC") }), {}),
            };
        case GET_ALL_COLUMNS_SUCCESS:
            // const newMetrics = {};

            // for (const table in state.metrics) {
            const cc = action.payload.filter(column => column.source === channelType).map(column => ({   // && column.other?.table?.id === table
                id: column.id,
                name: column.name,
                dataType: column.format ?? "FLOAT",
                group: "Customized",
            }));

            //     newMetrics[table] = state.metrics[table].filter(m => m.group !== "Customized").concat(cc);
            // }

            // return { ...state, metrics: newMetrics };
            return { ...state, metrics: state.metrics.filter(m => m.group !== "Customized").concat(cc) };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        default:
            return state;
    }
};

export default reducer;
