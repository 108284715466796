import React, { useEffect } from "react";
import ErrorIcon from "../../Assets/Images/error_icon.svg"
import SignInIcon from "../../Assets/Images/SignIn.svg";
import PixisIcon from "../../Assets/Icons/pixis.svg";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import { login, resetError } from "../../api/auth/action";
import SignInModal from '../Modal/SignInModal';
import ForgetPasswordModal from '../Modal/ForgetPasswordModal';
import { Button, Grid, InputAdornment, Link, TextField, Typography, } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';

function LoginPage(props) {
	const { login, resetError, loginFailureMessage } = props;

	const [googleResponse, setGoogleResponse] = React.useState(null);
	const [passwordModal, setPasswordModal] = React.useState(false);
	const [togglePassword, setTogglePassword] = React.useState(false);

	const [state, setState] = React.useState({
		email: "",
		password: ""
	});
	const [errors, setErrors] = React.useState({
		email: null,
		password: null
	});

	const validateData = (property, reqVal) => {
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		switch (property) {
			case "email":
				if (state.email.length === 0) {
					return reqVal ? "Email is required" : null;
				} else if (!emailRegex.test(state.email)) {
					return "Invalid Email";
				}
				break;
			case "password":
				if (state.password.length === 0) {
					return reqVal ? "Password is required" : null;
				}
				break;

			default:
				return null;
		}
		return null;
	}

	const handleChange = (property, value) => {
		setState({ ...state, [property]: value });
		setErrors({ ...errors, [property]: validateData(property) });
	}

	function parentWidth(elem) {
		return elem.parentElement.clientWidth;
	}

	const handleCustomLogin = () => {
		const newErrors = { ...errors };
		Object.keys(state).forEach(key => newErrors[key] = validateData(key, true));
		setErrors(newErrors);

		const isValid = Object.values(newErrors).find(v => v !== null) === undefined;
		if (isValid) {
			login(state);
		}
	}


	const handleGoogleResponse = (response) => {
		setGoogleResponse(response);
	}
	useEffect(() => {
		/* global google*/
		google.accounts.id.initialize({
			client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
			callback: handleGoogleResponse
		});
		renderGoogleButton();
	}, [])
	const renderGoogleButton = () => {
		google.accounts.id.renderButton(
			document.getElementById("googleSignIn"),
			{
				theme: 'outline',
				width: document.getElementById("parentElement")?.offsetWidth
			}
		);
	}
	useEffect(() => {
		window.addEventListener("resize", renderGoogleButton, false);
	}, [])
	return (
		<div style={{ flexGrow: 1, height: "100%", overflow: "auto", backgroundColor: "#fff", minHeight: "100vh" }}>
			<Grid
				container
				spacing={0}
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: "100vh", position: "relative" }}>
				<Grid sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FCFCFF' }} item sm={7} lg={7}>
					<img style={{ height: '60%' }} src={SignInIcon} alt="Login" />
				</Grid>
				<Grid container spacing={0} sx={{ backgroundColor: "#fff", textAlign: "center", overflow: "auto", height: "100%", display: 'flex', justifyContent: 'center' }} item sm={5} xs={12} lg={5}>
					<Grid xs={12} md={7} lg={7} id='parent_id'>
						<Grid style={{ padding: "4px 0 4px 0" }} item xs={12}>
							<img src={PixisIcon} style={{ width: 100 }} alt="Pixis icon" />
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} item xs={12}>
							<Typography style={{ color: "#0869FB" }} variant="h4" gutterBottom>
								Analytics
							</Typography>
						</Grid>
						<Grid item xs={12}>
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} sx={{ marginBottom: '30px' }} item xs={12}>
							<Typography style={{ color: "#222222", fontSize: 20, fontWeight: 600 }} variant="body1" gutterBottom>
								Sign In
							</Typography>
							<Typography fontSize={14} style={{ color: "#222222", fontSize: 14, opacity: 0.6 }} variant="subtitle1" gutterBottom>
								Enter your credentials to access your account
							</Typography>
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} sx={{ marginBottom: '20px' }} item xs={12}>
							<TextField
								required
								error={errors.email !== null}
								helperText={errors.email}
								label="E-Mail ID"
								placeholder="Enter Your E-Mail"
								style={{ width: "100%" }}
								value={state.email}
								onChange={_ => handleChange("email", _.target.value)}
								onKeyDown={(event) => {
									if (props.loginFailure) { resetError(); }
									if (event.key === "Enter") { handleCustomLogin(); }
								}}
								InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
								InputProps={{ style: { borderRadius: '8px' } }}
								variant="outlined"
							/>
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} sx={{ marginBottom: '10px' }} item xs={12}>
							<TextField
								required
								error={errors.password !== null}
								helperText={errors.password}
								label="Enter Password"
								placeholder="Enter Your Password"
								style={{ width: "100%" }}
								type={!togglePassword ? "password" : "text"}
								value={state.password}
								onChange={_ => handleChange("password", _.target.value)}
								onKeyDown={(event) => {
									if (props.loginFailure) { resetError(); }
									if (event.key === "Enter") { handleCustomLogin(); }
								}}
								name="password"
								InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
								InputProps={{
									style: { borderRadius: '8px' }, endAdornment: (
										<InputAdornment position="end">
											{togglePassword ? <IconButton onClick={() => setTogglePassword(!togglePassword)}><Visibility style={{ color: "#707070" }} /></IconButton> :
												<IconButton onClick={() => setTogglePassword(!togglePassword)}><VisibilityOff style={{ color: "#707070" }} /></IconButton>}
										</InputAdornment>
									)
								}}
								variant="outlined" />
						</Grid>
						{props.loginFailure ? (<Grid item xs={12} style={{ height: "60px", backgroundColor: "#FF000033", borderRadius: "8px", display: "flex", alignItems: "center", padding: "15px", margin: "4px" }}>
							<div style={{ width: "20%", display: "flex" }} >
								<img src={ErrorIcon} alt="Error!" />
							</div>
							<div style={{ width: "60%" }} >
								<b>Error!</b>
								<br />{loginFailureMessage}
							</div>
						</Grid>) : <></>}

						<Grid style={{ padding: "4px 0 4px 0" }} item xs={12}>
							<Grid sx={{ justifyContent: 'flex-end' }} container spacing={0}>
								<Typography fontSize={14} sx={{ marginRight: '2px' }}>
									<Link onClick={() => setPasswordModal(true)}>
										Forgot Password?
									</Link>
								</Typography>
							</Grid>
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} item xs={12}>
							<Button onClick={() => handleCustomLogin()} style={{ width: "100%", height: "50px", borderRadius: '8px' }} variant="contained" color="primary">
								Sign In
							</Button>
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} item xs={12}>
							<Typography fontSize={14} style={{ flexGrow: 1, height: "100%", overflow: "auto" }}>
								<span style={{ marginRight: 8 }}>Dont have an account? </span>
								<Link href="/createaccount" >
									SignUp
								</Link>
							</Typography>
						</Grid>
						<Grid style={{ padding: "4px 0 4px 0" }} item xs={12}>
							<Divider>or</Divider>
						</Grid>
						<Grid style={{ textAlign: "center", width: "100%", padding: "4px 0 4px 0" }} item xs={12} id="parentElement">
							<div id="googleSignIn" style={{ width: "100%" }} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<SignInModal googleResponse={googleResponse} setModal={_ => setGoogleResponse(null)} />
			<ForgetPasswordModal modal={passwordModal} setModal={setPasswordModal} />
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	login: (_) => dispatch(login(_)),
	resetError: () => dispatch(resetError())
})

const mapStateToProps = state => ({
	loginFailure: state.auth.loginFailure,
	loginFailureMessage: state.auth.loginFailureMessage
});

export default (connect(mapStateToProps, mapDispatchToProps)(LoginPage))
