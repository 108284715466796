import { useEffect, useState } from "react";
// import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from "react-redux";
import { Grid, ListItem, Stack, Typography, Link, IconButton } from "@mui/material";
import { GetChannel, SupportedChannels } from "../../../models/channel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditTableData from "./EditTableData";
import InfoIcon from '@mui/icons-material/Info';
import { useHistory } from "react-router-dom";
import { ReactComponent as NoResultsIcon } from "../../../Assets/errorMsgImages/NoResults.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/delete.svg";
import SearchBar from "../../Common/SearchBar";
import DataList from "./DataList";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../../api/user/action";
import { getAccountBasedColumns } from "../../../api/channel/saga";

const SelectDataSource = ({ source = 0, selectedSources, setSelectedSources, newSourceData, setNewSourceData, authenticatedAccounts, selectedAccounts }) => {

    const dispatch = useDispatch();
    const [updateData, setUpdateData] = useState(newSourceData.dataSources[source])
    const [channel, setChannel] = useState(GetChannel(updateData.source?.type))
    const [filteredChannels, setFilteredChannels] = useState(SupportedChannels);
    const [searchText, setSearchText] = useState("");
    const history = useHistory();
    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelAccountSelected = channel => channel.type in selectedAccounts;

    useEffect(() => {
        let dataSources = newSourceData.dataSources;
        dataSources[source] = updateData;
        setNewSourceData({ ...newSourceData, dataSources })
    }, [updateData])

    useEffect(() => {
        const join = newSourceData.dataSources[source].join;
        if (join) {
            setUpdateData({ ...updateData, join })
        }
    }, [newSourceData.dataSources[source].join])

    const state = useSelector(state => channel?.getState(state));
    const accountList = (state?.accountData?.accountList ?? []).map((acc, index) => ({ id: index, name: acc.title, ...acc }));
    const [account, setAccount] = useState(updateData?.account);
    const [accountCols, setAccountCols] = useState(null);

    useEffect(() => {
        if (!channel?.isBlend && account) {
            dispatch(updateLoader(true));
            getAccountBasedColumns(account, channel).then(data => {
                let columns = data?.columns ?? [];
                setAccountCols({
                    metrics: columns.filter(c => c.type === "METRIC"),
                    dimensions: columns.filter(c => c.type === "DIMENSION")
                });
            }).catch(err => {
                console.log("🚀 ~ file: EditChart.js:67 ~ getAccountBasedColumns ~ err:", err)
                setAccountCols({ metrics: [], dimensions: [] })
            }).finally(() => {
                dispatch(updateLoader(false));
            });
            setUpdateData({ ...updateData, account });
        } else {
            setAccountCols(null);
        }
    }, [account]);


    const updateChannel = (channel) => {
        const accounts = selectedSources.accounts;
        if (channel) {
            setChannel(channel)
            accounts[source] = channel.type;
            setSelectedSources({ ...selectedSources, accounts, count: selectedSources.count + 1 })
            setUpdateData({ ...updateData, source: { type: channel.type, icon: channel.icon, title: channel.title } })
        } else {
            setChannel(null);
            setAccount(null);
            accounts[source] = null
            setSelectedSources({ ...selectedSources, accounts, count: selectedSources.count - 1 })
            setUpdateData({ id: updateData.id, account: null, keys: [], dimensions: [], metrics: [] })
        }
    }

    const removeSource = () => {
        let dataSources = newSourceData.dataSources;
        if (source > 0) dataSources[source - 1].join = dataSources[source].join;
        dataSources.splice(source, 1);
        setNewSourceData({ ...newSourceData, dataSources });
        let accounts = selectedSources.accounts;
        let count = selectedSources.count;
        accounts.splice(source, 1);
        count = updateData.source ? count - 1 : count;
        setSelectedSources({ ...selectedSources, accounts, count });
    }

    return (
        <>
            <Grid item xs={12} >
                <div className="displayRowSpaced">
                    {!channel
                        ? <p className="h18" style={{ marginBottom: "10px" }}>Select a data source</p>
                        : <p className="h18" style={{ marginBottom: "10px" }}>
                            <IconButton
                                onClick={() => updateChannel(null)}
                                style={{ marginRight: "10px", padding: "8px", background: "#F3F4F8" }}
                                aria-label="go back"
                            >
                                <ArrowBackIcon style={{ width: 16, height: 16 }} />
                            </IconButton>
                            {channel.title}
                        </p>
                    }
                    {newSourceData.dataSources.length > 2 && <DeleteIcon style={{ marginBottom: "10px", cursor: "pointer" }} onClick={() => removeSource()} />}
                </div>
            </Grid>
            <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px 15px" }} />
            <Grid container item xs={12} spacing={2} style={{ height: "calc(100% - 45px)", width: "calc(100% + 32px)", maxWidth: "calc(100% + 32px)" }}>
                {!channel
                    ? <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", borderRight: "1px solid #EAEAEC", padding: "16px" }}>
                        <div>
                            <SearchBar filteredData={SupportedChannels} setFilteredData={setFilteredChannels} keys={["name", "title"]} />
                            <div className="inter h14_light" style={{ marginTop: "15px" }}>
                                Connected data sources
                            </div>
                            <Stack spacing={0}>
                                {filteredChannels
                                    .filter(channel => (isChannelAuthenticated(channel) && !selectedSources.accounts.includes(channel.type)))
                                    .map((channel, index) =>
                                        <ListItem key={index} onClick={() => updateChannel(channel)} sx={{ cursor: 'pointer' }}>
                                            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                                <div>
                                                    <img style={{ marginRight: 16, height: 20, width: 20 }} src={channel.icon} alt={channel.title} /></div>
                                                <div>
                                                    <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 500, color: "#333" }}>
                                                        {channel.title}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                    )}
                            </Stack>

                            {SupportedChannels.length !== authenticatedAccounts.length &&
                                <>
                                    <div className="inter h14_light" style={{ marginTop: "15px" }}>
                                        Non-Connected data sources
                                    </div>

                                    <Stack spacing={0}>
                                        {filteredChannels
                                            .filter(channel => !isChannelAuthenticated(channel))
                                            .map((channel, index) =>
                                                <ListItem key={index}>
                                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <img style={{ marginRight: 16, height: 20, width: 20 }} src={channel.icon} alt={channel.title} />
                                                            <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 500, color: "#333" }}>
                                                                {channel.title}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{ borderRadius: '8px', fontSize: "12px", color: "#1877F2", margin: "auto 5px" }}
                                                            onClick={() => history.push('/managesources')}
                                                        >
                                                            Connect
                                                        </div>
                                                    </div>
                                                </ListItem>
                                            )}
                                    </Stack>
                                </>
                            }
                        </div>
                        {/* {SupportedChannels.length !== Object.keys(selectedAccounts).length &&
                            <div style={{ borderRadius: "8px", background: "#1877F211", display: "flex", alignItems: "center", margin: "5px", padding: "8px", marginBottom: "20px" }}>
                                <InfoIcon style={{ color: "#999999", margin: "0px 5px" }} />
                                <div style={{ paddingLeft: "5px", fontSize: "10px" }}>
                                    You need to choose one Account ID for all connected data sources to blend data. Please goto <Link href="/managesources" > "Manage Data Source"</Link> Page.
                                </div>
                            </div>
                        } */}
                    </Grid>
                    : <Grid item xs={6} style={{ height: "100%", padding: "16px", borderRight: "1px solid #EAEAEC", }}>
                        {/* account type */}
                        {/* <div style={{ marginBottom: "10px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Account Id
                                </h4>
                            </div>
                            <DataList
                                channel={channel}
                                metric={account}
                                metrics={accountList}
                                isEditable={true}
                                onClick={accObj => setAccount(accObj)}
                                defaultTitle="Add Account"
                            />
                        </div> */}
                        <div style={{ height: "100%" }}>
                            <EditTableData
                                key={updateData.dimensions.length + "-" + updateData.metrics.length + "-" + updateData.keys.length}
                                type={1}
                                channel={channel}
                                accountCols={accountCols}
                                updateData={updateData}
                                setUpdateData={setUpdateData} />
                        </div>
                    </Grid>
                }
                <Grid item xs={6} style={{ height: "100%", padding: "16px" }}>
                    <div className="inter h14_light" style={{ marginBottom: "10px" }}>
                        Available fields
                    </div>
                    <SearchBar setFilterText={setSearchText} keys={["name", "title"]} />
                    <div style={{ height: "calc(100% - 80px)" }}>
                        {channel ?
                            <EditTableData
                                key={updateData.dimensions.length + "-" + updateData.metrics.length + "-" + updateData.keys.length + updateData.table?.id + updateData.dataLevel?.id}
                                type={2}
                                channel={channel}
                                accountCols={accountCols}
                                updateData={updateData}
                                setUpdateData={setUpdateData}
                                searchText={searchText}
                            /> :
                            <div className="displayColCenter highlight1" style={{ height: "95%" }}>
                                <NoResultsIcon width="200px" height="200px" />
                                <h3 style={{ fontFamily: "Inter", fontSize: "14px", color: "#666666", textAlign: "center" }}>
                                    Select a data source to proceed
                                </h3>
                            </div>}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = state => ({
    selectedAccounts: state.channel.selectedAccounts,
    authenticatedAccounts: state.channel.authenticatedAccounts
});

export default connect(mapStateToProps)(SelectDataSource);
