import { GET_ALL_COLUMNS_SUCCESS, SELECT_ACCOUNT } from "../channel/type";
import { channelType } from "./transformer";

const INITIAL_STATE = {
    accountData: {},
    segments: [
        {
            name: "View through attribution window",
            values: [
                { id: "1d_view", name: "1 day" },
                { id: "1d_ev", name: "1 day engagement" },
                // { id: "7d_view", name: "7 days" },
                // { id: "28d_view", name: "28 days" },
            ],
        },
        {
            name: "Click through attribution window",
            values: [
                { id: "1d_click", name: "1 day" },
                { id: "7d_click", name: "7 days" },
                { id: "28d_click", name: "28 days" },
            ],
        },
        {
            name: "SKad Network API",
            values: [
                { id: "skan_view", name: "View", value: false },
                { id: "skan_click", name: "Click", value: true },
            ],
        },
    ],
    dimensions: [],
    metrics: [],
    customDimensions: [],
    customMetrics: [],
    currency: null,
    metricsUpdated: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const username = action.payload.user_data.name;
            const email = action.payload.user_data.id;
            const accountList = action.payload.account_data.data.map(data => ({
                title: data.name,
                subtitle: data.id,
                accountId: data.account_id,
            }))
            return { ...state, accountData: { users: [{ username: username, email: email }], accountList: accountList } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return JSON.parse(JSON.stringify(INITIAL_STATE));
        case `${channelType}.GET_FIELDS_SUCCESS`:
            const dimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const metrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`:
            const customDimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const customMetrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, customDimensions: customDimensions, customMetrics: customMetrics, metricsUpdated: state.metrics.length && state.dimensions.length };
        case GET_ALL_COLUMNS_SUCCESS:
            const cc = action.payload.filter(column => column.source === channelType).map(column => ({
                id: column.id,
                name: column.name,
                dataType: column.format ?? "FLOAT",
                group: "Customized",
            }));

            return { ...state, metrics: state.metrics.filter(m => m.group !== "Customized").concat(cc) };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        case SELECT_ACCOUNT:
            if (action.payload.channel === channelType) {
                return { ...state, customDimensions: [], customMetrics: [], metricsUpdated: false };
            }
            return state;
        default:
            return state;
    }
};

export default reducer;
