import { GetChannel } from "../../models/channel";
import { GET_ALL_COLUMNS_SUCCESS } from "../channel/type";

const INITIAL_STATE = {
    blends: [],
    blendCustomCols: {}
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'BLENDS_LIST_SUCCESS':
            let eachBlend = action?.payload?.blends?.reduce((obj, blend) => ({
                ...obj, [blend._id]: {
                    dimensions: [], metrics: [], metricsUpdated: true,
                    dataSources: blend.dsConfigs.map(d => d.dataSource),
                }
            }), {})
            return { ...state, blends: action?.payload?.blends?.map(blend => ({ id: blend._id, ...blend })), ...eachBlend };
        case 'BLENDS_GET_COLUMNS_SUCCESS':
            const columns = action.payload.columns.map(c => ({
                id: c.id,
                name: `${c.uiName} (${GetChannel(c.dataSource).title})`,
                group: c.group,
                dataType: c.dataType,
                type: c.type,
                dataSource: c.dataSource,
                miscInfo: c.miscInfo
            }))
            return {
                ...state,
                [action.payload.blendId]: {
                    dimensions: columns.filter(c => c.type === "DIMENSION"),
                    metrics: columns.filter(c => c.type === "METRIC").concat(state.blendCustomCols[action.payload.blendId] ?? []),
                    metricsUpdated: true,
                    dataSources: state.blends.find(blend => blend.id === action.payload.blendId)?.dsConfigs.map(d => d.dataSource),
                },
            };
        case GET_ALL_COLUMNS_SUCCESS:
            const allBelndCC = action.payload.filter(column => column.other?.is_blended_datasource === true).map(column => ({
                id: column.id,
                name: column.name,
                dataType: column.format ?? "FLOAT",
                group: "Customized",
                dataSource: column.source,
                type: "METRIC"
            }));
            return { ...state, blendCustomCols: allBelndCC.reduce((group, cc) => ({ ...group, [cc.dataSource]: (group[cc.dataSource] ?? []).concat(cc) }), {}) };
        default:
            return state;
    }
}

export default reducer;
