import { useEffect, useState } from "react";
import EditDataSource from "./EditDataSources";
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Grid, Button, Typography, Link, Tooltip, IconButton } from "@mui/material";
import { ReactComponent as NoResultsIcon } from "../../../Assets/errorMsgImages/NoResults.svg";
import { ReactComponent as SelectNewIcon } from "../../../Assets/Images/CreateNew.svg";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/delete.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/Icons/info-square.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { blendsList, deleteBlend } from "../../../api/blends/action";
import { GetChannel } from "../../../models/channel";
import { getAllCustomDimensions } from "../../../api/channel/action";
import SuccessModal from "../../Modal/SuccessModal";
import FailureModal from "../../Modal/FailureModal";
import { updateLoader } from "../../../api/user/action";
import SearchBar from "../../Common/SearchBar";
import mixpanelObj, { EVENTS, transformMixPanelData } from "../../../utils/mixpanel";
import DeleteModal from "../../Modal/DeleteModal";
import "../../styles/main.css";

const BlendDataSource = (props) => {
    const { blendsList, blends, deleteBlend, user, allCustomDims, getAllCustomDimensions, updateLoader } = props;
    const [updateId, setUpdateId] = useState(null);
    const [displaySources, setDisplaySources] = useState(false);
    const [completion, setCompletion] = useState({ message: "none", operation: "none" });
    const [blendModal, setBlendModal] = useState(false);
    const [currentBlend, setCurrentBlend] = useState(null);

    useEffect(() => {
        setDisplaySources(false)
    }, [completion])

    const [filteredBlends, setFilteredBlends] = useState(blends ?? [])
    useEffect(() => {
        setFilteredBlends(blends ?? [])
    }, [blends])

    const deleteSource = (sourceId) => {
        updateLoader(true);
        deleteBlend({
            id: sourceId,
            loading: (msg) => {
                updateLoader(false);
                setCompletion({ message: msg, operation: "Deletion" });
            }
        });
    }

    const resetData = () => {
        setUpdateId(null);
        setDisplaySources(false);
    }

    useEffect(() => {
        updateLoader(true);
        blendsList({ update: () => updateLoader(false) });
        if (!allCustomDims || !allCustomDims.length) {
            getAllCustomDimensions();
        }
    }, []);

    const [isInfo, setIsInfo] = useState(false);

    return (
        <>
            <div className="main_container" >
                <Grid container flexDirection={"column"} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2} style={{ margin: "15px auto", height: "100vh", width: "100%" }}>
                    <Grid item xs={3} className="gridItem" style={{ padding: "0px", minHeight: "100%" }} >
                        <div style={{ padding: "20px 0px", minHeight: "100%", height: "100%" }}>
                            <div style={{ minHeight: "calc(100% - 50px)", height: "100%" }}>
                                {isInfo ?
                                    <Scrollbars autoHide style={{ height: "100%" }}>
                                        <div style={{ padding: "0px 20px", height: "100%" }}>
                                            <div className=" h18 lato displayRowSpaced" style={{ margin: "10px 0px" }}>
                                                <span className="displayRowCenter">
                                                    <IconButton
                                                        onClick={() => setIsInfo(false)}
                                                        style={{ marginRight: "10px", padding: "8px", background: "#F3F4F8" }}
                                                        aria-label="go back"
                                                    >
                                                        <ArrowBackIcon style={{ width: 16, height: 16 }} />
                                                    </IconButton>
                                                    Info
                                                </span>
                                                <InfoIcon />
                                            </div>
                                            <hr style={{ border: "1px solid #EAEAEC", margin: "10px 0px 20px 0px" }} />
                                            <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #E5E9F4", position: "relative", color: "#6D6E7C" }}>
                                                <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                                Data Blending is one of the helpful features developed to analyze the and club the marketing data by joining different sources such as ads channel metrics i.e impressions, clicks, spends etc with attribution channels metrics no. of users, landing page view, sessions etc and visualize into the same widget.
                                            </div>
                                            <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #E5E9F4", position: "relative", color: "#6D6E7C" }}>
                                                <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                                While joining two or more sources, you need to specify the relationship between two chosen tables whether it’s an inner, full outer, left join and right join.
                                                For more information across these joins, please go through this <Link href="https://dataschool.com/how-to-teach-people-sql/sql-join-types-explained-visually/">link</Link>.
                                                Note: You need to specify the same joining key across different data sources for accurate results with mandatory at least one dimension and metric.
                                            </div>
                                            <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #FFFFFF", position: "relative", color: "#6D6E7C" }}>
                                                <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                                Once you’ve created your data source, you can go to “Edit Widget” -{">"} “Data Sources” -{">"} and choose your customized data source to collaborate metrics within the same view and fasten your action using those visualizations.
                                                Example: Spends from different ads platforms into one line chart.
                                            </div>
                                        </div>
                                    </Scrollbars> :
                                    <div className="displayColCenter" style={{ padding: "0px 20px", height: "100%" }}>
                                        <div className="h18 lato displayRowSpaced" style={{ margin: "10px 0px 20px 0px" }}>
                                            Existing Data Sources
                                            <InfoIcon className="pointer" onClick={() => setIsInfo(true)} />
                                        </div>
                                        <SearchBar filteredData={blends} setFilteredData={setFilteredBlends} keys={["name", "lastSheetName", "lastTabName"]} />
                                        {blends.length ?
                                            <Scrollbars autoHide style={{ height: "90%", marginTop: "5px", width: "calc(100% + 40px)" }}>
                                                {
                                                    filteredBlends.map(source => {
                                                        return (
                                                            <div style={{ margin: "10px 20px", border: "1px solid #EAEAEC", borderRadius: "6px", padding: "10px 15px " }}>
                                                                <div className="displayRowSpaced">
                                                                    <Typography variant="body1" className="h14">
                                                                        {source.name}
                                                                    </Typography>
                                                                    <div style={{ display: "flex" }}>
                                                                        <EditIcon className="pointer"
                                                                            style={{ marginLeft: "8px", width: "16px", height: "16px" }}
                                                                            onClick={() => { setUpdateId(source.id); setDisplaySources(true); transformMixPanelData(EVENTS.click_existing_blend, source) }}
                                                                        />
                                                                        {user.role !== "viewer" && <DeleteIcon className="pointer"
                                                                            style={{ marginLeft: "8px", width: "16px", height: "16px" }}
                                                                            onClick={() => { setBlendModal(!blendModal); setCurrentBlend(source); transformMixPanelData(EVENTS.delete_custom_blend, source) }}
                                                                        />}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    source.dsConfigs.map(src => {
                                                                        const channel = GetChannel(src.dataSource);
                                                                        return <img style={{ margin: "6px 15px 6px 0px", height: 16, width: 16 }} src={channel.icon} alt={channel.title} />
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Scrollbars> :
                                            <div className="displayColCenter highlight1" style={{ height: "90%", marginTop: "15px" }}>
                                                <NoResultsIcon width="200px" height="200px" />
                                                <h3 style={{ fontFamily: "Inter", fontSize: "14px", color: "#666666", textAlign: "center" }}>
                                                    There is not any custom data source added. <br />
                                                    Create a new source to proceed.
                                                </h3>
                                            </div>}
                                    </div>}
                            </div>
                        </div>
                    </Grid>
                    {displaySources ?
                        <EditDataSource key={updateId} source={blends.find(source => source.id === updateId)} setCompletion={setCompletion} resetData={resetData} />
                        : <Grid container justifyContent="center" alignItems="center" item xs={9} style={{ paddingTop: 0, minHeight: "100%" }}>
                            <Tooltip title={user.role === "viewer" ? "Viewer does not have permission to create!" : ""} placement="top">
                                <div className="gridItem displayColCenter" style={{ minHeight: "30%", height: "30%", width: "30%", position: "relative" }}>
                                    <SelectNewIcon disabled style={{ cursor: "pointer", marginBottom: "20px" }} onClick={() => { setUpdateId(null); setDisplaySources(true); mixpanelObj.track(EVENTS.click_new_source, {}, true) }} />
                                    <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}>Create New Source</Typography>
                                    <Typography style={{ fontSize: "12px", color: "#6D6D6D", textAlign: "center" }}>Join multiple sources using ads and attribution channels.</Typography>
                                    {
                                        (user.role === "viewer") &&
                                        <div style={{ position: "absolute", background: "#FFFFFFAA", height: "100%", width: "100%" }}>
                                        </div>
                                    }
                                </div>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            </div>
            <SuccessModal
                modal={completion.message === "Success"}
                setModal={() => setCompletion({ message: "none", operation: "none" })}
                message={`${completion.operation} of Blends Source is Successful`}
            />

            <FailureModal
                modal={completion.message === "Fail"}
                setModal={() => setCompletion({ message: "none", operation: "none" })}
                message={`${completion.operation} of Blends Source Failed`}
            />
            {currentBlend && <DeleteModal
                modal={blendModal}
                setModal={setBlendModal}
                message={`Do you really want to delete this ${currentBlend.name} blend?`}
                onDelete={() => deleteSource(currentBlend.id)}
            />}
        </>
    )
}

const mapStateToProps = state => ({
    blends: state.blends.blends,
    user: state.user.user,
    allCustomDims: state.channel.allCustomDims
});

const mapDispatchToProps = dispatch => ({
    blendsList: (_) => dispatch(blendsList(_)),
    deleteBlend: (_) => dispatch(deleteBlend(_)),
    getAllCustomDimensions: () => dispatch(getAllCustomDimensions()),
    updateLoader: (_) => dispatch(updateLoader(_))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlendDataSource);
