import Appbar from "../Dashboard/AppBar";
import NavigationPane from "./NavigationPane";

const SuperMetrics = (props) => {
  return (
    <div>
      <Appbar />
      <NavigationPane style={{ marginBottom: "50px" }} />
    </div>
  );
};

export default SuperMetrics;
