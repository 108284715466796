import Modal from 'react-modal';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import shopifyLogo from "../../Assets/Images/logos_shopify.png";
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { channelType } from '../../api/shopify-ads/transformer';
import { closeConnecting } from '../../api/shopify-ads/action';
import { connectAccountApi } from '../../api/shopify-ads/action';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#ffff",
        width: "400px",
        padding: "0",
        zIndex: 9000000000000,
        position: "absolute"
    },
};

function ShopifyConnectModal({ isConnecting, connectAccountApi, close }) {
    const [state, setState] = useState({
        shopify_access_token: "",
        store_name: ""
    });

    useEffect(() => {
        close();
    }, []);

    const handleChange = (property, value) => {
        setState({ ...state, [property]: value });
    }

    return (
        <Modal isOpen={isConnecting} style={customStyles}>
            <div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <b><img src={shopifyLogo} style={{ width: "20px", marginRight: "5px" }} alt="Shopify" /> Connect your shopify account</b>
                </div>
                <div>
                    <IconButton aria-label="save" onClick={close}>
                        <Close sx={{
                            borderRadius: '4px',
                            padding: '4px',
                            backgroundColor: '#F2F2F2'
                        }}
                        />
                    </IconButton>
                </div>
            </div>
            <div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", marginTop: '20px' }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "70%" }}>
                    <Grid xs={12}>
                        <p style={{ fontSize: "12px", opacity: "0.75" }}><q>
                            Follow these <a href={`${process.env.REACT_APP_BASE_URL}/connect-shopify-account`} target="_blank" rel="noreferrer">instructions</a> to connect your shopify account.
                            Please make sure that you're adding "read_analytics", "read_gift_cards", "read_locales", "read_locations", "read_reports", "read_shipping", "read_orders", and "read_products" in the scope while creating app.
                        </q></p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
                        <h3 style={{ textAlign: "left", marginBottom: "14px" }}><b>New Shopify account</b></h3>
                        <TextField
                            placeholder="shopname.myshopify.com"
                            label="Shop URL"
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            variant="outlined"
                            onChange={(e) => handleChange("store_name", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "20px" }}>
                        <TextField
                            style={{ width: '100%' }}
                            placeholder='shpat_*************************'
                            size="small"
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            label="Access token"
                            variant="outlined"
                            onChange={(e) => handleChange("shopify_access_token", e.target.value)}
                        />
                    </Grid>
                </div>
                <div style={{ width: "100%", borderTop: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                    <div>
                        <Button
                            sx={{ padding: "5px 20px", textAlign: "right", float: "right", borderRadius: "10px", textTransform: "none" }}
                            variant="contained"
                            onClick={() => connectAccountApi({
                                redirect_url: "selectchannels",
                                ...state
                            })}
                        >Connect Account</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const mapStateToProps = state => ({
    isConnecting: state[channelType].isConnecting,
})

const mapDispatchToProps = dispatch => ({
    connectAccountApi: (_) => dispatch(connectAccountApi(_)),
    close: () => dispatch(closeConnecting()),
})

export default (connect(mapStateToProps, mapDispatchToProps)(ShopifyConnectModal));
