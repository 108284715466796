import React, { createRef, useEffect } from "react";
import {
  Box,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Tab,
  Tabs,
  Button,
  Select,
  Typography,
  Tooltip,
  Menu,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { Errorhandler } from "../ErrorHanlder/ErrorHandler";
import AppBar from "./AppBar";
import Sidebar from "../EditWidget/Sidebar";
import { styled } from "@mui/material/styles";
import CalendarLogo from "../../Assets/Images/logos_calendar.svg";
import ChartWrapper from "../Charts/ChartWrapper";
import { connect } from "react-redux";
import { checkAuth, getAllCustomColumns } from "../../api/channel/action";
import { changeDate, updateFilterChartMap } from "../../api/dashboard/action";
import { GetChannel } from "../../models/channel";
import { useHistory, useParams } from "react-router-dom";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { sendFingerprint } from "../../api/auth/saga";
import { getUser, updateLoader } from "../../api/user/action";
import RGL, { WidthProvider } from "react-grid-layout";
import { ReactComponent as AddChartLogo } from "../../Assets/Images/logos_add_chart.svg";
import { ReactComponent as RemoveChartLogo } from "../../Assets/Images/logos_remove_chart.svg";
import { Chart } from "../../models/chart";
import {
  Report,
  Tab as ReportTab,
  Grid as ReportGrid,
} from "../../models/report";
import CustomReportIcon from "../../Assets/ChannelIcons/custom_report.svg";
import {
  createReport,
  updateReport,
  deleteReport,
  getDefaultReport,
  setDefaultReport,
  getAllReports,
  getReport,
  updateChart,
  updateGrid,
  updateTab,
} from "../../api/report/action";
import { ReactComponent as AddTab } from "../../Assets/Icons/tab-icon.svg";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/filterIcon.svg";
import ThreeDots from "../../Assets/Images/logos_threedots.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditNameModal from "../Modal/EditNameModal";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableTab from "./DraggableTab";
import { TabContext } from "@mui/lab";
import { blendsList } from "../../api/blends/action";
import { ChartFilterSupport, DateDisplayFormatter } from "../Charts/ChartUtils";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FilterListModal from "../Filteration/FilterListModal";
import ProductTourModal from "../Modal/ProductTourModal";
import IntroTour from "../Common/IntroTour";
import { useSelector } from "react-redux";
import DateRangeModal from "../Modal/DateRangeModal";
import DashboardLoader from "../Common/DashboardLoader";
import mixpanelObj, {
  EVENTS,
  transformMixPanelData,
} from "../../utils/mixpanel";
import DeleteModal from "../Modal/DeleteModal";
import { ReactComponent as RefreshIcon } from "../../Assets/Icons/refresh-icon.svg";
import { ReactComponent as CalenderNewIcon } from "../../Assets/Icons/calender-borderless.svg";
import { ReactComponent as DropIcon } from "../../Assets/Icons/drop-icon.svg";
import { ReactComponent as EditIcon } from "../../Assets/Icons/edit-icon.svg";
import { ReactComponent as DefaultIcon } from "../../Assets/Icons/default_icon.svg";
import { ReactComponent as ExportIcon } from "../../Assets/Icons/export-icon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Icons/delete-icon.svg";
import { ReactComponent as AddFile } from "../../Assets/Icons/add-file-icon.svg";
import moment from "moment";

const ReactGridLayout = WidthProvider(RGL);

const drawerWidth = 364;
const SEPARATOR = ":";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
    ...(open && {
      width: `calc(100% - 375px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: 0,
      }),
      marginRight: drawerWidth,
    }),
  })
);

const DrawerHeader = styled("div")(() => ({
  display: "flex",
  width: "100%",
  height: "100px",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Dashboard({
  selectedAccounts,
  allReports,
  reports,
  defaultReportId,
  reportDownloaded,
  dateRange,
  changeDate,
  filterChartMap,
  updateFilterChartMap,
  checkAuth,
  getUser,
  updateLoader,
  createReport,
  updateReport,
  deleteReport,
  setDefaultReport,
  getDefaultReport,
  getAllReports,
  getReport,
  updateChart,
  updateGrid,
  updateTab,
  blendsList,
  getAllCustomColumns,
  blends,
}) {
  const { channel: reportId, tab: tabPath } = useParams();

  useEffect(() => {
    getDefaultReport();
  }, [defaultReportId]);

  useEffect(() => {
    if (!reportId || !allReports.find((report) => report.id === reportId)) {
      if (allReports.length > 0) {
        if (
          defaultReportId &&
          allReports.find((report) => report.id === defaultReportId)
        ) {
          window.location.href = `/dashboard/${defaultReportId}`;
        } else {
          window.location.href = `/dashboard/${allReports[0].id}`;
        }
      } else {
        getAllReports();
      }
    }
  }, [allReports]);

  const [report, setReport] = React.useState(
    Report.fromJSON(reports[reportId]) ?? {}
  );

  useEffect(() => {
    let reportJSON = Report.fromJSON(reports[reportId]) ?? {};
    setReport(reportJSON);
    if (!reportJSON || !Object.keys(reportJSON).length) {
      getReport({ id: reportId });
    }
  }, [reportId]);

  useEffect(() => {
    setReport(Report.fromJSON(reports[reportId]) ?? {});
  }, [reports]);

  const [startTour, setStartTour] = React.useState(false);
  const [filterListOpen, setFilterListOpen] = React.useState(false);
  const [updateNameModal, setUpdateNameModal] = React.useState({
    show: false,
    update: false,
  });
  const [isReportUpdated, setIsReportUpdated] = React.useState(false);
  const stateUpdateNumber = React.useRef(0);

  const [currentTabIndex, setCurrentTabIndex] = React.useState(
    report.tabs?.findIndex((t) => t.path === tabPath) ?? 0
  );
  const [headerModal, setHeaderModal] = React.useState(false);

  const viewDate = (date) => moment(date).format("Do MMM YYYY");

  useEffect(() => {
    let tabIndex = report.tabs?.findIndex((t) => t.path === tabPath) ?? 0;
    if (tabIndex < 0) {
      setCurrentTabIndex(0);
      history.push(`/dashboard/${reportId}/${report.tabs[0].path}`);
    } else {
      setCurrentTabIndex(tabIndex);
    }
  }, [tabPath]);

  useEffect(() => {
    if (currentTabIndex < 0) {
      setCurrentTabIndex(0);
      history.push(`/dashboard/${reportId}/${report.tabs[0].path}`);
    }
    setUpdateCount((prev) => prev + 1);
  }, [currentTabIndex]);

  useEffect(() => {
    if (!tabPath && report.tabs) {
      setCurrentTabIndex(0);
      window.location.href = `/dashboard/${reportId}/${report.tabs[0].path}`;
    } else {
      let tabIndex = report.tabs?.findIndex((t) => t.path === tabPath) ?? 0;
      if (tabIndex !== currentTabIndex) {
        setCurrentTabIndex(tabIndex);
      }
    }
  }, [report]);

  const history = useHistory();

  const [editDrawerState, setEditDrawerState] = React.useState({
    isOpen: false,
    chart: null,
    grid: null,
    handleGridChange: (property, value, gridId) => {
      updateGrid({
        reportId: report.id,
        updates: [
          {
            grid_id: gridId,
            update_type: "update",
            params: { [property]: value },
          },
        ],
      });
    },
    handleChartChange: (property, value) => { },
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [exportTabData, setExportTabData] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setExportTabData(false);
  };

  const [open, setOpen] = React.useState(false);
  const [notifyOpen, setNotifyOpen] = React.useState(false);

  const openErrorMsg = () => {
    setOpen(true);
  };
  const closeErrorMsg = () => {
    setOpen(false);
  };

  const [notifyMessage, setNotifyMessage] = React.useState("");
  const notifyBarOpen = () => {
    setNotifyOpen(true);
  };
  const notifyBarClose = () => {
    setNotifyOpen(false);
    setNotifyMessage("");
  };

  const { data: visitorData } = useVisitorData();

  const [refs, setRefs] = React.useState({});

  const handleAddNew = (title, type, icon) => {
    if (type === "Report") {
      createReport({ report: Report.new(title, icon) });
    } else {
      const tab = ReportTab.new(title, report.tabs.length);
      const grid = tab.grids[0];
      const charts = grid.charts;
      updateTab({
        reportId: report.id,
        updates: [{ tab_id: tab.id, update_type: "add", params: tab.toJSON() }],
        navigate: () => {
          navigateTo(report.id, tab.path);
        },
      });
      updateGrid({
        reportId: report.id,
        updates: [
          { grid_id: grid.id, update_type: "add", params: grid.toJSON() },
        ],
      });
      updateChart({
        reportId: report.id,
        updates: charts.map((chart) => {
          const ref = createRef();
          setRefs({ ...refs, [chart.id]: ref });
          return {
            chart_id: chart.id,
            update_type: "add",
            params: chart.toJSON(),
          };
        }),
      });
    }
    setUpdateNameModal({ show: false, update: false });
  };

  const updateName = (title, type, icon) => {
    if (type === "Report") {
      updateReport({
        reportId: report.id,
        params: { report_name: title, report_icon: icon },
      });
    } else {
      let path = Report.getTabPathFromTitle(title);
      report.tabs[currentTabIndex].path = path;
      updateTab({
        reportId: report.id,
        updates: [
          {
            tab_id: report.tabs[currentTabIndex].id,
            update_type: "update",
            params: { name: title, path },
          },
        ],
      });
      navigateTo(report.id, path);
    }
    setUpdateNameModal({ show: false, update: false });
  };

  const handleAllChartsForceReload = (filterId) => {
    filterChartMap[filterId]?.forEach((chartId) => {
      refs[chartId].current?.forceUpdateChart();
    });
  };

  const handleTabCsvExport = (tabIndex) => {
    updateLoader(true);
    var date = [
      "Date Range: ",
      DateDisplayFormatter(dateRange.startDate) +
      " - " +
      DateDisplayFormatter(dateRange.endDate),
    ];
    let allData = [date, [], []];
    Object.keys(refs).forEach((chartId) => {
      const data = refs[chartId].current?.handleAllExport();
      if (data) {
        allData = [...allData, ...data, [], []];
      }
    });
    const blob = new Blob([allData.join("\n")], {
      type: "text/csv",
    });
    const a = document.createElement("a");
    a.download = report.tabs[tabIndex].name
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    a.href = window.URL.createObjectURL(blob);
    a.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
    a.remove();
    updateLoader(false);
  };

  const padding = 15;
  let top = 15;

  const handleTabPdfExport = async (tabIndex) => {
    updateLoader(true);
    var date = `Date Range:  ${DateDisplayFormatter(
      dateRange.startDate
    )} - ${DateDisplayFormatter(dateRange.endDate)}`;
    const name = report.tabs[tabIndex].name
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    const doc = new jsPDF({});
    doc.text([date], padding, top);
    top += padding;
    for (let chartId of Object.keys(refs)) {
      top = await refs[chartId].current?.handleAllPdfExport(doc, top);
    }
    doc.save(`${name}.pdf`);
    updateLoader(false);
  };

  const handleTabPngExport = () => {
    Object.keys(refs).forEach((chartId) => {
      refs[chartId].current?.handleAllPngExport();
    });
  };

  const handleTabJpgExport = () => {
    Object.keys(refs).forEach((chartId) => {
      refs[chartId].current?.handleAllJpgExport();
    });
  };

  const handleTabDelete = (tabIndex) => {
    if (report.tabs.length <= 1) {
      return;
    }
    updateTab({
      reportId: report.id,
      updates: [
        { tab_id: report.tabs[tabIndex].id, update_type: "delete" },
        ...report.tabs
          .filter((tab) => tab.position > report.tabs[tabIndex].position)
          .map((tab) => ({
            tab_id: tab.id,
            update_type: "update",
            params: { position: tab.position - 1 },
          })),
      ],
    });
    updateGrid({
      reportId: report.id,
      updates: report.tabs[tabIndex].grids.map((grid) => ({
        grid_id: grid.id,
        update_type: "delete",
      })),
    });
    const newRefs = refs;
    updateChart({
      reportId: report.id,
      updates: report.tabs[tabIndex].grids
        .reduce((prev, curr) => prev.concat(curr.charts), [])
        .map((chart) => {
          delete newRefs[chart.id];
          return { chart_id: chart.id, update_type: "delete" };
        }),
    });
    setRefs({ ...newRefs });
    const newIndex = tabIndex === 0 ? 1 : 0;
    navigateTo(report.id, report.tabs[newIndex].path);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const source = result.source.index;
    const destination = result.destination.index;
    let updates;
    if (source > destination) {
      updates = report.tabs
        .filter((tab) => tab.position >= destination && tab.position <= source)
        .map((tab) => ({
          tab_id: tab.id,
          update_type: "update",
          params: {
            position: tab.position === source ? destination : tab.position + 1,
          },
        }));
    } else {
      updates = report.tabs
        .filter((tab) => tab.position >= source && tab.position <= destination)
        .map((tab) => ({
          tab_id: tab.id,
          update_type: "update",
          params: {
            position: tab.position === source ? destination : tab.position - 1,
          },
        }));
    }
    updateTab({ reportId: report.id, updates });
  };

  const handleRemoveChart = (chartId) => {
    const newRefs = refs;
    delete newRefs[chartId];
    setRefs({ ...newRefs });
    updateChart({
      reportId: report.id,
      updates: [{ chart_id: chartId, update_type: "delete" }],
    });
  };
  const [updateCount, setUpdateCount] = React.useState(1);
  const [gridChartCount, setGridChartCount] = React.useState([]);

  useEffect(() => {
    setGridChartCount([]);
    setChartPagination({ start: 0, end: 8 });
    setExportTabData(false);
    let childRefs = {};
    let filter_chart_map = {};
    report.tabs?.[currentTabIndex]?.grids?.forEach((grid, gridIndex) => {
      let maxY = 0;
      grid.charts.forEach((chart, chartIndex) => {
        const { gridPosition: { y, h } = {} } = chart;
        maxY = maxY > y + h ? maxY : y + h;
        childRefs[chart.id] = createRef();
        if (typeof chart.channelType === "object" && chart.channelType?.id) {
          const blendId = chart.channelType.id;
          const blendSource = blends.find(
            (source) => source.id === chart.channelType.id
          );
          blendSource?.dsConfigs.forEach((source) => {
            const filterId = source.dsOptions?.filterGrouping?.filterId;
            filter_chart_map[filterId] = (
              filter_chart_map[filterId] ?? []
            ).concat(chart.id);
          });
        } else {
          if (ChartFilterSupport(chart.type)) {
            const filterId = chart.filter.id;
            filter_chart_map[filterId] = (
              filter_chart_map[filterId] ?? []
            ).concat(chart.id);
          } else {
            const combinedMetrics = chart.leftMetrics.concat(
              chart.rightMetrics ?? []
            );
            for (let metric of combinedMetrics) {
              const filterId = metric.filter.id;
              if (
                filterId &&
                !(filter_chart_map[filterId] ?? []).includes(chart.id)
              ) {
                filter_chart_map[filterId] = (
                  filter_chart_map[filterId] ?? []
                ).concat(chart.id);
              }
            }
          }
        }
      });
      setGridChartCount((prev) =>
        prev.concat(gridIndex ? prev[gridIndex - 1] + maxY : maxY)
      );
    });
    setRefs({ ...childRefs });
    updateFilterChartMap(filter_chart_map);
  }, [updateCount]);

  const { showTutorial, run } =
    useSelector((state) => state.user.tutorial) || {};
  const userEmail = useSelector((state) => state.user.user?.email);
  const userRole = useSelector((state) => state.user.user?.role);

  useEffect(() => {
    stateUpdateNumber.current++;
    if (stateUpdateNumber.current >= 2 && reportDownloaded) {
      // first to get initial redux state, then state update when API call is made, then state update when API call completed
      setIsReportUpdated(true);
      if (showTutorial && run === false) {
        setStartTour(true);
      }
    }
  }, [reportDownloaded]);

  const updateGridPos = (grid, event) => {
    const updates = [];
    event.forEach((e) => {
      const id = e.i.split(SEPARATOR)[0];
      const chart = grid?.charts.find((c) => c.id === id);
      if (
        chart &&
        (chart.gridPosition.x !== e.x ||
          chart.gridPosition.y !== e.y ||
          chart.gridPosition.w !== e.w ||
          chart.gridPosition.h !== e.h)
      ) {
        if (isReportUpdated) {
          updates.push({
            chart_id: chart.id,
            update_type: "update",
            params: { gridPosition: { ...e, minW: ["ADD", "KPI"].includes(chart.type) ? 3 : 6, minH: ["ADD", "KPI"].includes(chart.type) ? 1 : 2, maxH: 4 } },
          });
        } else {
          chart.gridPosition.x = e.x;
          chart.gridPosition.y = e.y;
          chart.gridPosition.w = e.w;
          chart.gridPosition.h = e.h;
          chart.gridPosition.minW = ["ADD", "KPI"].includes(chart.type) ? 3 : 6;
          chart.gridPosition.minH = ["ADD", "KPI"].includes(chart.type) ? 1 : 2;
          chart.gridPosition.maxH = 4;
        }
      }
    });
    if (updates.length > 0) {
      updateChart({
        reportId: report.id,
        updates: updates,
      });
    }
  };

  const addNewBlock = (grid) => {
    const newRefs = {};
    const height = grid.charts.reduce(
      (max, chart) =>
        Math.max(max, chart.gridPosition.y + chart.gridPosition.h),
      0
    );
    const newCharts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = {
        ...chart.gridPosition,
        x: index * 3,
        y: height,
        w: 3,
        h: 1,
      };
      chart.gridId = grid.id;
      return chart;
    });
    updateChart({
      reportId: report.id,
      updates: newCharts.map((chart) => {
        newRefs[chart.id] = createRef();
        return {
          chart_id: chart.id,
          update_type: "add",
          params: chart.toJSON(),
        };
      }),
    });
    setRefs({ ...refs, ...newRefs });
    setChartPagination((prev) => ({ start: 0, end: prev.end + 4 }));
  };

  const removeAddBlock = (grid) => {
    const removedChartIds = [];
    const chartsLength = grid.charts.length;
    for (
      let index = chartsLength - 1;
      index > (chartsLength > 4 ? chartsLength - 5 : 0);
      index--
    ) {
      const chart = grid.charts[index];
      if (chart.type === "ADD") {
        removedChartIds.push(grid.charts[index].id);
      }
    }
    const newRefs = refs;
    updateChart({
      reportId: report.id,
      updates: removedChartIds.map((id) => {
        delete newRefs[id];
        return { chart_id: id, update_type: "delete" };
      }),
    });
    setRefs({ ...newRefs });
  };

  const checkGridRemove = (grid) => {
    const chartsLength = grid.charts.length;
    for (
      let index = chartsLength - 1;
      index > (chartsLength > 4 ? chartsLength - 5 : 0);
      index--
    ) {
      if (grid.charts[index].type === "ADD") {
        return true;
      }
    }
    return false;
  };

  const handleHeaderAdd = (tabId) => {
    const grid = ReportGrid.new(tabId, "New Header", "Sub-Header");
    const charts = grid.charts;
    updateGrid({
      reportId: report.id,
      updates: [
        { grid_id: grid.id, update_type: "add", params: grid.toJSON() },
      ],
    });
    const newRefs = {};
    updateChart({
      reportId: report.id,
      updates: charts.map((chart) => {
        newRefs[chart.id] = createRef();
        return {
          chart_id: chart.id,
          update_type: "add",
          params: chart.toJSON(),
        };
      }),
    });
    setRefs({ ...refs, ...newRefs });
    setChartPagination((prev) => ({ start: 0, end: prev.end + 4 }));
    setGridChartCount((prev) => prev.concat(prev[prev.length - 1] + 4));
  };

  const handleHeaderDelete = (grid) => {
    if (report.tabs[currentTabIndex].grids.length < 2) {
      openErrorMsg();
      return;
    }
    updateGrid({
      reportId: report.id,
      updates: [{ grid_id: grid.id, update_type: "delete" }],
    });
    const newRefs = refs;
    updateChart({
      reportId: report.id,
      updates: grid.charts.map((chart) => {
        delete newRefs[chart.id];
        return { chart_id: chart.id, update_type: "delete" };
      }),
    });
    setRefs({ ...newRefs });
  };

  useEffect(() => {
    if (visitorData) {
      sendFingerprint(visitorData, visitorData.visitorId);
    }
  }, [visitorData]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }, [editDrawerState.isOpen]);

  function handleScroll() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const scrollBottom = scrollTop + windowHeight;

    if (scrollBottom >= documentHeight - 5) {
      setChartPagination((prev) => ({ start: 0, end: prev.end + 8 }));
    }
  }

  const [loaded, setLoaded] = React.useState({
    blends: false,
    customCols: false,
  });

  useEffect(() => {
    if (reportId && tabPath) {
      setLoaded({ blends: false, customCols: false });
      getAllReports();
      checkAuth();
      getUser();
      getReport({ id: reportId });
      blendsList({
        update: () => setLoaded((prev) => ({ ...prev, blends: true })),
      });
      getAllCustomColumns({
        update: () => setLoaded((prev) => ({ ...prev, customCols: true })),
      });
    }

    // Add Scroll event listener to load more charts
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [chartPagination, setChartPagination] = React.useState({
    start: 0,
    end: 8,
  });

  const navigateTo = (reportId, tabPath) => {
    setUpdateCount(updateCount + 1);
    if (tabPath) {
      history.push(`/dashboard/${reportId}/${tabPath}`);
    } else {
      setReport({});
      history.push(`/dashboard/${reportId}/`);
    }
  };

  const getActiveChartClass = (chartId) => {
    if (editDrawerState.isOpen && editDrawerState.chart?.id === chartId) {
      return "highlight-shadow";
    } else {
      return "";
    }
  };

  const applyDate = (datePickerRange) => {
    changeDate(datePickerRange);
  };

  useEffect(() => {
    report.tabs &&
      transformMixPanelData(EVENTS.view_reports, {
        ...report.tabs[currentTabIndex],
        ...report,
      });
  }, [currentTabIndex]);
  return (
    <>
      {isReportUpdated && showTutorial && (
        <IntroTour
          editDrawerState={editDrawerState}
          setEditDrawerState={setEditDrawerState}
          chart={report.tabs[0].grids[0].charts[0]}
          source={"dashboard"}
        />
      )}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar open={editDrawerState.isOpen} drawerWidth={drawerWidth} />
        {!Object.keys(report).length ||
          !tabPath ||
          !Object.values(loaded).every((val) => val) ? (
          <DashboardLoader />
        ) : (
          <Main open={editDrawerState.isOpen}>
            <DrawerHeader />
            <div className="dashboard">
              <Grid
                container
                rowSpacing={0}
                spacing={0}
                justify="space-around"
                alignItems="stretch"
                style={{
                  padding: "30px 24px",
                  margin: "0 auto",
                  // maxWidth: "1700px",
                  borderRadius: "12px",
                  boxShadow: "0px 12px 40px -16px rgba(0, 0, 0, 0.05)",
                  background: "#FFFFFF",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "flex-end",
                    padding: "0px 10px 0px 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                        width: "40px",
                        borderRadius: "8px",
                        background: "#F6F7F8",
                        marginRight: "12px",
                      }}
                    > */}
                    <img
                      style={{
                        height: "35px",
                        width: "35px",
                        marginRight: "12px",
                      }}
                      src={
                        report.icon ?? report.report_icon ?? CustomReportIcon
                      }
                      alt={report.report_name}
                    />
                    {/* </div> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {report.report_name}
                        <ArrowDropDownIcon
                          style={{
                            color: "#000000",
                            marginLeft: "10px",
                            height: "30px",
                            cursor: 'pointer'
                          }}
                          onClick={(e) => setAnchorEl2(e.currentTarget)}
                        />
                      </Typography>
                      <Menu
                        id="menu-appbar"
                        className="smooth-shadow"
                        anchorEl={anchorEl2}
                        keepMounted
                        getContentAnchorEl={null}
                        variant='menu'
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        open={Boolean(anchorEl2)}
                        onClose={handleClose}
                        sx={{
                          "& .MuiMenu-paper": {
                            borderRadius: "8px",
                            padding: "8px",
                            minWidth: "168px",
                            gap: "12px",
                          },
                          "& .MuiMenuItem-root": {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20px",
                            backgroundColor: "transparent"
                          },
                          "&.Mui-selected": {
                            backgroundColor: "transparent"
                          }
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setUpdateNameModal({
                              show: "Report",
                              update: true,
                            });
                            handleClose();
                          }}
                        >
                          <EditIcon
                            style={{
                              marginRight: "12px",
                              width: "20px",
                              height: "20px",
                            }}
                          />{" "}
                          Edit Report
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            deleteReport({
                              id: report.id,
                              delete: report.id === defaultReportId,
                            });
                          }}
                        >
                          <DeleteIcon
                            style={{
                              marginRight: "12px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          Delete Report{" "}
                        </MenuItem>
                        {(userRole === "admin" || userRole === "editor") && (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setDefaultReport({
                                defaultReportId: report.id,
                                loading: (msg) => {
                                  setNotifyMessage(msg);
                                  notifyBarOpen();
                                },
                              });
                            }}
                          >
                            <DefaultIcon
                              style={{
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            Set as Default{" "}
                          </MenuItem>
                        )}
                      </Menu>

                      {editDrawerState.isOpen}
                      <Tooltip
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-arrow": {
                              color: "white",
                            },
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "white",
                              color: "black",
                              marginBottom: "20px",
                              filter:
                                "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                              padding: "20px 25px",
                            },
                          },
                        }}
                        title={
                          <Box
                            sx={{
                              borderRadius: "12px",
                              width: "277px",
                              // padding: "16px",
                            }}
                          >
                            <div style={{ height: "auto", maxHeight: "56vh", overflow: "auto" }}>
                              {Object.keys(selectedAccounts).map(
                                (channelType, index) => {
                                  const channel = GetChannel(channelType);
                                  return (
                                    <div
                                      style={{
                                        borderRadius: "4px",
                                      }}
                                      key={channel.type}
                                      value={channel.name}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "start",
                                          // marginBottom: "4px",
                                          marginTop: index === 0 ? "0px" : "10px",
                                        }}
                                      >
                                        <img
                                          src={channel.icon}
                                          alt={channel.title}
                                          height={21}
                                          width={21}
                                          style={{ marginTop: '5px' }}
                                        />
                                        {""}
                                        <div>
                                          <Typography
                                            style={{
                                              fontFamily: "Inter",
                                              fontSize: "14px",
                                              fontWeight: "600",
                                              fontStyle: "normal",
                                              lineHeight: "20px",
                                              letterSpacing: "0.21px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {channel.title}
                                          </Typography>
                                          <Typography
                                            style={{

                                              fontSize: "12px",
                                              fontFamily: "Inter",
                                              fontWeight: "700",
                                              lineHeight: "20px",
                                              color: "#5C6674",
                                              fontStyle: "normal",
                                              margin: "0px",
                                              padding: "0px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {selectedAccounts[channelType].title}
                                          </Typography>
                                          <Typography
                                            style={{

                                              fontSize: "10px",
                                              fontFamily: "Inter",
                                              fontWeight: "400",
                                              lineHeight: "20px",
                                              color: "#585969",
                                              fontStyle: "normal",
                                              margin: "0px",
                                              padding: "0px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {selectedAccounts[channelType].subtitle}
                                          </Typography>
                                        </div>
                                      </div>


                                      {Object.keys(selectedAccounts).length !== (index + 1) && <p
                                        style={{
                                          borderBottom: "1px solid #ECEEF1",
                                          width: "90%",
                                          marginTop: "10px",
                                        }}
                                      ></p>}
                                    </div>
                                  );
                                }
                              )}
                            </div>


                          </Box>
                        }
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <Typography
                            style={{
                              maxWidth: "400px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontFamily: "Inter",
                              fontSize: "12px",
                            }}
                            className="lato bold"
                          >
                            {Object.values(selectedAccounts)
                              .map((a, i) => `${i + 1} - ${a.title}`)
                              .join(", ")}
                          </Typography>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "end" }}>
                    {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "16px",
                        border: "1px solid #EAEAEC",
                        borderRadius: "6px",
                        minWidth: "200px",
                        minHeight: "40px",
                        maxHeight: "40px",
                        padding: "5px 16px",
                      }}
                    >
                      <IconButton
                        style={{
                          width: "26px",
                          height: "26px",
                          marginRight: "10px",
                          padding: "0px",
                        }}
                        onClick={() => setIsDatePickerOpen(true)}
                      >
                        <CalenderIcon />
                      </IconButton>
                      <Typography
                        component="div"
                        sx={{ flexGrow: 1 }}
                        style={{ lineHeight: "16px", whiteSpace: "nowrap" }}
                      >
                        <span
                          style={{
                            color: "#AEB6C4",
                            fontFamily: "Inter",
                            fontSize: "10px",
                            fontWeight: "400",
                            lineHeight: "8px",
                          }}
                        >
                          {dateRange.label ?? "Custom date range"}
                        </span>
                        <br />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "8px",
                            color: "#585969",
                          }}
                        >
                          {DateDisplayFormatter(dateRange.startDate)} -{" "}
                          {DateDisplayFormatter(dateRange.endDate)}
                        </span>
                      </Typography>
                      <IconButton
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "10px",
                          padding: "0px",
                        }}
                        onClick={() => setIsDatePickerOpen(true)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "20px",
                          color: "#999999",
                        }}
                      >
                        Change Report Type
                      </span>
                      <FormControl
                        sx={{
                          maxWidth: "241px",
                        }}
                      >
                        <Select
                          data-tut="reports_list"
                          id="demo-simple-select"
                          value={report.id}
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #EAEAEC",
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #EAEAEC",
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #EAEAEC",
                            },
                          }}
                          size="small"
                          onChange={(event) => {
                            const reportDetails =
                              event.target.value === "new"
                                ? null
                                : allReports.find(
                                  (report) => report.id === event.target.value
                                );
                            transformMixPanelData(
                              EVENTS.report_type,
                              reportDetails
                            );
                            if (event.target.value === "new") {
                              setUpdateNameModal({
                                show: "Report",
                                update: false,
                              });
                            } else {
                              navigateTo(event.target.value);
                            }
                          }}
                          inputProps={{
                            sx: {
                              display: "flex",
                              alignItems: "center",
                              width: "241px",
                            },
                          }}
                          MenuProps={{
                            sx: {
                              marginTop: "8px",
                              borderRadius: "8px",
                              maxHeight: "500px",
                              maxWidth: "241px",
                            },
                            MenuListProps: {
                              sx: {
                                padding: "10px !important",
                                borderRadius: "8px",
                              },
                            },
                          }}
                        >
                          <div
                            style={{
                              color: "rgba(0, 0, 0, 0.5)",
                              fontSize: "13px",
                            }}
                          >
                            Default Report
                          </div>
                          {allReports
                            .filter((report, index) =>
                              defaultReportId
                                ? report.id === defaultReportId
                                : index === 0
                            )
                            .map((dReport) => (
                              <MenuItem
                                sx={{ borderRadius: "4px", marginTop: "4px" }}
                                key={dReport.id}
                                value={dReport.id}
                              >
                                <img
                                  height={16}
                                  width={16}
                                  src={dReport.report_icon || CustomReportIcon}
                                  alt="Custom Reports"
                                />
                                <div
                                  style={{
                                    paddingLeft: "15px",
                                    // maxWidth: "200px",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "20px",
                                    color: "#5C6674",
                                  }}
                                >
                                  {dReport.report_name}
                                </div>
                              </MenuItem>
                            ))}
                          <hr />
                          <div
                            style={{
                              color: "rgba(0, 0, 0, 0.5)",
                              fontSize: "13px",
                            }}
                          >
                            Available Reports
                          </div>
                          {allReports.map((report) => (
                            <MenuItem
                              sx={{ borderRadius: "4px", marginTop: "4px" }}
                              key={report.id}
                              value={report.id}
                            >
                              <img
                                height={16}
                                width={16}
                                src={report.report_icon || CustomReportIcon}
                                alt="Custom Reports"
                              />
                              <Tooltip title={report.report_name} placement="bottom" arrow>
                                <div
                                  style={{
                                    paddingLeft: "15px",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "20px",
                                    color: "#5C6674",
                                    display: "inline-block",
                                    width: "150px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  {report.report_name}
                                </div>
                              </Tooltip>

                            </MenuItem>
                          ))}
                          <hr />
                          <MenuItem
                            sx={{ borderRadius: "4px", marginTop: "4px" }}
                            value="new"
                          >
                            <Button
                              style={{
                                width: "100%",
                                background: "#DDDDDDAA",
                                borderRadius: "8px",
                              }}
                            >
                              + New Report
                            </Button>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    {/* <IconButton
                      style={{
                        padding: "0px",
                        height: 32,
                        width: 32,
                        marginLeft: 12,
                      }}
                      disableRipple
                    >
                      <RefreshIcon />
                    </IconButton> */}

                    <div
                      style={{
                        height: "40px",
                        minWidth: "40px",
                        display: "flex",
                        border: "1px solid #ECEEF1",
                        borderRadius: "4px",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "0px 10px",
                        marginLeft: "10px",
                      }}
                      onClick={() => setFilterListOpen(true)}
                    >
                      <FilterIcon
                        data-tut="filter_box"
                        style={{ height: "18px", width: "18px" }}
                        onClick={() => setFilterListOpen(true)}
                      />
                    </div>
                  </div>
                </div>
                <Grid rowSpacing={0} item xs={12} align="center">
                  <Box sx={{ width: "100%" }} className="dashboard-tabs">
                    <TabContext value={currentTabIndex}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 10px",
                          paddingBottom: "0",
                          // borderBottom: "1px solid #efefef",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            maxWidth: "calc(100% - 300px)",
                          }}
                        >
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="1" direction="horizontal">
                              {(droppableProvided) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "calc(100% - 35px)",
                                  }}
                                  ref={droppableProvided.innerRef}
                                  {...droppableProvided.droppableProps}
                                >
                                  <Tabs
                                    value={currentTabIndex}
                                    onChange={(event, newValue) =>
                                      navigateTo(
                                        report.id,
                                        report.tabs[newValue].path
                                      )
                                    }
                                    variant="scrollable"
                                    scrollButtons={false}
                                    aria-label="scrollable auto tabs example"
                                    style={{ maxWidth: "50vw" }}
                                    selectionFollowsFocus
                                  >
                                    {report.tabs.map((tab, tabIndex) => {
                                      const child = (
                                        <Tooltip title={tab.name} placement="bottom" arrow>
                                          <Tab
                                            className="dashboard-tab"
                                            label={
                                              <div style={{
                                                whiteSpace: "nowrap", overflow: "hidden",
                                                textOverflow: "ellipsis", width: "120px"
                                              }}>
                                                {tab.name}
                                              </div>}
                                            sx={{
                                              whiteSpace: "nowrap",
                                              textTransform: "none",
                                              padding: "0px",
                                              margin: "0px",
                                              display: "flex",
                                            }}
                                            value={tabIndex}
                                            icon={
                                              currentTabIndex === tabIndex ? (
                                                <DropIcon
                                                  style={{
                                                    color: "#000000",
                                                    marginLeft: "20px",
                                                  }}
                                                  onClick={(e) =>
                                                    setAnchorEl(e.currentTarget)
                                                  }
                                                />
                                              ) : null
                                            }
                                            iconPosition="end"
                                          />
                                        </Tooltip>
                                      );
                                      return (
                                        <DraggableTab
                                          label={
                                            <div style={{
                                              whiteSpace: "nowrap", overflow: "hidden",
                                              textOverflow: "ellipsis", width: "120px"
                                            }}>
                                              {tab.name}
                                            </div>
                                          }
                                          value={tab.value}
                                          index={tabIndex}
                                          key={tabIndex}
                                          child={child}
                                        />
                                      );
                                    })}
                                    {droppableProvided
                                      ? droppableProvided.placeholder
                                      : null}
                                  </Tabs>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <Tooltip title={"Add New Tab"} placement="bottom">
                            <IconButton
                              data-tut="add_tab"
                              style={{
                                padding: "0px",
                                height: "32px",
                                width: "32px",
                                marginLeft: "20px",
                              }}
                              onClick={() =>
                                setUpdateNameModal({
                                  show: "Tab",
                                  update: false,
                                })
                              }
                            >
                              <AddTab />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="menu-appbar"
                            className="smooth-shadow"
                            anchorEl={anchorEl}
                            keepMounted
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            sx={{
                              marginTop: 3.5,
                              "& 		.MuiMenu-paper": {
                                borderRadius: "8px",
                                padding: "8px",
                                minWidth: "168px",
                                gap: "12px",
                              },
                              "& .MuiMenuItem-root": {
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "20px",
                                backgroundColor: "transparent"
                              },
                            }}
                          >
                            {!exportTabData ? (
                              <>
                                {/* <MenuItem onClick={() => { setUpdateNameModal({ show: "Tab", update: true }); handleClose(); }}>Rename Tab</MenuItem> */}
                                <MenuItem
                                  onClick={() => {
                                    setUpdateNameModal({
                                      show: "Tab",
                                      update: true,
                                    });
                                    handleClose();
                                  }}
                                >
                                  {" "}
                                  <EditIcon
                                    style={{
                                      marginRight: "12px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />{" "}
                                  Edit{" "}
                                </MenuItem>

                                <MenuItem
                                  onClick={() => setExportTabData(true)}
                                >
                                  {" "}
                                  <ExportIcon
                                    style={{
                                      marginRight: "12px",
                                      width: "20px",
                                      height: "12px",
                                    }}
                                  />{" "}
                                  Export{" "}
                                </MenuItem>
                                {/* <MenuItem onClick={() => { setUpdateNameModal({ show: "Tab", update: false }); handleClose(); }}>Add New Tab </MenuItem> */}
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    handleTabDelete(currentTabIndex);
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      marginRight: "12px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />{" "}
                                  Delete{" "}
                                </MenuItem>
                              </>
                            ) : (
                              <>
                                <MenuItem
                                  style={{
                                    paddingLeft: "10px",
                                    color: "#00000099",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => setExportTabData(false)}
                                >
                                  <ArrowBackIcon style={{ width: "16px" }} />
                                  <p
                                    style={{
                                      margin: "auto 5px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    Export
                                  </p>{" "}
                                </MenuItem>
                                <MenuItem
                                  style={{ paddingLeft: "30px" }}
                                  onClick={() => {
                                    handleClose();
                                    handleTabPdfExport(currentTabIndex);
                                  }}
                                >
                                  PDF Format
                                </MenuItem>
                                <MenuItem
                                  style={{ paddingLeft: "30px" }}
                                  onClick={() => {
                                    handleClose();
                                    handleTabPngExport(currentTabIndex);
                                  }}
                                >
                                  PNG Format
                                </MenuItem>
                                <MenuItem
                                  style={{ paddingLeft: "30px" }}
                                  onClick={() => {
                                    handleClose();
                                    handleTabJpgExport(currentTabIndex);
                                  }}
                                >
                                  JPG Format
                                </MenuItem>
                                <MenuItem
                                  style={{ paddingLeft: "30px" }}
                                  onClick={() => {
                                    handleClose();
                                    handleTabCsvExport(currentTabIndex);
                                  }}
                                >
                                  CSV Format
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {/* <Button
                            sx={{ marginRight: "12px" }}
                            variant="outlined"
                          >
                            Save
                          </Button> */}
                          <div
                            onClick={() => setIsDatePickerOpen(true)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #EAEAEC",
                              borderRadius: "6px",
                              minWidth: "200px",
                              minHeight: "40px",
                              maxHeight: "40px",
                              padding: "12px 16px",
                              cursor: "pointer",
                            }}
                          >
                            <IconButton
                              style={{
                                width: "16px",
                                height: "16px",
                                marginRight: "10px",
                                padding: "0px",
                              }}
                              onClick={() => setIsDatePickerOpen(true)}
                            >
                              <CalenderNewIcon />
                            </IconButton>
                            <Typography
                              component="div"
                              sx={{ flexGrow: 1 }}
                              style={{
                                lineHeight: "16px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* <span
                                style={{
                                  color: "#AEB6C4",
                                  fontFamily: "Inter",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  lineHeight: "8px",
                                }}
                              >
                                {dateRange.label ?? "Custom date range"}
                              </span>
                              <br /> */}
                              <span
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  fontStyle: "normal",
                                  letterSpacing: "0.12px",
                                  lineHeight: "8px",
                                  color: "#585969",
                                }}
                              >
                                {viewDate(dateRange.startDate)} -{" "}
                                {viewDate(dateRange.endDate)}
                              </span>
                            </Typography>
                            {/* <IconButton
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "10px",
                                padding: "0px",
                              }}
                              onClick={() => setIsDatePickerOpen(true)}
                            >
                              <ArrowDropDownIcon />
                            </IconButton> */}
                          </div>
                        </div>
                      </Box>
                      {report.tabs.map((tab, tabIndex) => (
                        <TabPanel
                          key={tab.id}
                          value={currentTabIndex}
                          index={tabIndex}
                        >
                          <Grid
                            container
                            spacing={2}
                            rowSpacing={0}
                            justify="space-evenly"
                            alignItems="center"
                          >
                            {tab.grids
                              .filter((grid, index) =>
                                index
                                  ? gridChartCount[index - 1] <=
                                  chartPagination.end
                                  : true
                              )
                              .map((grid, gridIndex) => (
                                <Grid key={grid.id} item xs={12}>
                                  {grid.title && (
                                    <div
                                      className="tab-section-header"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontFamily: "Lato",
                                            fontsize: "18px",
                                            fontWeight: "700",
                                            fontStyle: "normal",
                                            lineHeight: "26px",
                                          }}
                                        >
                                          {grid.title}
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                            color: "#5C6674",
                                          }}
                                        >
                                          {grid.subtitle}
                                        </Typography>
                                      </div>

                                      <div
                                        onClick={(e) =>
                                          setAnchorEl3({
                                            target: e.currentTarget,
                                            grid: grid,
                                          })
                                        }
                                        style={{
                                          display: "flex",
                                          height: "30px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid #eaeaec",
                                          width: " 30px",
                                          borderRadius: 4,
                                          cursor: "pointer",
                                          // position: "absolute",
                                          // right: "11px",
                                        }}
                                      >
                                        <IconButton
                                          disableRipple
                                          style={{
                                            height: "32px",
                                            width: "32px",

                                            // top: "20px",
                                            // height: "5%",
                                          }}
                                          onClick={(e) =>
                                            setAnchorEl3({
                                              target: e.currentTarget,
                                              grid: grid,
                                            })
                                          }
                                        >
                                          <img src={ThreeDots} alt="dots" />
                                        </IconButton>
                                      </div>

                                      <Menu
                                        id="menu-appbar"
                                        className="smooth-shadow"
                                        anchorEl={anchorEl3?.target}
                                        keepMounted
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        open={Boolean(anchorEl3?.target)}
                                        onClose={handleClose}
                                        sx={{
                                          marginTop: "10px",
                                          "& 		.MuiMenu-paper": {
                                            borderRadius: "8px",
                                            padding: "8px",
                                            minWidth: "168px",
                                            gap: "12px",
                                          },
                                          "& .MuiMenuItem-root": {
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                          },
                                        }}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setEditDrawerState({
                                              ...editDrawerState,
                                              isOpen: true,
                                              grid: anchorEl3.grid,
                                              chart: null,
                                            });
                                            handleClose();
                                          }}
                                        >
                                          <EditIcon
                                            style={{
                                              marginRight: "12px",
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                          {""}
                                          Edit Header
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            transformMixPanelData(
                                              EVENTS.add_new_header,
                                              {}
                                            );
                                            handleHeaderAdd(tab.id);
                                            handleClose();
                                          }}
                                        >
                                          <AddFile
                                            style={{
                                              marginRight: "12px",
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                          Add New Header
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            setHeaderModal(true);
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{
                                              marginRight: "12px",
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />{" "}
                                          Delete Header
                                        </MenuItem>
                                      </Menu>
                                    </div>
                                  )}

                                  <Grid item xs={12}>
                                    <ReactGridLayout
                                      isBounded={true} rowHeight={200} cols={12} padding={0}
                                      resizeHandles={["ne", "nw", "se", "sw", "e", "n", "s", "w"]}
                                      draggableHandle=".drag-handle"
                                      layout={grid.charts.map(chart => chart.gridPosition)}
                                      onLayoutChange={(event) => { updateGridPos(grid, event); }}
                                    >
                                      {grid.charts
                                        .filter((chart, index) => {
                                          let maxY = chart?.gridPosition?.y + chart?.gridPosition?.h;
                                          return !index
                                            ? true
                                            : gridIndex
                                              ? (gridChartCount[gridIndex - 1] + maxY) <= chartPagination.end
                                              : maxY <= chartPagination.end;
                                        })
                                        .map((chart) => (
                                          <div
                                            className={`flexible-box same-padding ${getActiveChartClass(chart.id)}`}
                                            key={chart.id}
                                            data-grid={chart.gridPosition}
                                          >
                                            <Errorhandler
                                              errorMessage="Error Loading Chart"
                                              removeChart={() => {
                                                handleRemoveChart(chart.id);
                                              }}
                                            >
                                              <ChartWrapper
                                                ref={refs[chart?.id]}
                                                chart={chart}
                                                report={report?.report_name}
                                                removeChart={() => {
                                                  handleRemoveChart(chart.id);
                                                }}
                                                handleToggleEditDrawer={(
                                                  chart,
                                                  handleChartChange
                                                ) =>
                                                  setEditDrawerState({
                                                    ...editDrawerState,
                                                    isOpen: true,
                                                    chart: chart,
                                                    grid: null,
                                                    handleChartChange: handleChartChange,
                                                  })
                                                }
                                                closeEditDrawer={(chart) => {
                                                  if (
                                                    chart?.id ===
                                                    editDrawerState?.chart?.id
                                                  )
                                                    setEditDrawerState({
                                                      ...editDrawerState,
                                                      isOpen: false,
                                                    });
                                                }}
                                              />
                                            </Errorhandler>
                                          </div>
                                        ))}
                                    </ReactGridLayout>
                                    {chartPagination.end <
                                      gridChartCount[gridIndex] ? (
                                      <></>
                                    ) : (
                                      <>
                                        {checkGridRemove(grid) && (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginLeft: "-20px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <RemoveChartLogo
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              onClick={() =>
                                                removeAddBlock(grid)
                                              }
                                            />
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "8px",
                                                background: "#D9D9D999",
                                              }}
                                            ></div>
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "-20px",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <AddChartLogo
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                            onClick={() => addNewBlock(grid)}
                                          />
                                          <div
                                            style={{
                                              width: "100%",
                                              height: "8px",
                                              background: "#D9D9D999",
                                            }}
                                          ></div>
                                        </div>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Main>
        )}
        <DateRangeModal
          dateRange={dateRange}
          setDateRange={applyDate}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
        />
        <EditNameModal
          key={updateNameModal.show}
          oldName={
            updateNameModal.update
              ? updateNameModal.show === "Report"
                ? report.report_name
                : report.tabs[currentTabIndex]?.name
              : null
          }
          oldIcon={
            updateNameModal.update ? report.report_icon : CustomReportIcon
          }
          modal={updateNameModal}
          setModal={setUpdateNameModal}
          update={updateNameModal.update ? updateName : handleAddNew}
          allNames={
            updateNameModal.show === "Report"
              ? allReports?.map((report) => report.report_name)
              : report.tabs?.map((tab) => tab.path)
          }
        />
        <ProductTourModal
          key={startTour}
          modal={startTour}
          setModal={setStartTour}
        />
        <Sidebar
          drawerWidth={drawerWidth}
          editDrawerState={editDrawerState}
          onClose={() =>
            setEditDrawerState({ ...editDrawerState, isOpen: false })
          }
          handleAllChartsForceReload={handleAllChartsForceReload}
        />
        <FilterListModal
          modal={filterListOpen}
          setModal={setFilterListOpen}
          handleAllChartsForceReload={handleAllChartsForceReload}
        />
        <Snackbar open={open} autoHideDuration={3000} onClose={closeErrorMsg}>
          <Alert
            onClose={closeErrorMsg}
            severity="error"
            sx={{ width: "100%" }}
          >
            Cannot delete header. Must have at least one header in a tab.
          </Alert>
        </Snackbar>
        <Snackbar
          open={notifyOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={5000}
          onClose={notifyBarClose}
        >
          <Alert
            onClose={notifyBarClose}
            severity={notifyMessage.indexOf("wrong") > -1 ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {notifyMessage}
          </Alert>
        </Snackbar>
        <DeleteModal
          modal={headerModal}
          setModal={setHeaderModal}
          message={`Do you really want to delete this ${anchorEl3?.grid?.title} Header?`}
          onDelete={() => {
            handleHeaderDelete(anchorEl3.grid);
            handleClose();
          }}
        />
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedAccounts: state.channel.selectedAccounts,
  allReports: state.report.allReports,
  reports: state.report.reports,
  defaultReportId: state.report.defaultReportId,
  reportDownloaded: state.report.reportDownloaded,
  dateRange: state.dashboard.dateRange,
  filterChartMap: state.dashboard.filterChartMap,
  blends: state.blends.blends,
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth()),
  getUser: () => dispatch(getUser()),
  createReport: (_) => dispatch(createReport(_)),
  updateReport: (_) => dispatch(updateReport(_)),
  deleteReport: (_) => dispatch(deleteReport(_)),
  getAllReports: () => dispatch(getAllReports()),
  getReport: (_) => dispatch(getReport(_)),
  updateChart: (_) => dispatch(updateChart(_)),
  updateGrid: (_) => dispatch(updateGrid(_)),
  updateTab: (_) => dispatch(updateTab(_)),
  changeDate: (_) => dispatch(changeDate(_)),
  updateFilterChartMap: (_) => dispatch(updateFilterChartMap(_)),
  blendsList: (_) => dispatch(blendsList(_)),
  getAllCustomColumns: (_) => dispatch(getAllCustomColumns(_)),
  setDefaultReport: (_) => dispatch(setDefaultReport(_)),
  getDefaultReport: (_) => dispatch(getDefaultReport(_)),
  updateLoader: (_) => dispatch(updateLoader(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
