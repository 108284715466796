import React from "react";
import { Box, Button, Modal, Typography } from '@mui/material';
import "../styles/modal.css";
import { ReactComponent as SuccessIcon } from "../../Assets/Images/success.svg";

const customStyles = {
    top: '30%',
    left: '35%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: "#ffff",
    borderRadius: "8px",
    width: "30%",
    minHeight: "auto",
    padding: "30px",
    zIndex: 9000000000000,
    position: "absolute",
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "column"
};

const UnsavedChangesModal = (props) => {
    const { modal, setModal, message, goBackUrl } = props;

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal("none")}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={customStyles}>
                    <Typography variant="body1" style={{ fontFamily: "Inter", fontSize: "28px", fontWeight: 600, marginBottom: "12px", textAlign: "left", float: "left" }}>
                        Unsaved Changes
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: 400, marginBottom: "24px", color: "#5C6674" }}>
                        {message}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Button style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "500", textTransform: "none", color: "#0869FB", marginRight: "10px" }} onClick={() => window.location.href = goBackUrl}>Go back</Button>
                        <Button variant="contained" style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "500", textTransform: "none", color: "#fff", background: "#0869FB" }} onClick={setModal}>Stay</Button>
                    </div>
                </Box>

            </Modal >
        </div>
    )
}
export default UnsavedChangesModal;
