import { InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SearchMenu } from '../../Assets/Icons/search-icon.svg';
import { useEffect, useState } from "react";

export default function SearchBar({ setFilterText, filteredData, setFilteredData, keys = ["name"] }) {

    const [searchText, setSearchText] = useState("");

    const filterData = (value = "") => {
        return value.toLowerCase().includes(searchText.toLowerCase())
    }

    useEffect(() => {
        if (setFilteredData) {
            setFilteredData(filteredData.filter(data => keys.some(key => filterData(data?.[key] ?? ""))))
        }
    }, [searchText])

    return (
        <TextField
            placeholder="Search"
            id="outlined-basic"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchMenu style={{ marginRight: '10px' }} />
                    </InputAdornment>
                ),
            }}
            variant="outlined"
            size="small"
            onChange={event => setFilterText ? setFilterText(event.target.value) : setSearchText(event.target.value)}
            sx={{
                width: "100%",
                '& legend': { display: 'none' },
                '& fieldset': { top: 0, borderRadius: "6px", border: "1px solid #EAEAEC", }
            }}
        />
    )
}