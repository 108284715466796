import { Typography } from '@mui/material';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';


export default function TitleSubtitle({ title, subtitle }) {
    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <Typography variant='subtitle2' style={{ lineBreak: "anywhere" }}>
                {title}
            </Typography>
            <Tooltip title={subtitle}>
                <Typography style={{ fontSize: 11 }}>
                    {subtitle.length > 38 ? subtitle.substring(0,38)+"..." : subtitle}
                </Typography>
            </Tooltip>
        </div>
    );
}
