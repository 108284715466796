import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getChartCurrency } from "../../api/channel/saga";
import MetricList from "./MetricList";

export default function AccountList({ channel, account = {}, setAccount }) {
    const state = useSelector(state => channel?.getState(state));
    const accountList = (state?.accountData?.accountList ?? []).map((acc, index) => ({ id: index, name: acc.title, ...acc }));
    const [acc, setAcc] = useState(account?.[channel.type]);

    useEffect(() => {
        if (acc) {
            getChartCurrency(acc, channel).then(data => {
                setAccount({ ...account, [channel.type]: { ...acc, currency: data.currency ?? null } })
            }).catch(err => {
                console.log("🚀 ~ file: AccountList.js ~ getChartCurrency ~ err:", err)
                setAccount({ ...account, [channel.type]: { ...acc, currency: null } })
            });
        }
    }, [acc])

    return (
        <MetricList
            channel={channel}
            metric={acc}
            metrics={accountList}
            isEditable={true}
            onClick={accObj => setAcc(accObj)}
            defaultTitle="Data Account"
        />
    )
}