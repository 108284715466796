import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import QuizIcon from "@mui/icons-material/Quiz";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutModal from "../Modal/LogoutModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ReactComponent as DataBlendIcon } from "../../Assets/Icons/DataBlend.svg";
import { ReactComponent as ExtractorIcon } from "../../Assets/Icons/extractor.svg";
import { ReactComponent as NewAdd } from "../../Assets/Icons/new_add_icon.svg";
import { ReactComponent as NewBlend } from "../../Assets/Icons/new-blend-icon.svg";
import { ReactComponent as NewExtractor } from "../../Assets/Icons/new-extractor-icon.svg";
import { ReactComponent as NewProfile } from "../../Assets/Icons/new-profile-icon.svg";
import { ReactComponent as NavBack } from "../../Assets/Icons/nav-back-icon.svg";
import { connect } from "react-redux";

import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

import Pixis from "../../Assets/Images/logos_pixis.svg";
import { GetChannel } from "../../models/channel";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useParams } from "react-router-dom";
import mixpanelObj, {
  EVENTS,
  transformMixPanelData,
} from "../../utils/mixpanel";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

function AppBarContainer({ selectedAccounts, drawerWidth, user }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  var count = 0;
  const { channel: channelString } = useParams();

  const DrawerHeader = styled("div")(() => ({
    display: "flex",
    width: "100%",
    height: "100px",
  }));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setModal(true);
    handleClose();
  };

  return (
    <>
      <AppBar
        style={{
          background: "#F8F9FD",
          height: 124,
          boxShadow: "none",
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0.5 }}
        drawerWidth="100%"
      >
        <AppBar
          style={{
            background: "#fff",
            padding: "0px 16px 0px 16px",
            width: "calc(100% - 144px)",
            height: "72px",
            margin: "24px 72px",
            boxShadow: "12px 12px 48px 0px rgba(80, 80, 159, 0.04)",
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            borderRadius: "12px",
            "& .MuiToolbar-root": { padding: "0px", height: "100%" },
          }}
        >
          <Toolbar>
            {history.location.pathname === "/profile" ||
              history.location.pathname === "/faq" ||
              history.location.pathname === "/privacy-policy" ||
              history.location.pathname === "/terms-and-conditions" ||
              history.location.pathname === "/extractor" ||
              history.location.pathname === "/customizing-sources" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  disableRipple
                  style={{
                    border: "none",
                    color: "black",
                    padding: "0px",
                    height: "32px",
                    width: "32px",
                  }}
                  onClick={() => history.push("/dashboard")}
                >
                  <NavBack style={{ height: 32, width: 32 }} />{" "}
                </Button>
                <Typography
                  style={{
                    color: "#0869FB",
                    fontWeight: "700",
                    margin: 0,
                    fontSize: "27px",
                    fontFamily: "Lato",
                    cursor: "pointer",
                  }}
                  variant="h4"
                  gutterBottom
                  onClick={() => history.push("/dashboard")}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "16px",
                    }}
                    src={Pixis}
                    alt="dots"
                  />
                  <span style={{ color: "black" }}>Pixis</span> Analytics
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                }}
                onClick={() => history.push("/dashboard")}
              >
                <img
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  src={Pixis}
                  alt="dots"
                />
                <Typography
                  style={{
                    color: "#0869FB",
                    paddingLeft: "16px",
                    fontWeight: "700",
                    margin: 0,
                    fontSize: "27px",
                    fontFamily: "Lato",
                  }}
                  variant="h4"
                  gutterBottom
                >
                  <span style={{ color: "black" }}>Pixis</span> Analytics
                </Typography>
              </div>
            )}

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "56px",
                marginRight: "16px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "#999999",
                  marginBottom: "4px",
                }}
              >
                Connected Data Sources
              </Typography>
              <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                {Object.keys(selectedAccounts).map((channelType, index) => {
                  if (index <= 2) {
                    const channel = GetChannel(channelType);
                    return (
                      <Tooltip
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-arrow": {
                              color: "white",
                            },
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "white",
                              color: "black",
                              marginBottom: "20px",
                              filter:
                                "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                              padding: "20px 25px",
                            },
                          },
                        }}
                        title={
                          <Box
                            sx={{
                              borderRadius: "12px",
                              width: "277px",
                              // padding: "16px",                            
                            }}

                          >
                            <div style={{
                              height: 'auto',
                              maxHeight: "56vh",
                              overflow: 'auto',
                            }}>
                              {Object.keys(selectedAccounts).map(
                                (channelType, index) => {
                                  const channel = GetChannel(channelType);
                                  return (
                                    <div
                                      style={{
                                        borderRadius: "4px",
                                      }}
                                      key={channel.type}
                                      value={channel.name}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "start",
                                          // marginBottom: "4px",
                                          marginTop: index === 0 ? "0px" : "10px",
                                        }}
                                      >
                                        <img
                                          src={channel.icon}
                                          alt={channel.title}
                                          height={21}
                                          width={21}
                                          style={{ marginTop: '5px' }}
                                        />
                                        {""}
                                        <div>
                                          <Typography
                                            style={{
                                              fontFamily: "Inter",
                                              fontSize: "14px",
                                              fontWeight: "600",
                                              fontStyle: "normal",
                                              lineHeight: "20px",
                                              letterSpacing: "0.21px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {channel.title}
                                          </Typography>
                                          <Typography
                                            style={{

                                              fontSize: "12px",
                                              fontFamily: "Inter",
                                              fontWeight: "700",
                                              lineHeight: "20px",
                                              color: "#5C6674",
                                              fontStyle: "normal",
                                              margin: "0px",
                                              padding: "0px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {selectedAccounts[channelType].title}
                                          </Typography>
                                          <Typography
                                            style={{

                                              fontSize: "10px",
                                              fontFamily: "Inter",
                                              fontWeight: "400",
                                              lineHeight: "20px",
                                              color: "#585969",
                                              fontStyle: "normal",
                                              margin: "0px",
                                              padding: "0px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {selectedAccounts[channelType].subtitle}
                                          </Typography>
                                        </div>
                                      </div>


                                      {Object.keys(selectedAccounts).length !== (index + 1) && <p
                                        style={{
                                          borderBottom: "1px solid #ECEEF1",
                                          width: "90%",
                                          marginTop: "10px",
                                        }}
                                      ></p>}
                                    </div>
                                  );
                                }
                              )}
                            </div>

                          </Box>
                        }
                        arrow
                        placement="bottom"
                      >
                        <img
                          key={index}
                          style={{ width: 20, height: 20 }}
                          src={channel.icon}
                          alt={channel.title}
                        />
                      </Tooltip>
                    );
                  } else {
                    count++;
                  }
                })}
                {count ? (
                  <Tooltip
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-arrow": {
                          color: "white",
                        },
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "white",
                          color: "black",
                          marginBottom: "20px",
                          filter:
                            "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                          padding: "20px 25px",
                        },
                      },
                    }}
                    title={
                      <Box
                        sx={{
                          borderRadius: "12px",
                          width: "277px",
                          // padding: "16px",
                        }}
                      >
                        <div style={{ height: "auto", maxHeight: "56vh", overflow: 'auto' }}>

                          {Object.keys(selectedAccounts).map(
                            (channelType, index) => {
                              const channel = GetChannel(channelType);
                              return (
                                <div
                                  style={{
                                    borderRadius: "4px",
                                  }}
                                  key={channel.type}
                                  value={channel.name}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "start",
                                      // marginBottom: "4px",
                                      marginTop: index === 0 ? "0px" : "10px",
                                    }}
                                  >
                                    <img
                                      src={channel.icon}
                                      alt={channel.title}
                                      height={21}
                                      width={21}
                                      style={{ marginTop: '5px' }}
                                    />
                                    {""}
                                    <div>
                                      <Typography
                                        style={{
                                          fontFamily: "Inter",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          fontStyle: "normal",
                                          lineHeight: "20px",
                                          letterSpacing: "0.21px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {channel.title}
                                      </Typography>
                                      <Typography
                                        style={{

                                          fontSize: "12px",
                                          fontFamily: "Inter",
                                          fontWeight: "700",
                                          lineHeight: "20px",
                                          color: "#5C6674",
                                          fontStyle: "normal",
                                          margin: "0px",
                                          padding: "0px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {selectedAccounts[channelType].title}
                                      </Typography>
                                      <Typography
                                        style={{

                                          fontSize: "10px",
                                          fontFamily: "Inter",
                                          fontWeight: "400",
                                          lineHeight: "20px",
                                          color: "#585969",
                                          fontStyle: "normal",
                                          margin: "0px",
                                          padding: "0px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {selectedAccounts[channelType].subtitle}
                                      </Typography>
                                    </div>
                                  </div>


                                  {Object.keys(selectedAccounts).length !== (index + 1) && <p
                                    style={{
                                      borderBottom: "1px solid #ECEEF1",
                                      width: "90%",
                                      marginTop: "10px",
                                    }}
                                  ></p>}
                                </div>
                              );
                            }
                          )}
                        </div>

                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        color: "#0869FB",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      +{count}
                    </Typography>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div>
              {/* <Button onClick={() => history.push("/managesources", { prevPath: history.location.pathname, channelType: channelString })} style={{ marginRight: 8, textTransform: "none" }} variant="outlined" startIcon={<AddIcon />}>
                            Manage Data Sources
                        </Button> */}
              {/* <button  style={{background:'none',border:'none',cursor:'pointer',marginRight:'16px'}}><img style={{width:'40px',height:'40px'}} src={NewAdd} alt="" /></button> */}
              <Tooltip title={"Manage Data Sources"} placement="bottom" arrow>
                <IconButton
                  // data-tut="cutomize_sources"
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: "16px",
                    padding: "0px",
                  }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() =>
                    history.push("/managesources", {
                      prevPath: history.location.pathname,
                      channelType: channelString,
                    })
                  }
                >
                  <NewAdd />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={"Blends and Customizing Sources"}
                arrow
                placement="bottom"
              >
                <IconButton
                  data-tut="cutomize_sources"
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: "16px",
                    padding: "0px",
                  }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() =>
                    mixpanelObj.track(EVENTS.data_blends_view, {}, true)
                  }
                >
                  <Link
                    to="/customizing-sources"
                    style={{ width: "100%", margin: "auto" }}
                  >
                    <NewBlend />
                  </Link>
                </IconButton>
              </Tooltip>

              <Tooltip title={"Extractor"} placement="bottom" arrow>
                <IconButton
                  // data-tut="cutomize_sources"
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: "16px",
                    padding: "0px",
                  }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <Link
                    to="/extractor"
                    style={{ width: "100%", margin: "auto" }}
                  >
                    <NewExtractor />
                  </Link>
                </IconButton>
              </Tooltip>

              <Tooltip title={"Profile"} placement="bottom" arrow>
                <IconButton
                  data-tut="more_options"
                  style={{ width: 40, height: 40, padding: "0px" }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <NewProfile style={{ width: "40px", height: "40px" }} />
                </IconButton>
              </Tooltip>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock
                sx={{
                  marginTop: "10px",
                  "& .MuiMenu-paper": {
                    borderRadius: "8px",
                    padding: "12px",
                    gap: "12px",
                  },
                  "& 	.MuiMenu-list": {
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  },
                }}
                MenuListProps={{ sx: { padding: 0 } }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between",
                    width: "200px",
                  }}
                >
                  <MenuItem
                    sx={{
                      background: "rgba(46, 86, 246, 0.1)",
                      opacity: "1 !important",
                    }}
                    disabled
                    onClick={handleClose}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        color: "rgba(0, 0, 0, 0.5)",
                        height: "30px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <AccountCircle
                          style={{ color: "rgba(0, 0, 0, 0.5)" }}
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          paddingLeft: "10px",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "20px",
                        }}
                      >
                        {user.name}
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link
                      to="/profile"
                      style={{ width: "100%" }}
                      onClick={() =>
                        transformMixPanelData(EVENTS.click_my_profile, {})
                      }
                    >
                      <div
                        data-tut="profile_section"
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          width: "100%",
                          color: "rgba(0, 0, 0, 0.5)",
                          height: "30px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <PermIdentityIcon />
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            paddingLeft: "10px",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          Manage Profile
                        </div>
                      </div>
                    </Link>
                  </MenuItem>
                  {/* <MenuItem>
                                    <Link to="/faq" style={{ width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "start", width: "100%", color: "rgba(0, 0, 0, 0.5)", height: '30px', alignItems: 'center' }}>
                                            <div style={{ display: 'flex' }}>
                                                <QuizIcon />
                                            </div>
                                            <div style={{ textAlign: "left", paddingLeft: "10px" }}>
                                                FAQ
                                            </div>
                                        </div>
                                    </Link>
                                </MenuItem> */}
                  <MenuItem>
                    <a
                      href="https://pixis.ai/privacy-policy/"
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          width: "100%",
                          color: "rgba(0, 0, 0, 0.5)",
                          height: "30px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <PrivacyTipIcon />
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            paddingLeft: "10px",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          Privacy Policy
                        </div>
                      </div>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/terms-and-conditions" style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          width: "100%",
                          color: "rgba(0, 0, 0, 0.5)",
                          height: "30px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <InsertDriveFileIcon />
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            paddingLeft: "10px",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {`Terms & Conditions`}
                        </div>
                      </div>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        color: "rgba(0, 0, 0, 0.5)",
                        height: "30px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <LogoutIcon />
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          paddingLeft: "10px",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        Logout
                      </div>
                    </div>
                  </MenuItem>
                </div>
              </Menu>
            </div>
          </Toolbar>
          <LogoutModal modal={modal} setModal={setModal} />
        </AppBar>
      </AppBar>
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedAccounts: state.channel.selectedAccounts,
  user: state.user.user,
});

export default connect(mapStateToProps)(AppBarContainer);
