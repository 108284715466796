import { Box, Button, Modal, Tooltip, Typography, } from "@mui/material";
import "../../styles/filterpopup.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { Filter } from "../../models/chart";
import { useSelector } from "react-redux";
import { GetChannel, SupportedChannels } from "../../models/channel";
import CloseIcon from '@mui/icons-material/Close';
import DataList from "../CustomizingSources/BlendDataSource/DataList";
import InfoIcon from '@mui/icons-material/Info';
import FilterPopup from "./filterpopup/FilterPopup";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: "82vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column"
};

export default function SelectSourceModal({ modal, setModal, blendChannelCols }) {

    const dispatch = useDispatch();
    const blends = useSelector(state => state.blends.blends);
    const selectedAccounts = useSelector(state => state.channel.selectedAccounts);
    const authenticatedAccounts = useSelector(state => state.channel.authenticatedAccounts);
    const [channel, setChannel] = useState(null);

    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelAccountSelected = channel => channel.type in selectedAccounts
    const [table, setTable] = useState(null);

    const state = useSelector(state => channel?.getState(state));
    const tables = state?.tables;

    const dataLevels = (state?.dataLevels?.[table?.id] ?? []).map(level => ({ id: level, name: level }));
    const [dataLevel, setDataLevel] = useState(null);

    const [initialChannelUpdate, setInitialChannelUpdate] = useState(true);
    useEffect(() => {
        if (initialChannelUpdate) {
            setInitialChannelUpdate(false);
        } else {
            setTable(null);
            setDataLevel(null);
        }
    }, [channel])

    useEffect(() => {
        // if (table && state?.tablesUpdated && !state?.tablesUpdated?.[table.id]) {
        //     dispatch(channel.actions?.getColumns(table));
        // }
        setDataLevel(null)
    }, [table])

    const [isFilterOpen, setIsFilterOpen] = React.useState(false);

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(!modal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box style={{ padding: "30px", width: "100%", height: "40px", borderBottom: "1px solid #eee", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}> Create new Filter</Typography>
                        <CloseIcon onClick={() => setModal(!modal)} />
                    </Box>
                    <Box style={{ padding: "30px" }}>
                        <Box>

                            {/* source type */}
                            <div style={{ marginBottom: "25px" }}>
                                <div style={{ margin: "5px 0px", display: "flex" }}>
                                    <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                                        Choose your source
                                    </h4>
                                    <Tooltip
                                        title="You need to choose one Account ID for all connected data sources to create your own metrics. Please goto 'Manage Data Source' Page."
                                        placement="right"
                                    >
                                        <InfoIcon style={{ color: "#999999", margin: "0px 5px" }} />
                                    </Tooltip>
                                </div>
                                <DataList
                                    channel={channel}
                                    metric={channel && { id: channel?.type, name: channel?.title }}
                                    metrics={SupportedChannels
                                        .filter(channel => (isChannelAuthenticated(channel)))
                                        .concat(blends.map(GetChannel))
                                        .map(channel => ({
                                            id: channel.type, name: channel.title, icon: channel.icon, isBlend: channel.isBlend,
                                            //  disabled: !isChannelAccountSelected(channel)
                                        }))
                                    }
                                    isEditable={true}
                                    onClick={channelObject => setChannel(GetChannel(channelObject.isBlend ? channelObject : channelObject.id))}
                                    defaultTitle="Add Source"
                                />
                            </div>
                            {/* report type */}
                            {tables && <div style={{ marginBottom: "25px" }}>
                                <div style={{ margin: "5px 0px", display: "flex" }}>
                                    <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                                        Report type
                                    </h4>
                                </div>
                                <DataList
                                    channel={channel}
                                    metric={table}
                                    metrics={tables}
                                    isEditable={true}
                                    width={200}
                                    onClick={setTable}
                                    defaultTitle="Add table name"
                                />
                            </div>}

                            {(tables && dataLevels.length) ?
                                <div>
                                    <h4 className="inter bold">
                                        Data level
                                    </h4>
                                    <DataList
                                        channel={channel}
                                        metric={dataLevel}
                                        metrics={dataLevels}
                                        isEditable={true}
                                        onClick={setDataLevel}
                                        defaultTitle="Add Data Level"
                                    />
                                    <br />
                                </div>
                                : null
                            }

                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                            }}>
                            <Button disabled={!(channel && (!tables || table) && (!dataLevels.length || dataLevel))} onClick={() => {
                                setIsFilterOpen(true)
                            }} variant="contained">
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {isFilterOpen &&
                <FilterPopup
                    chart={{ channelType: channel.isBlend ? { id: channel.type, name: channel.title } : channel.type, table, dataLevel: dataLevel?.id, reportFilter: true }}
                    onClose={() => setIsFilterOpen(false)}
                    currentFilter={Filter.new({ channelType: channel.isBlend ? { id: channel.type, name: channel.title } : channel.type })}
                    onChange={() => { setModal(false) }}
                    blendChannelCols={blendChannelCols}
                />}
        </div>
    );
};
