import { all, put, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj,{EVENTS} from "../../utils/mixpanel";

function* connectAccount(args) {
    yield window.location.href=`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}/${args.payload.redirect_url}/&state=foobar&scope=r_emailaddress,r_liteprofile,w_member_social,w_organization_social,rw_ads,r_ads_reporting,r_ads_reporting,r_1st_connections_size,rw_organization_admin,r_organization_social`;
}

function* sendLinkedlnCode(args){
    try {
        yield api.post("/api/linkedin-ads/auth", {
            code: args.payload.code,
            redirect_url: args.payload.redirect_url,
        });
        mixpanelObj.track(EVENTS.connect_source,{
            data_source:channelType,
            channel_type:"ads",
            connected:"Yes"
        },true);
        window.location.href=window.location.origin + window.location.pathname;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        mixpanelObj.track(EVENTS.connect_source,{
            data_source:channelType,
            channel_type:"ads",
            connected:"No"
        },true);
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/api/linkedin-ads/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track(EVENTS.remove_source,{data_source:channelType,channel_type:"ads"},true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/linkedin-ads/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const response = yield api.post("/api/linkedin-ads/get-columns");
        yield put({
            type: `${channelType}.GET_FIELDS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/linkedin-ads/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.SEND_CODE`, sendLinkedlnCode),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
