export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GOOGLE_LOGIN = "GOOGLE_LOGIN";

export const LOGOUT = "LOGOUT";

export const IS_ONBOARDED = "IS_ONBOARDED";
export const RESET_ERROR = "RESET_ERROR";
export const RESET_FAILURE = "RESET_FAILURE"

export const UPDATE_USER_ORG_SUCCESS = "UPDATE_USER_ORG_SUCCESS"