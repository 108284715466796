import { TextField } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export default function TextFieldElement({
  channel,
  label = channel.title,
  metric,
  draggableProvided,
  ...params
}) {
  if (params.InputProps) {
    params.InputProps.disableUnderline = true;
  }
  return (
    <div className="data-list-form">
      {metric?.metric && draggableProvided && (
        <div {...draggableProvided.dragHandleProps}>
          <DragIndicatorIcon
            style={{
              marginTop: "9px",
              width: "18px",
              color: "#000000AA",
            }}
          />
        </div>
      )}
      <img
        height="20px"
        style={{ marginLeft: "10px" }}
        src={channel.icon}
        alt={channel.title}
      />
      <TextField
        id="Analytics"
        variant="filled"
        label={label}
        size="small"
        InputProps={{
          disableUnderline: true,
          readOnly: true,
        }}
        {...params}
      />
    </div>
  );
}
