import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import PixisIcon from "../../Assets/Icons/pixis.svg";
import Onboarding1 from "../../Assets/Images/onboarding1.svg";
import Onboarding2 from "../../Assets/Images/onboarding2.svg";
import Onboarding3 from "../../Assets/Images/onboarding3.svg";
import { useHistory } from "react-router-dom";
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import { connect } from "react-redux";
import { setIsOnboarded } from "../../api/auth/action";
import { Grid } from "@mui/material";

const AutoPlaySwipeableViews = SwipeableViews;

const images = [
    {
        boldText: "Connect and Manage with Different Data Sources",
        label: "Connect with various sources and see your data in much more organized way. It is essential to have quality B2B marketing data to align sales, marketing, and customer enhancement processes.",
        imgPath: Onboarding1,
    },
    {
        boldText: "Generate Reports",
        label: "Own the power of data at just click of one button. When the marketing team has access to the most accurate prospects, they can spend time and resources on the right customers.",
        imgPath: Onboarding2,
    },
    {
        boldText: "Customizable Reports",
        label: "Customize your reports in a way that suits you the best. By using a good report card system, you can design, create, distribute, and maintain report cards easily.",
        imgPath: Onboarding3,
    }
];

function SwipeableTextMobileStepper(props) {
    const { setIsOnboarded } = props;

    const theme = useTheme();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
    var buttonText = ""
    if (activeStep === 2) { buttonText = "Connect your data sources" } else { buttonText = "Next" }

    const handleNext = () => {
        if (activeStep === 2) {
            history.push("/organizations");
            setIsOnboarded();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1 === 3 ? 0 : prevActiveStep + 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div>
            <Grid container sx={{ height: '100vh',backgroundColor:"#ffff" }} xs={12} spacing={0}>
                <Grid sx={{ marginTop: '75px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={PixisIcon} alt="Pixis icon" />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ color: "#0869FB", marginTop: "10px", fontFamily: "lato", fontWeight: 700, fontSize: '45px' }} variant="h5" gutterBottom>
                            Analytics
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        sx={{ marginTop: '25px' }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }} >
                            <Grid xs={7} sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', justifyContent: 'center', marginTop: '5vh' }}>
                                <div >
                                    <Typography variant="h4" style={{ fontWeight: 500, textAlign: "left" }}>{images[activeStep].boldText}</Typography>
                                </div>
                                <Grid item xs={12} sx={{ paddingTop: '30px' }}>
                                    <Typography variant="body2" style={{ textAlign: "left", fontWeight: 100 }}>{images[activeStep].label}</Typography>
                                </Grid>
                                <div style={{ display: 'flex', width: '60%' }}>

                                    <MobileStepper
                                        steps={maxSteps}
                                        position="static"
                                        activeStep={activeStep}
                                        style={{ width: '100%', justifyContent: 'space-between' }}
                                        nextButton={null}
                                        backButton={null}
                                    />
                                </div>

                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images.map((step, index) => (
                                        <div key={index}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        marginLeft: '20px',
                                                        height: '40vh',
                                                        display: "block",
                                                        overflow: "hidden",
                                                    }}
                                                    src={step.imgPath}
                                                    alt={step.label}
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Button
                            font
                            variant='contained'
                            size="medium"
                            onClick={handleNext}
                            style={{ textTransform: 'none', fontSize: "16px", height: '40px', marginRight: '50px', marginBottom: '10px', minWidth: '120px', borderRadius: "8px", padding: "0 20px",backgroundColor:"#0769FB" }}
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    setIsOnboarded: () => dispatch(setIsOnboarded())
});

export default (connect(null, mapDispatchToProps)(SwipeableTextMobileStepper))
