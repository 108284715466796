import AppBar from "../Dashboard/AppBar";
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Collapse, Select } from 'antd';    //Need to review and update this as antd is removed
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import "../styles/faq.css";
import { Button } from "@mui/material";
const { Panel } = Collapse;
const { Option } = Select;

const Faq=()=>{
    const [expandIconPosition, setExpandIconPosition] = useState('end');
    const history = useHistory();
    const onPositionChange = (newExpandIconPosition) => {
        setExpandIconPosition(newExpandIconPosition);
    };
    const onChange = (key) => {
    };

    return (
         <>
            <AppBar/>
            <div style={{height:"100vh - 150px",top:"85px",position:"relative",margin:"60px auto"}}>
                    <Grid container>
                        <Grid xs={0.6}/>
                         <Grid xs={1.5} sx={{mt:2, display: 'flex'}}>
                              <h1 style={{fontSize:"36px"}}>FAQs</h1>
                         </Grid>
                         <Grid xs={9.5}>
                            <Collapse
                                defaultActiveKey={['1']}
                                onChange={onChange}
                                expandIconPosition={expandIconPosition}
                            >
                                <Panel header="Alright, but what exactly do you do?" key="1">
                                     <div style={{color:"rgba(60, 60, 67, 0.85)"}}>
                                        Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. 
                                        Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, 
                                        eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget.
                                        Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                     </div>
                                </Panel>
                                <Panel header="I don't need a brand strategist but I need help executing an upcoming campaign. Can we still work together?" key="2">
                                    <div style={{color:"rgba(60, 60, 67, 0.85)"}}>
                                        Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. 
                                        Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, 
                                        eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget.
                                        Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                     </div>
                                </Panel>
                                <Panel header="Are your rates competitive?" key="3">
                                    <div style={{color:"rgba(60, 60, 67, 0.85)"}}>
                                        Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. 
                                        Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, 
                                        eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget.
                                        Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                    </div>
                                </Panel>
                                <Panel header="Why do you have a monthly project cap?" key="4">
                                    <div style={{color:"rgba(60, 60, 67, 0.85)"}}>
                                        Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. 
                                        Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, 
                                        eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget.
                                        Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                    </div>
                                </Panel>
                            </Collapse>
                        </Grid>
                    </Grid>
            </div>
         </>
    )
}
export default Faq;