import { Button, IconButton, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import { AddCircleOutline, Clear } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useUpdateEffect from "../../CustomHooks/useUpdateEffect";
import FilterPopup from "../../Filteration/filterpopup/FilterPopup";
import DataList from "./DataList";
import { Filter, Metric } from "../../../models/chart";
import { useSelector } from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import Scrollbars from "react-custom-scrollbars";
import { filterAbsoluteMetrics } from "../Utils";
import FilterListModal from "../../Filteration/FilterListModal";
import { ReactComponent as FilterIcon } from "../../../Assets/Icons/openFilter.svg";
import { ReactComponent as NoResultsIcon } from "../../../Assets/errorMsgImages/NoResults.svg";
import { getFilterDetails } from "../../Filteration/Utils";
import "../../styles/main.css"

export default function EditTableData({ type, channel, accountCols, updateData, setUpdateData, searchText }) {
    const dispatch = useDispatch();
    const [chartKeys, setChartKeys] = React.useState(updateData.keys?.concat([null]));
    const [chartDimensions, setChartDimensions] = React.useState(updateData.dimensions?.concat([null]))
    const [chartMetrics, setChartMetrics] = React.useState(updateData.metrics?.concat([null]))
    const [chartFilter, setChartFilter] = React.useState(Filter.new({ channelType: channel.type }, updateData?.filter?.name, updateData?.filter?.segment, updateData?.filter?.dimensionFilters, updateData?.filter?.metricFilters));

    const [filterListOpen, setFilterListOpen] = React.useState(false);
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const [filterString, setFilterString] = React.useState(chartFilter?.toString());
    const [table, setTable] = React.useState(updateData.table);
    const [dataLevel, setDataLevel] = React.useState(updateData.dataLevel);
    const allCustomDims = useSelector(state => state.channel.allCustomDims);
    const state = useSelector(state => channel.getState(state));

    const tables = state.tables;
    const dataLevels = (state.dataLevels?.[table?.id] ?? []).map(level => ({ id: level, name: level }));
    const baseMetrics = Array.isArray(state.metrics) ? filterAbsoluteMetrics(state.metrics, channel.type) : filterAbsoluteMetrics(state.metrics[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")], channel.type) ?? [];
    const baseDimensions = Array.isArray(state.dimensions) ? state.dimensions : state.dimensions[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ?? [];
    const customMetrics = accountCols ? filterAbsoluteMetrics(accountCols.metrics) : Array.isArray(state.customMetrics) ? filterAbsoluteMetrics(state.customMetrics, channel.type) : filterAbsoluteMetrics(state.customMetrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")], channel.type) ?? [];
    const customDimensions = accountCols ? accountCols.dimensions : Array.isArray(state.customDimensions) ? state.customDimensions : state.customDimensions?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ?? [];
    const metrics = baseMetrics.concat(customMetrics);
    const dimensions = baseDimensions.concat(customDimensions)
        .concat(allCustomDims?.filter(dim => dim.data_source === channel.type && (!tables || dim.base_dimension.reportType?.id === table?.id))
            .map(data => ({
                id: data.id,
                name: data.name,
                dataType: "STRING",
                group: "Custom",
                miscInfo: {
                    base_dimension: data.base_dimension,
                    mapped_dimension: data.mapped_dimension
                }
            })));

    useUpdateEffect(() => {
        var metricIds = metrics.map((e) => e.id);
        var dimensionIds = dimensions.map((e) => e.id);

        setChartMetrics(chartMetrics.filter(metric => !metric || metricIds?.indexOf(metric?.metric?.id) > -1));
        setChartKeys(chartKeys.filter(dim => !dim || dimensionIds?.indexOf(dim?.id) > -1));
        setChartDimensions(chartDimensions.filter(dim => !dim || dimensionIds?.indexOf(dim?.id) > -1));
    }, [accountCols])


    const filterData = (value) => {
        return value.toLowerCase().includes(searchText.toLowerCase())
    }

    const updateFilter = (filter) => {
        if (!filter) {
            filter = Filter.new({ channelType: channel.type })
        }
        setFilterString(filter.toString());
        setChartFilter(filter);
    }

    useEffect(() => {
        if (updateData?.filter?.id) {
            getFilterDetails([updateData.filter.id], channel.type, (filters) => { updateFilter(filters[0]); })
        }
    }, [])

    useEffect(() => {
        setUpdateData({
            ...updateData,
            keys: chartKeys.filter(key => key !== null),
            dimensions: chartDimensions.filter(dimension => dimension !== null),
            metrics: chartMetrics.filter(metric => metric !== null),
            filter: chartFilter,
            dataLevel: dataLevel
        })
    }, [chartKeys, chartDimensions, chartMetrics, chartFilter, dataLevel])

    const [initialUpdate, setInitialUpdate] = React.useState(true)
    useEffect(() => {
        if (!initialUpdate) {
            setUpdateData({ ...updateData, table, keys: [], dimensions: [], metrics: [] })
        } else {
            setUpdateData({ ...updateData, table });
        }
        setInitialUpdate(false)
        // if (type === 1 && table && state?.tablesUpdated && !state?.tablesUpdated?.[table.id]) {
        //     dispatch(channel.actions?.getColumns(table));
        // }
    }, [table])

    return (
        <>
            {(type === 1)
                ? <Scrollbars autoHide style={{ height: "100%", width: "calc(100% + 16px)" }}>
                    <div style={{ width: "95%", marginBottom: "10px" }}>
                        {/* report type */}
                        {tables && <div style={{ marginBottom: "15px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Report type
                                </h4>
                                <Tooltip
                                    title="Choose the table from which you want the dimensions and metrics for blend."
                                    placement="right"
                                >
                                    <InfoIcon style={{ color: "#999999", margin: "0px 5px" }} />
                                </Tooltip>
                            </div>
                            <DataList
                                channel={channel}
                                metric={table}
                                metrics={tables}
                                isEditable={true}
                                onClick={setTable}
                                defaultTitle="Add Report Type"
                            />
                        </div>}

                        {/*data level tiktok*/}
                        {tables && channel.type === "tiktok-ads" && <div style={{ marginBottom: "15px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Data Level
                                </h4>
                                <Tooltip
                                    title="Choose the table from which you want the dimensions and metrics for blend."
                                    placement="right"
                                >
                                    <InfoIcon style={{ color: "#999999", margin: "0px 5px" }} />
                                </Tooltip>
                            </div>
                            <DataList
                                channel={channel}
                                metric={dataLevel}
                                metrics={dataLevels}
                                isEditable={true}
                                onClick={setDataLevel}
                                defaultTitle="Add Data Level"
                            />
                        </div>}

                        {/* join key */}
                        <div style={{ marginBottom: "15px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Join Keys
                                </h4>
                                <Tooltip
                                    title="Add dimension from the list on which you want to join the data sources."
                                    placement="right"
                                >
                                    <InfoIcon style={{ color: "#999999", margin: "0px 5px", height: "20px", width: "20px" }} />
                                </Tooltip>
                            </div>
                            {chartKeys.map((key, index) =>
                                <DataList
                                    key={chartKeys.length * (index + 1)}
                                    channel={channel}
                                    metric={key}
                                    isEditable={false}
                                    defaultTitle="Add Key"
                                    metrics={dimensions.filter(dimension => (chartKeys.findIndex(d => d?.name === dimension.name) === -1))}
                                    onClick={key => {
                                        const newKeys = Array.from(chartKeys);
                                        newKeys[index] = key;
                                        if (key && index === newKeys.length - 1) {
                                            newKeys.push(null);
                                        }
                                        setChartKeys(newKeys);
                                        setChartDimensions(chartDimensions.filter(d => d ? (d.id !== key.id) : true))
                                    }}
                                    onDelete={() => {
                                        const newKeys = Array.from(chartKeys);
                                        setChartKeys(newKeys.slice(0, index).concat(newKeys.slice(index + 1)));
                                    }}
                                />)}
                        </div>

                        {/* dimensions */}
                        <div style={{ marginBottom: "15px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Dimensions
                                </h4>
                                <Tooltip
                                    title="Add dimensions from the list on which you want to visualize data. Please avoid same column in the joining keys and dimensions."
                                    placement="right"
                                >
                                    <InfoIcon style={{ color: "#999999", margin: "0px 5px", height: "20px", width: "20px" }} />
                                </Tooltip>
                            </div>
                            {chartDimensions.map((dimension, index) =>
                                <DataList
                                    key={chartDimensions.length * (index + 1)}
                                    channel={channel}
                                    metric={dimension}
                                    isEditable={false}
                                    defaultTitle="Add Dimension"
                                    metrics={dimensions.filter(dimension => ((chartDimensions.findIndex(d => d?.name === dimension.name) === -1) && (chartKeys.findIndex(d => d?.name === dimension.name) === -1)))}
                                    onClick={dimension => {
                                        const newDimensions = Array.from(chartDimensions);
                                        newDimensions[index] = dimension;
                                        if (dimension && index === newDimensions.length - 1) {
                                            newDimensions.push(null);
                                        }
                                        setChartDimensions(newDimensions);
                                    }}
                                    onDelete={() => {
                                        const newDimensions = Array.from(chartDimensions);
                                        setChartDimensions(newDimensions.slice(0, index).concat(newDimensions.slice(index + 1)));
                                    }}
                                />)}
                        </div>

                        {/* metrics */}
                        <div style={{ marginBottom: "15px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Metrics
                                </h4>
                                <Tooltip
                                    title="The KPI's you want to visualize the data for."
                                    placement="right"
                                >
                                    <InfoIcon style={{ color: "#999999", margin: "0px 5px", height: "20px", width: "20px" }} />
                                </Tooltip>
                            </div>
                            {chartMetrics.map((metric, index) =>
                                <DataList
                                    key={chartMetrics.length * (index + 1)}
                                    channel={channel}
                                    metric={metric?.metric}
                                    isEditable={false}
                                    defaultTitle="Add Metric"
                                    metrics={metrics.filter(metric => (chartMetrics.findIndex(m => m?.metric?.name === metric.name) === -1))}
                                    onClick={metric => {
                                        const newMetrics = Array.from(chartMetrics);
                                        newMetrics[index] = Metric.new({ channelType: channel.type }, metric);
                                        if (metric && index === newMetrics.length - 1) {
                                            newMetrics.push(null);
                                        }
                                        setChartMetrics(newMetrics);
                                    }}
                                    onDelete={() => {
                                        const newMetrics = Array.from(chartMetrics);
                                        setChartMetrics(newMetrics.slice(0, index).concat(newMetrics.slice(index + 1)));
                                    }}
                                />)}
                        </div>

                        {/* filters */}
                        <div style={{ margin: "5px 0px", display: "flex", justifyContent: "space-between", width: "94%" }}>
                            <div style={{ display: "flex" }}>
                                <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Filters
                                </h4>
                                <Tooltip
                                    title="Filter the dimensions/metrics for the data source you want to join. Note: Each data source have different set of combination in applying filters."
                                    placement="right"
                                >
                                    <InfoIcon style={{ color: "#999999", margin: "0px 5px", height: "20px", width: "20px" }} />
                                </Tooltip>
                            </div>
                            {!(tables && !table) && <Typography className="h14"
                                style={{ color: "#0869FB", margin: "auto 0px" }}
                                onClick={() => { setFilterListOpen(true) }}
                            >
                                <FilterIcon /> See All
                            </Typography>}
                        </div>

                        {filterString ?
                            <div
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%"
                                }}>
                                <div className="filter-list pointer" style={{ minHeight: "42px", padding: "8px 12px" }} onClick={() => setIsFilterOpen(true)}>
                                    {filterString}
                                </div>
                                <IconButton
                                    onClick={() => {
                                        setFilterString(null);
                                        setChartFilter(Filter.new({ channelType: channel.type }))
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    aria-label="delete">
                                    <Clear />
                                </IconButton>
                            </div>
                            : <Button
                                sx={{
                                    justifyContent: "flex-start",
                                    color: "#333333",
                                    borderColor: "#DDDDDD",
                                    borderRadius: "8px",
                                    height: "42px",
                                    width: "95%",
                                    padding: "12px"
                                }}
                                fullWidth
                                variant="outlined"
                                size="small"
                                startIcon={<AddCircleOutline />}
                                disabled={(tables && !table)}   // || (channel.type === "tiktok-ads" && !dataLevel)
                                onClick={() => setIsFilterOpen(true)}>
                                Add a filter
                            </Button>
                        }


                        {isFilterOpen &&
                            <FilterPopup
                                accountCols={accountCols}
                                chart={{ channelType: channel.type, table, dataLevel: dataLevel?.id }}
                                onClose={() => setIsFilterOpen(false)}
                                currentFilter={chartFilter}
                                onChange={filter => {
                                    updateFilter(filter);
                                }}
                            />}

                        <FilterListModal
                            modal={filterListOpen}
                            setModal={setFilterListOpen}
                            chart={{ channelType: channel.type, table, dataLevel: dataLevel?.id }}
                            chartFilter={chartFilter}
                            onChange={filter => {
                                updateFilter(filter)
                                setFilterListOpen(false);
                            }}
                        />
                    </div>
                </Scrollbars>
                : (channel && (!tables || table)) ? //&& (channel.type !== "tiktok-ads" || dataLevel) ?
                    <>
                        <div className="inter h14_light" style={{ marginTop: "10px" }}>
                            Dimensions
                        </div>
                        <Scrollbars autoHide style={{ height: "calc(50% - 35px)", marginTop: "5px" }}>
                            <Stack spacing={0}>
                                {dimensions.filter(dimension => (chartDimensions.findIndex(d => d?.name === dimension.name) === -1 && chartKeys.findIndex(d => d?.name === dimension.name) === -1 && filterData(dimension.name)))
                                    .map((dimension, index) =>
                                        <ListItem
                                            key={index}
                                            className="h14_dark highlight1 pointer"
                                            onClick={() => {
                                                const newDimensions = Array.from(chartDimensions);
                                                newDimensions[newDimensions.length - 1] = dimension;
                                                newDimensions.push(null);
                                                setChartDimensions(newDimensions);
                                            }}
                                        >
                                            {dimension.name}
                                        </ListItem>
                                    )}
                            </Stack>
                        </Scrollbars>
                        <div className="inter h14_light" style={{ marginTop: "10px" }}>
                            Metrics
                        </div>
                        <Scrollbars autoHide style={{ height: "calc(50% - 35px)", marginTop: "5px" }}>
                            <Stack spacing={0}>
                                {metrics.filter(metric => (chartMetrics.findIndex(m => m?.metric?.name === metric.name) === -1 && filterData(metric.name)))
                                    .map((metric, index) =>
                                        <ListItem
                                            key={index}
                                            className="h14_dark highlight1 pointer"
                                            onClick={() => {
                                                const newMetrics = Array.from(chartMetrics);
                                                newMetrics[newMetrics.length - 1] = Metric.new({ channelType: channel.type }, metric);
                                                newMetrics.push(null);
                                                setChartMetrics(newMetrics);
                                            }}
                                        >
                                            {metric.name}
                                        </ListItem>
                                    )}
                            </Stack>
                        </Scrollbars>
                    </> :
                    <div className="displayColCenter highlight1" style={{ height: "95%" }}>
                        <NoResultsIcon width="200px" height="200px" />
                        <h3 style={{ fontFamily: "Inter", fontSize: "14px", color: "#666666", textAlign: "center" }}>
                            Select Report Type {channel.type === "tiktok-ads" ? "and  Data Level" : ""} to proceed
                        </h3>
                    </div>
            }
        </>
    );
}
