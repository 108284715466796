import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import { connect } from "react-redux";
import { connectAccountApi } from "../../api/appsflyer/action";

function AppsflyerConnect({ connectAccountApi }) {
  const [state, setState] = React.useState({
    appsflyer_access_token: "",
    token_name:""
  });

  const handleChange = (property, value) => {
    setState({ ...state, [property]: value });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", width: "85%", margin: "20px auto" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          marginTop: "0px",
        }}
      >
        <Grid
          xs={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            margin: "0px",
            marginTop: "0",
          }}
        >
          <p>Follow these <a href="https://support.appsflyer.com/hc/en-us/articles/360004562377-Managing-API-tokens" target="_blank">instructions</a> to connect your appsflyer account.</p>
          <ul style={{textAlign:"left",padding:"0px 0px 0px 15px"}}>
            <li>
              From the menu bar, access the user menu (email address drop-down
              at the top right corner).
            </li>
            <li>Select Security center.</li>
            <li>
              In the AppsFlyer API tokens section, click Manage your AppsFlyer
              API tokens.
            </li>
            
          </ul>
          <h4 style={{ fontSize: "14px",fontWeight:600 }}>New Appsflyer account</h4>
          <h5 style={{ fontSize: "12px", marginTop: "5px" }}>Access token name</h5>
          <TextField
            style={{ borderColor: "red", width: "100%" }}
            placeholder="Enter access token name"
            onChange={(e) =>
              handleChange("token_name", e.target.value)
            }
            error={ !state.token_name }
            type="text"
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            variant="outlined"
            size="small"
          />
          <h5 style={{ fontSize: "12px", marginTop: "5px" }}>Access token</h5>
          <TextField
            style={{ borderColor: "red", width: "100%" }}
            placeholder="*************************************"
            onChange={(e) =>
              handleChange("appsflyer_access_token", e.target.value)
            }
            error={ !state.appsflyer_access_token  }
            type="password"
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            variant="outlined"
            size="small"
          />
          <Button
            style={{
              backgroundColor: "#0869FB",
              color: "white",
              padding: "5px 15px",
              width: "100%",
              marginTop: "8px",
            }}
            onClick={() =>
              (state.token_name.length !== 0 && state.appsflyer_access_token.length !== 0) && connectAccountApi({
                redirect_url: "managesources",
                ...state,
              })
            }
          >
            Connect an account
          </Button>
        </Grid>
      </div>
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) => ({
  connectAccountApi: (_) => dispatch(connectAccountApi(_)),
});

export default connect(null, mapDispatchToProps)(AppsflyerConnect);
