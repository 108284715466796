import { GET_ALL_COLUMNS_SUCCESS } from "../channel/type";
import { channelType } from "./transformer";

const INITIAL_STATE = {
    accountData: {},
    dimensions: [],
    metrics: [],
    currency: null,
    metricsUpdated: false,
    campaigns: [],
    creatives: [],
    campaignGroups: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const username = action.payload.userName;
            const email = action.payload.userEmail;
            const accountList = action.payload.accounts.map(data => ({
                title: data.name,
                subtitle: data.id,
                accountId: data.urn,
            }));
            return { ...state, accountData: { users: [{ username: username, email: email }], accountList: accountList }, campaigns: action.payload.campaigns, creatives: action.payload.creatives, campaignGroups: action.payload.campaignGroups, accounts: action.payload.accounts };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE };
        case `${channelType}.GET_FIELDS_SUCCESS`:
            const dimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const metrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case GET_ALL_COLUMNS_SUCCESS:
            const cc = action.payload.filter(column => column.source === channelType).map(column => ({
                id: column.id,
                name: column.name,
                dataType: column.format ?? "FLOAT",
                group: "Customized",
            }));

            return { ...state, metrics: state.metrics.filter(m => m.group !== "Customized").concat(cc) };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        default:
            return state;
    }
};

export default reducer;
