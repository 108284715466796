import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj, { EVENTS } from "../../utils/mixpanel";

function* connectAccount(args) {
    yield put({
        type: `${channelType}.SHOW_CONNECT_ACCOUNT`,
    });
}

function* connectAccountAPI(args) {
    const { adjust_access_token, redirect_url, token_name } = args.payload;
    try {
        yield api.post("/api/adjust/auth", { access_token: adjust_access_token, token_name: token_name });
        yield put({
            type: CHECK_AUTH,
        });
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY`,
        });
        mixpanelObj.track(EVENTS.connect_source, {
            data_source: channelType,
            channel_type: "attributes",
            connected: "Yes"
        }, true);
    } catch (error) {
        mixpanelObj.track(EVENTS.connect_source, {
            data_source: channelType,
            channel_type: "attributes",
            connected: "No"
        }, true);
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        window.location.href = `${process.env.REACT_APP_BASE_URL}/${redirect_url}?channel=${channelType}&failure`;
    }
    yield put({
        type: `${channelType}.HIDE_CONNECT_ACCOUNT`,
    });
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/adjust/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* disconnectAccount(args) {
    try {
        yield api.post("/api/adjust/revoke", args.payload);
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY`,
        });
        yield put({
            type: `${channelType}.HIDE_CONNECT_ACCOUNT`,
        });
        mixpanelObj.track(EVENTS.remove_source, { data_source: channelType, channel_type: "attributes" }, true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getCustomFields(selectedAccount) {
    try {
        const response = yield api.post("/api/adjust/get-columns", {
            accountId: selectedAccount.accountId,
            columnTypes: ["EVENTS_WITHOUT_COHORT"]
        });
        yield put({
            type: `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const state = yield select((state) => state);
        const selectedAccount = state.channel.selectedAccounts[channelType];
        const { metrics, dimensions } = state[channelType];
        if (!metrics.length || !dimensions.length) {
            const response = yield api.post("/api/adjust/get-columns");
            yield put({
                type: `${channelType}.GET_FIELDS_SUCCESS`,
                payload: response.data
            });
        }
        yield all([getCustomFields(selectedAccount)]);
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/adjust/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.CONNECT_ACCOUNT_API`, connectAccountAPI),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
