import { CREATE_REPORT, UPDATE_REPORT, DELETE_REPORT, GET_ALL_REPORTS, GET_REPORT, UPDATE_CHART, UPDATE_GRID, UPDATE_TAB, DUPLICATE_REPORT, GET_DEFAULT_REPORT, SET_DEFAULT_REPORT } from "./type";

export const createReport = payload => {
    return {
        type: CREATE_REPORT,
        payload: payload
    };
};

export const updateReport = payload => {
    return {
        type: UPDATE_REPORT,
        payload: payload
    };
};

export const duplicateReport = payload => {
    return {
        type: DUPLICATE_REPORT,
        payload: payload
    };
};

export const deleteReport = payload => {
    return {
        type: DELETE_REPORT,
        payload: payload
    };
};

export const getAllReports = () => {
    return {
        type: GET_ALL_REPORTS
    };
};

export const getReport = payload => {
    return {
        type: GET_REPORT,
        payload: payload
    };
};

export const updateChart = payload => {
    return {
        type: UPDATE_CHART,
        payload: payload
    };
};

export const updateGrid = payload => {
    return {
        type: UPDATE_GRID,
        payload: payload
    };
};

export const updateTab = payload => {
    return {
        type: UPDATE_TAB,
        payload: payload
    };
};

export const getDefaultReport = payload => {
    return {
        type: GET_DEFAULT_REPORT,
        payload: payload
    };
};

export const setDefaultReport = payload => {
    return {
        type: SET_DEFAULT_REPORT,
        payload: payload
    };
};
