import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType } from "./transformer";
import mixpanelObj,{EVENTS} from "../../utils/mixpanel";

function* connectAccount(args) {
    try {
        yield window.fbLogin(async response => {
            if (response.authResponse) {
                try {
                    await api.post("/api/facebook/authenticate", {
                        auth_token: response.authResponse.accessToken
                    });
                    mixpanelObj.track(EVENTS.connect_source,{
                        data_source:channelType,
                        channel_type:"ads",
                        connected:"Yes"
                    },true);
                    window.location.href = args.payload.redirect_url + `?channel=${channelType}`;
                } catch (error) {
                    mixpanelObj.track(EVENTS.connect_source,{
                        data_source:channelType,
                        channel_type:"ads",
                        connected:"No"
                    },true);
                    window.location.href = args.payload.redirect_url + `?channel=${channelType}&failure`;
                }
            } else {
                mixpanelObj.track(EVENTS.connect_source,{
                    data_source:channelType,
                    channel_type:"ads",
                    connected:"No"
                },true);
                window.location.href = args.payload.redirect_url + `?channel=${channelType}&failure`;
            }
        });
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        window.location.href = args.payload.redirect_url + `?channel=${channelType}&failure`;
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/api/facebook/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track(EVENTS.remove_source,{data_source:channelType,channel_type:"ads"},true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/fb/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getCustomFields(selectedAccount) {
    try {
        const response = yield api.post("/api/fb/get-columns", { accountId: selectedAccount.accountId, columnTypes: ["CUSTOM"] });
        yield put({
            type: `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const state = yield select((state) => state);
        const selectedAccount = state.channel.selectedAccounts[channelType];
        const { metrics, dimensions } = state[channelType];
        if (!metrics.length || !dimensions.length) {
            const response = yield api.post("/api/fb/get-columns");
            yield put({
                type: `${channelType}.GET_FIELDS_SUCCESS`,
                payload: response.data
            });
        }
        yield all([getCustomFields(selectedAccount)]);
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/fb/get-currency", { account_id: args.payload.subtitle });
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
