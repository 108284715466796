import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj,{EVENTS} from "../../utils/mixpanel";

function* connectAccount() {
    yield put({
        type: `${channelType}.SHOW_CONNECT_ACCOUNT`,
    });
}

function* connectAccountAPI(args) {
    let shopify_store_name = args.payload.store_name;
    if ((/(https(s?)):\/\//i.test(shopify_store_name) && shopify_store_name.includes(".myshopify.com"))) {
        shopify_store_name = shopify_store_name.split(".")[0].split("://")[1];
        args.payload.store_name = shopify_store_name;
    }
    try {
        yield api.post("/api/shopify-ads/auth", args.payload);
        yield put({
            type: CHECK_AUTH,
        });
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY`,
        });
        mixpanelObj.track(EVENTS.connect_source,{
            data_source:channelType,
            channel_type:"attribution",
            connected:"Yes"
        },true);
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        mixpanelObj.track(EVENTS.connect_source,{
            data_source:channelType,
            channel_type:"attribution",
            connected:"No"
        },true);
        window.location.href = `${process.env.REACT_APP_BASE_URL}/${args.payload.redirect_url}?channel=${channelType}&failure`;
    }
    yield put({
        type: `${channelType}.HIDE_CONNECT_ACCOUNT`,
    });
}

function* disconnectAccount(args) {
    try {
        yield api.post("/api/shopify-ads/revoke", args.payload);
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY`,
        });
        mixpanelObj.track(EVENTS.remove_source,{data_source:channelType,channel_type:"attribution"},true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary() {
    try {
        const response = yield api.post("/api/shopify-ads/get-account-summary");
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}


function* getFields() {
    try {
        const response = yield api.post("/api/shopify-ads/get-columns");
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_COLUMNS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/api/shopify-ads/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.CONNECT_ACCOUNT_API`, connectAccountAPI),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
