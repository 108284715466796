import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import { connect } from "react-redux";
import { connectAccountApi } from "../../api/shopify-ads/action";

function ShopifyConnect({ connectAccountApi }) {
  const [state, setState] = React.useState({
    shopify_access_token: "",
    store_name: ""
  });

  const handleChange = (property, value) => {
    setState({ ...state, [property]: value });
  }

  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "100%", width: "85%", margin: "20px auto" }}
    >
      <div>
        <Grid
          xs={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            margin: "15px",
            marginTop: "0",
          }}
        >
          <p style={{ opacity: "0.75" }}>
            Follow these <a href={`${process.env.REACT_APP_BASE_URL}/connect-shopify-account`} target="_blank" rel="noreferrer">instructions</a> to connect your shopify account. Please
            make sure that you're adding "read_analytics", "read_gift_cards", "read_locales", "read_locations", "read_reports", "read_shipping", "read_orders", and "read_products" in the
            scope while creating app.
          </p>
          <h4 style={{ fontSize: "14px" }}>New Shopify account</h4>
          <h5 style={{ fontSize: "12px", marginTop: "10px" }}>Shop URL</h5>
          <TextField
            style={{ borderColor: "red", width: "90%" }}
            placeholder="shopname.myshopify.com"
            onChange={(e) => handleChange("store_name", e.target.value)}
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            variant="outlined"
            size="small"
          />
          <h5 style={{ fontSize: "12px", marginTop: "10px" }}>
            Access token
          </h5>
          <TextField
            style={{ borderColor: "red", width: "90%" }}
            placeholder="shpat_*************************"
            onChange={(e) => handleChange("shopify_access_token", e.target.value)}
            type="password"
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            variant="outlined"
            size="small"
          />
          <Button
            style={{
              backgroundColor: "#0869FB",
              color: "white",
              padding: "5px 15px",
              width: "90%",
              marginTop: "15px",
            }}
            disabled={state.store_name.length <= 0 && state.shopify_access_token.length <= 0}
            onClick={() => connectAccountApi({
              redirect_url: "managesources",
              ...state
            })}
          >
            Connect an account
          </Button>
        </Grid>
      </div>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => ({
  connectAccountApi: (_) => dispatch(connectAccountApi(_)),
});

export default connect(null, mapDispatchToProps)(ShopifyConnect);
