import Appbar from "../Dashboard/AppBar";
import NavigationMenu from "./NavigationMenu";

const CustomizingSources = (props) => {
    return (
        <>
            <Appbar />
            <NavigationMenu />
        </>
    )
}

export default CustomizingSources;
