import { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit-icon.svg";
import { Box, Grid, TextField } from "@mui/material";
import SelectDataSource from "./SelectDataSource";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as AddIcon } from "../../../Assets/Images/AddMore.svg";
import { ReactComponent as InnerJoinIcon } from "../../../Assets/Icons/join_inner.svg";
import { ReactComponent as LeftJoinIcon } from "../../../Assets/Icons/join_left.svg";
import { ReactComponent as RightJoinIcon } from "../../../Assets/Icons/join_right.svg";
import { ReactComponent as OuterJoinIcon } from "../../../Assets/Icons/join_outer.svg";
import { ReactComponent as CircleIcon } from "../../../Assets/Icons/circle.svg";
import { ReactComponent as PipeIcon } from "../../../Assets/Images/pipe.svg";
import { ReactComponent as Pipe2Icon } from "../../../Assets/Images/pipe2.svg";
import { ReactComponent as PreviewIcon } from "../../../Assets/Images/Preview.svg";
import MergedDataSource from "./MergedDataSource";
import { SupportedChannels } from "../../../models/channel";
import { v4 as uuid } from 'uuid';
import { transformCreateDataSourceObject } from "../Utils";
import { EVENTS, transformMixPanelData } from "../../../utils/mixpanel";
import "../../styles/main.css"

const createEmptyDataSource = () => ({ id: uuid(), account: null, keys: [], dimensions: [], metrics: [] });

const EditDataSource = ({ source, setCompletion, resetData }) => {

    const [newSourceData, setNewSourceData] = useState(
        source ? transformCreateDataSourceObject(source)
            : {
                name: "",
                dataSources: [createEmptyDataSource()]
            }
    );
    const accounts = newSourceData.dataSources.map(source => source.source?.type ?? null)
    const [selectedSources, setSelectedSources] = useState({ count: accounts.filter(account => account !== null).length, accounts });
    const [preview, setPreview] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [initialUpdate, setInitialUpdate] = useState(true);

    const addNewSource = (index) => {
        setPreview(false);
        const newDataSources = newSourceData.dataSources;
        newDataSources[index] = { ...newDataSources[index], join: "inner" }
        newDataSources.push(createEmptyDataSource());
        setNewSourceData({ ...newSourceData, dataSources: newDataSources })
    }

    const updateJoin = (index, join) => {
        const dataSources = newSourceData.dataSources;
        dataSources[index] = { ...dataSources[index], join }
        setNewSourceData({ ...newSourceData, dataSources })
    }

    useEffect(() => {
        if (newSourceData.dataSources.length !== selectedSources.count) {
            setPreview(false);
        }
    }, [selectedSources.count])

    useEffect(() => {
        if (!initialUpdate) {
            setNameError(newSourceData.name.length === 0);
        } else {
            setInitialUpdate(false);
        }
    }, [newSourceData.name])

    return (
        <>
            <Grid container item xs={9} flexDirection={"column"} style={{ paddingTop: 0, minHeight: "100%" }}>
                <div className="gridItem displayRowCenter" style={{ paddingTop: "5px", paddingBottom: "0px", height: "8%", maxHeight: "8%" }}>
                    {/* Source Name */}
                    <EditIcon width={20} height={20} />
                    <TextField
                        className="filter-name"
                        sx={{ marginLeft: "12px", height: "60px", maxHeight: "60px", justifyContent: "center", width: "100%" }}
                        variant="standard"
                        placeholder="New Source Name*"
                        defaultValue={newSourceData.name}
                        error={nameError}
                        label={nameError ? "Required" : ""}
                        required
                        onChange={e => {
                            setNewSourceData({ ...newSourceData, name: e.target.value });
                        }}
                        InputProps={{ disableUnderline: true, style: { fontSize: "10px" } }}
                        inputProps={{ style: { fontSize: "18px" } }}
                    />
                    <CloseIcon className="pointer" onClick={() => { resetData() }} />
                </div>

                <Grid container style={{ marginTop: "2%", height: "88%", maxHeight: "88%", display: "flex", flexWrap: "nowrap", width: "100%", overflowX: "scroll", overflowY: "hidden" }}>
                    {newSourceData.dataSources.map((source, sourceIndex) => {
                        return <>
                            <Grid className="gridItem" style={{ minWidth: "45%", padding: "16px" }} spacing={2} item xs={3.5} flexDirection={"column"} >
                                <SelectDataSource key={source.id} source={sourceIndex} selectedSources={selectedSources} setSelectedSources={setSelectedSources} newSourceData={newSourceData} setNewSourceData={setNewSourceData} />
                            </Grid>
                            {(source.source || newSourceData.dataSources.length > (sourceIndex + 1)) && (
                                (newSourceData.dataSources.length > 1 && selectedSources.count === newSourceData.dataSources.length && sourceIndex === newSourceData.dataSources.length - 1) ?
                                    <Grid className="displayRowCenter" item xs={1} style={{ minWidth: "fit-content" }} >
                                        <div className="displayColCenter" style={{ height: "100%" }}>
                                            <div className="displayRowLeft" style={{ height: "50%", width: "100%" }}>
                                                {/* <CircleIcon width="15%" /> */}
                                                <PipeIcon width="20%" />
                                                <div className="inter displayColCenter gridItem pointer" style={{ gap: "10px", color: "#0869FB", fontSize: 12, fontWeight: 500, padding: "10px", width: "60%", height: "fit-content" }}>
                                                    <PreviewIcon onClick={() => { setPreview(true); transformMixPanelData(EVENTS.view_preview, accounts) }} />
                                                    <span style={{ textAlign: "center" }}> Preview </span>
                                                </div>
                                                {preview && <Pipe2Icon width="20%" />}
                                            </div>

                                            {SupportedChannels.length !== selectedSources.accounts.length &&
                                                < div className="displayRowLeft" style={{ height: "50%", width: "100%" }}>
                                                    {/* <CircleIcon width="15%" /> */}
                                                    <PipeIcon width="20%" />
                                                    <div className="inter displayColCenter gridItem pointer" style={{ gap: "10px", color: "#0869FB", fontSize: 12, fontWeight: 500, padding: "10px", width: "60%", height: "fit-content" }}>
                                                        <AddIcon style={{ color: "#0869FB" }} onClick={() => addNewSource(sourceIndex)} />
                                                        <span style={{ textAlign: "center" }}> Add more data sources</span>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </Grid>
                                    : < Grid className="displayRowCenter" item xs={1} style={{ minWidth: "fit-content" }} >
                                        <div className="displayRowCenter" style={{ height: "100%", width: "100%" }}>
                                            {/* <CircleIcon width="15%" /> */}
                                            <PipeIcon width="35px" height="30px" />
                                            {newSourceData.dataSources.length === (sourceIndex + 1)
                                                ? (SupportedChannels.length !== selectedSources.accounts.length &&
                                                    <div className="inter displayColCenter gridItem pointer" style={{ gap: "10px", color: "#0869FB", fontSize: 12, fontWeight: 500, height: "30%" }}>
                                                        <AddIcon style={{ color: "#0869FB" }} onClick={() => addNewSource(sourceIndex)} />
                                                        <span style={{ textAlign: "center" }}> Add more source</span>
                                                    </div>
                                                )
                                                : <>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "fit-content", borderRadius: "40px", boxShadow: "0px 0px 17px 3px rgba(159, 159, 159, 0.5)" }}>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <div className="join-icon" style={{ borderBottom: "1px solid #E5E5E5", borderRight: "1px solid #E5E5E5", borderRadius: "40px 0px 0px 0px" }}>
                                                                <InnerJoinIcon fill={source.join === "inner" ? "url(#paint0_linear_7203_92906)" : "url(#paint1_linear_7203_92906)"} onClick={() => { updateJoin(sourceIndex, "inner") }} />
                                                            </div>
                                                            <div className="join-icon" style={{ paddingLeft: "5px", borderBottom: "1px solid #E5E5E5", borderRadius: "0px 40px 0px 0px" }}>
                                                                <LeftJoinIcon fill={source.join === "left" ? "url(#paint0_linear_7203_92906)" : "#6D6E7C4D"} onClick={() => { updateJoin(sourceIndex, "left") }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <div className="join-icon" style={{ paddingTop: "5px", borderRight: "1px solid #E5E5E5", borderRadius: "0px 0px 0px 40px" }}>
                                                                <RightJoinIcon fill={source.join === "right" ? "url(#paint0_linear_7203_92906)" : "#6D6E7C4D"} onClick={() => { updateJoin(sourceIndex, "right") }} />
                                                            </div>
                                                            <div className="join-icon" style={{ paddingLeft: "5px", paddingTop: "5px", borderRadius: "0px 0px 40px 0px" }}>
                                                                <OuterJoinIcon fill={source.join === "outer" ? "url(#paint0_linear_7203_92906)" : "#6D6E7C4D"} onClick={() => { updateJoin(sourceIndex, "outer") }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Pipe2Icon width="32px" height="15px" />
                                                </>
                                            }
                                        </div>
                                    </Grid>)}
                        </>
                    })}

                    {(selectedSources.count === newSourceData.dataSources.length && preview) &&
                        <Grid item xs={3} className="gridItem" style={{ height: "100%", minWidth: "40%" }}>
                            <MergedDataSource newSourceData={newSourceData} setCompletion={setCompletion} />
                        </Grid>

                    }
                </Grid>
            </Grid>
        </>
    )
}

export default EditDataSource;
