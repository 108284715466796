export const CREATE_REPORT = "CREATE_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAILURE = "UPDATE_REPORT_FAILURE";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE";

export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const GET_ALL_REPORTS_SUCCESS = "GET_ALL_REPORTS_SUCCESS";
export const GET_ALL_REPORTS_FAILURE = "GET_ALL_REPORTS_FAILURE";

export const GET_DEFAULT_REPORT = "GET_DEFAULT_REPORT"
export const GET_DEFAULT_REPORT_SUCCESS = "GET_DEFAULT_REPORT_SUCCESS"
export const GET_DEFAULT_REPORT_FAILURE = "GET_DEFAULT_REPORT_FAILURE"

export const SET_DEFAULT_REPORT = "SET_DEFAULT_REPORT"
export const SET_DEFAULT_REPORT_SUCCESS = "SET_DEFAULT_REPORT_SUCCESS"
export const SET_DEFAULT_REPORT_FAILURE = "SET_DEFAULT_REPORT_FAILURE"

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

export const UPDATE_CHART = "UPDATE_CHART";
export const UPDATE_CHART_LOCAL = "UPDATE_CHART_LOCAL";
export const UPDATE_CHART_SUCCESS = "UPDATE_CHART_SUCCESS";

export const UPDATE_GRID = "UPDATE_GRID";
export const UPDATE_GRID_LOCAL = "UPDATE_GRID_LOCAL";
export const UPDATE_GRID_SUCCESS = "UPDATE_GRID_SUCCESS";

export const UPDATE_TAB = "UPDATE_TAB";
export const UPDATE_TAB_LOCAL = "UPDATE_TAB_LOCAL";
export const UPDATE_TAB_SUCCESS = "UPDATE_TAB_SUCCESS";

export const DUPLICATE_REPORT = "DUPLICATE_REPORT";
export const DUPLICATE_REPORT_SUCCESS = "DUPLICATE_REPORT_SUCCESS"
export const DUPLICATE_REPORT_FAILURE = "DUPLICATE_REPORT_FAILURE"
export const DUPLICATE_TAB_SUCCESS = "DUPLICATE_TAB_SUCCESS"
export const DUPLICATE_TAB_FAILURE = "DUPLICATE_TAB_FAILURE"
