import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import Back from "../../../Assets/Icons/back_arrow.svg";
import Find from "../../../Assets/Icons/find_icon.svg";
import FindandReplaceModal from "../../Modal/FindandReplaceModal";
import UnsavedChangesModal from "../../Modal/UnsavedChangesModal";

const NavigationPane = ({ bulkTriggers, sheets, dates, handleBulkEditing, changedTriggers,infoComplete }) => {
  const [isopen, setIsOpen] = useState(false);
  const [modal,setModal] = useState(false);
  const changesTriggerLen = Object.keys(changedTriggers ?? {}).length > 0;
  const disableReplaceBtn = Object.values(infoComplete ?? []).includes(false);
  return (
    <Box sx={{ width: "100%", padding: "0px 0px 24px 0px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          background: "#FFFFFF",
          borderRadius: "12px",
          padding: "16px 12px",
          position: "relative",
          boxShadow: "12px 12px 48px 0px rgba(80, 80, 159, 0.04)",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "32px",
          }}
        >
          {" "}
          <button
            style={{
              background: "none",
              border: "none",
              marginRight: "12px",
              cursor: "pointer",
            }}
            onClick={() => !changesTriggerLen ? window.location.href="/extractor" : setModal(true)}
          >
            {" "}
            <img src={Back} alt="" />{" "}
          </button>{" "}
          Bulk Editing
        </Typography>
        <button
          style={{
            background: "none",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "8px",
            border: disableReplaceBtn ? "0.5px solid #EAEAEC" : "1.2px solid #EAEAEC",
            borderRadius: "6px",
            padding: "12px 16px",
            cursor: disableReplaceBtn ? "auto" : "pointer",
          }}
          disabled={disableReplaceBtn}
          onClick={() => setIsOpen(true)}
        >
          {" "}
          <img src={Find} alt="" /> Find & Replace
        </button>
      </Box>
      {isopen && <FindandReplaceModal isopen={isopen} setIsOpen={setIsOpen} bulkTriggers={bulkTriggers} sheets={sheets} dates={dates} handleBulkEditing={handleBulkEditing} />}
      {changesTriggerLen && modal && <UnsavedChangesModal 
        modal={modal}
        setModal={() => setModal(false)}
        message="Are you sure you want to go back? All unsaved changes will be lost."
        goBackUrl="/extractor"
      />}
    </Box>
  );
};

export default NavigationPane;
