import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { EVENTS, transformMixPanelData } from "../../utils/mixpanel";
import "react-calendar/dist/Calendar.css";
import "../styles/calander.css";
import ListItemButton from "@mui/material/ListItemButton";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";

const lastWeekStartDate = (count) => {
   const startDate = new Date(moment().subtract(count, 'week').startOf('week'));
   return startDate.setDate(startDate.getDate() + 1);
}
const lastWeekEndDate = () => {
  const endDate=new Date(moment().subtract(1, 'week').endOf('week'));
  return endDate.setDate(endDate.getDate() + 1);
}

const DateRangeModal = ({
  dateRange,
  setDateRange,
  isDatePickerOpen,
  setIsDatePickerOpen,
  compare,
}) => {
  const [datePickerRange, setDatePickerRange] = useState({
    label: dateRange.label ?? dateRange.dateType ?? "",
    startDate: dateRange.startDate ?? dateRange.fromDate,
    endDate: dateRange.endDate ?? dateRange.toDate,
  });
  const [isTillTodaySelected, setTodaySelected] = useState(false);

  const applyDate = (range) => {
    setDateRange({
      ...range,
      startDate: range.startDate ? formatDate(range.startDate) : range.startDate,
      endDate: range.endDate ? formatDate(range.endDate) : range.endDate
    });
    setIsDatePickerOpen(false);
  };

  const [count, setCount] = useState(1);
  const [currentDate, setCurrentDate] = useState(null);
  const [activeList,setActiveList] = useState(null);

  const today = new Date();
  var first = today.getDate() - today.getDay() + 1; // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6

  const definedDateRanges = [
    {
      startDate: today,
      endDate: today,
      label: "Today"
    },
    {
      startDate: new Date().setDate(today.getDate() - 1),
      endDate: new Date().setDate(today.getDate() - 1),
      label: "Yesterday"
    },
    {
      startDate: new Date().setDate(first),
      endDate: new Date().setDate(today.getDate() - 1) < new Date().setDate(first) ? new Date().setDate(first) : new Date().setDate(today.getDate() - 1),
      label: "This Week"
    },
    {
      startDate: lastWeekStartDate(1),
      endDate: lastWeekEndDate(),
      label: "Last Week"
    },
    {
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: new Date().setDate(today.getDate() - 1) < new Date(today.getFullYear(), today.getMonth(), 1) ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date().setDate(today.getDate() - 1),
      label: "This Month"
    },
    {
      startDate: new Date(today.getFullYear(), 0, 1),
      endDate: new Date().setDate(today.getDate() - 1) < new Date(today.getFullYear(), 0, 1) ? new Date(today.getFullYear(), 0, 1) : new Date().setDate(today.getDate() - 1),
      label: "This Year"
    },
  ];

  const handleCustomDate = (type, count, isTillTodaySelected) => {
    setCurrentDate(type);
    (type === "Week" && count === 1) ? setActiveList("Last Week") : setActiveList(null);
    switch (type) {
      case "Days":
        setDatePickerRange({
          label: `Last ${count} Days`,
          startDate: new Date(new Date().setDate(today.getDate() - count)),
          endDate: new Date(new Date().setDate(today.getDate() - 1)),
        });
        break;
      case "Months":
        setDatePickerRange({
          label: `Last ${count} Months`,
          startDate: new Date(today.getFullYear(), today.getMonth() - count, 1),
          endDate: isTillTodaySelected ? tillToday() : new Date(today.getFullYear(), today.getMonth(), 0),
        });
        break;
      case "Years":
        setDatePickerRange({
          label: `Last ${count} Years`,
          startDate: new Date(today.getFullYear() - count, 0, 1),
          endDate: isTillTodaySelected ? tillToday() : new Date(today.getFullYear() - 1, 11, 31),
        });
        break;
      case "Weeks":
        var startDate = lastWeekStartDate(count);
        var endDate = lastWeekEndDate();
        setDatePickerRange({
          label: `Last ${count} Weeks`,
          startDate: startDate,
          endDate: isTillTodaySelected ? tillToday() : endDate,
        });
        console.log("type..",type)
        break;
    }
    isTillTodaySelected && !datePickerRange.label.includes("Including Today") && setDatePickerRange({...datePickerRange,"label":datePickerRange.label+" Including Today"})
  };

  const updateCustomDate = (startDate, endDate) => {
    const findDate = definedDateRanges.find((date) => formatDate(startDate) === formatDate(date.startDate) && formatDate(endDate) === formatDate(date.endDate));
    findDate?.label === "Last Week" && updateDropdownAndCount(findDate.label);
    findDate && setActiveList(findDate.label);
    return findDate?.label;
  };
  
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const definedDateRangesDropdown = ["Days", "Weeks", "Months", "Years"];

  useEffect(() => {
    const isLabelDateType=dateRange.label ?? dateRange.dateType ?? "";
    let start=datePickerRange.startDate ?? dateRange.fromDate;
    let end=datePickerRange.endDate ?? dateRange.toDate;
    const isTodayEnabled = datePickerRange.label.includes("including today");
    let isHandleCustomHit = false;
    if(datePickerRange.label){
      let numberRegex = /\d+/;
      let isNumberThere = numberRegex.exec(isLabelDateType);
      const dateType = datePickerRange.label.toLowerCase();
      if(isNumberThere){
        const countTemp = parseInt(isNumberThere[0]);
        if(dateType.includes("days") && dateType.includes("last")){
          handleCustomDate(definedDateRangesDropdown[0],countTemp,isTodayEnabled);
          isHandleCustomHit=true;
        }else if(dateType.includes("weeks") && dateType.includes("last")){
          handleCustomDate(definedDateRangesDropdown[1],countTemp,isTodayEnabled);
          isHandleCustomHit=true;
        }else if(dateType.includes("months") && dateType.includes("last")){
          handleCustomDate(definedDateRanges[2],countTemp,isTodayEnabled);
          isHandleCustomHit=true;
        }else if(dateType.includes("years") && dateType.includes("last")){
          handleCustomDate(definedDateRanges[3],countTemp,isTodayEnabled);
          isHandleCustomHit=true;
        }
      }else{
        if(dateType === "last week"){
          start = lastWeekStartDate(1);
          end = lastWeekEndDate();
        }else if(dateType === "this month"){
          start = new Date(today.getFullYear(), today.getMonth(), 1);
          end = new Date().setDate(today.getDate() - 1) < new Date(today.getFullYear(), today.getMonth(), 1) ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date().setDate(today.getDate() - 1);
        }else if(dateType === "this year"){
          start = new Date(today.getFullYear(), 0, 1);
          end = new Date().setDate(today.getDate() - 1) < new Date(today.getFullYear(), 0, 1) ? new Date(today.getFullYear(), 0, 1) : new Date().setDate(today.getDate() - 1);
        }else if(dateType === "today"){
          start = today;
          end  = today;
        }else if(dateType === "Yesterday"){
          start =  new Date().setDate(today.getDate() - 1);
          end =  new Date().setDate(today.getDate() - 1);
        }
      }
    }
    !isHandleCustomHit && setDatePickerRange({label: dateRange.label ?? dateRange.dateType ?? "",startDate: start ?? dateRange.fromDate,endDate: isTodayEnabled ? tillToday() : end});
    setTodaySelected(isTodayEnabled);
  }, []);

  const tillToday = () => {
    return formatDate(new Date());
  };

  const removeTillToday = (type) => {
    const isThere = {...definedDateRanges.find((range) => range.label === type)};
    if(type.toLowerCase() === "custom date range including today"){
      setDatePickerRange({...datePickerRange,label:"Custom Date Range",endDate:null});
    }else if(Object.keys(isThere).length){
      setDatePickerRange(isThere);
    }else{
      handleCustomDate(currentDate,count,false);
    }
  };

  const updateDropdownAndCount = (label) => {
    const array = label?.split(" ");
    if (label?.includes("Last") && array.length === 2) {
      setCount(1);
      setCurrentDate("Weeks");
    } else if (label?.includes("Last") && array.length === 3) {
      setCount(array[1]);
      setCurrentDate(array[2]);
    } else {
      setCount(null);
      setCurrentDate(null);
    }
  };
  useEffect(() => {
    updateDropdownAndCount(datePickerRange.label);
  }, []);
  useEffect(()=>{
    if(isNaN(count)){
      setDatePickerRange({...definedDateRanges[0]});
    }
  },[count]);
  useEffect(()=>{
    setTodaySelected(datePickerRange.label?.includes("Including Today"));
  },[datePickerRange]);
  useEffect(() => {
    let data=definedDateRanges.find(range => datePickerRange.label.substring(0,range.label.length) === range.label);
    setActiveList(data ? data.label : null);
  },[]);
  // console.log("datepicker....",datePickerRange);
  // console.log("daterange....",dateRange);
  return (
    <Modal
      style={{
        margin: "auto auto",
        backgroundColor: "#FFFFFF",
      }}
      open={isDatePickerOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={false}
      className="calanderModal"
    >
      <Box
        key={datePickerRange.startDate}
        style={{
          backgroundColor: "#FFFFFF",
          width: "100%",
          height: "100%",
          padding: "8px 15px",
          border: "0.5px solid #E5E5E5",
          boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          style={{
            color: "#2E3044",
            fontFamily: "Lato",
            fontWeight: 600,
            fontSize: "22px",
            paddingTop: "10px",
            fontStyle: "normal",
          }}
        >
          Choose the calendar date
        </Typography>
        <Typography
          variant="p"
          component="p"
          style={{
            color: "#6D6E7C",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "14px",
            paddingBottom: "14px",
            fontStyle: "normal",
          }}
        >
          {`${formatDate(datePickerRange.startDate)} - ${formatDate(
            datePickerRange.endDate
          )}`}
        </Typography>
        <Divider />
        <Grid
          container
          sx={{ marginTop: "5px" }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          item
        >
          <Grid xs={8.1} item>
            <Calendar
              value={[
                formatDate(datePickerRange.startDate),
                formatDate(datePickerRange.endDate),
              ]}
              range
              numberOfMonths={2}
              onChange={(array) => {
                if (array.length === 2) {
                  const arr = array.join(",\n").split(",");
                  setDatePickerRange({
                    label: updateCustomDate(arr[0], arr[1].substring(1)) ?? "Custom date range",
                    startDate: arr[0],
                    endDate: arr[1].substring(1)
                  });
                } else if (array.length === 1) {
                  const arr = array.join(",\n").split(",");
                  setDatePickerRange({
                    label: "Custom date range",
                    startDate: arr[0],
                    endDate: null
                  });
                  updateDropdownAndCount(null);
                  setTodaySelected(false);
                  setActiveList(null);
                }
              }}
              showOtherDays
              format="YYYY-MM-DD"
              key={datePickerRange.startDate + "-" + datePickerRange.endDate}
            />
          </Grid>
          <Grid xs={0.2} />
          <Grid
            xs={3.2}
            item
            style={{ backgroundColor: "#F8F9FD", borderRadius: "6px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
                width: "100%",
              }}
            >
              {definedDateRanges.map((date, index) => (
                <div key={index+"-"+datePickerRange.label}>
                  <ListItemButton
                    sx={{
                      textAlign: "center",
                      backgroundColor: date.label === activeList || (formatDate(date.startDate) === formatDate(datePickerRange.startDate) && formatDate(date.endDate) === formatDate(datePickerRange.endDate)) ? "#FFFFFF" : "",
                      color: "#585969",
                      fontFamily: "Inter",
                      fontSize: "12.5px",
                      marginTop: "10px",
                      width: "170px",
                      borderRadius: "6px",
                      display: "inline-block",
                      padding: "9px 0px",
                    }}
                    onClick={() => {
                      setDatePickerRange({
                        ...date,
                        endDate: isTillTodaySelected ? tillToday() : formatDate(date.endDate),
                        label: isTillTodaySelected ? date.label + " Including Today" : date.label
                      });
                      if (date.label.includes("Last")) {
                        setCount(1);
                        setCurrentDate("Weeks");
                      } else {
                        setCount(null);
                        setCurrentDate(null);
                      }
                      setActiveList(date.label);
                    }}
                  >
                    {date.label}
                  </ListItemButton>
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "80%",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ fontSize: "12px", color: "#585969" }}
                >
                  Last
                </Typography>
                <TextField
                  id="outlined-basic"
                  type="number"
                  min="1"
                  max="99"
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    width: "45px",
                    fontSize: "11px",
                  }}
                  className="calanderInputNumber"
                  value={count}
                  onChange={(e) => {
                    setCount(parseInt(e.target.value));
                    currentDate && handleCustomDate(currentDate, e.target.value, isTillTodaySelected);
                  }}
                />
                <FormControl style={{ width: "70px" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentDate}
                    label="Age"
                    size="small"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      width: "80px",
                      fontSize: "11px",
                    }}
                  >
                    {definedDateRangesDropdown.map((label, index) => (
                      <MenuItem
                        value={label}
                        key={index}
                        onClick={() =>
                          handleCustomDate(label, count, isTillTodaySelected)
                        }
                      >
                        {label.substring(0,label.length-1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sx={{ marginTop: "20px" }}
          justifyContent="space-between"
        >
          <Grid item sx={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={isTillTodaySelected}
                onClick={() => {
                  const label=datePickerRange.label;
                  if(isTillTodaySelected){
                    removeTillToday(activeList ?? label);
                    setTodaySelected(!isTillTodaySelected);
                  }else{
                    setDatePickerRange({...datePickerRange,label:datePickerRange.label+" "+"Including Today",endDate: tillToday(datePickerRange.endDate)});
                    setTodaySelected(!isTillTodaySelected);
                  }
                }}
              />
              <Typography
                variant="h5"
                component="h5"
                style={{ fontSize: "12px", color: "#2E3044", fontSize: "14px" }}
              >
                Till Today
              </Typography>
            </div>
          </Grid>
          <Grid item sx={6}>
            {compare && (
              <Button
                onClick={() => applyDate({ startDate: "", endDate: "" })}
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
              >
                No Comparison
              </Button>
            )}
            <Button
              sx={{
                textTransform: "capitalize",
                color: "#0869FB",
                marginRight: "20px",
              }}
              onClick={() => setIsDatePickerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize", backgroundColor: "#0869FB" }}
              onClick={() => {
                transformMixPanelData(
                  EVENTS.date_range_reports,
                  datePickerRange
                );
                applyDate(datePickerRange);
              }}
              disabled={!datePickerRange.endDate}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DateRangeModal;
