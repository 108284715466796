import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.GoogleAds;

export function getAccountId(account) {
    return {
        customerId: account?.customerId,
        loginCustomerId: account?.loginCustomerId,
    };
}

// export function getTableObject(table) {
//     return {
//         from: table?.id,
//         tableUiName: table?.name,
//     }
// }

// export function getTableFromObject(object) {
//     return {
//         id: object.from,
//         name: object.tableUiName,
//     }
// }

export function getFilterObject(filter) {
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    return {
        conditions: [...filter.dimensionFilters, ...filter.metricFilters].map(filter => ({
            fieldName: filter.filter.id,
            operator: filter.operator.type,
            value: filter.value,
        })),
    }
}

export function getApiObject(metrics, dimensions, filter, table) {
    return {
        selectFields: [...metrics, ...dimensions],
        // ...getTableObject(table),
        ...getFilterObject(filter),
    }
}


export const properties = {
    dateDimension: { id: "segments.date", name: "Date" },
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["INTEGER", "CURRENCY", "DATE", "TIME", "DATETIME", "BOOLEAN", "STRING", "ENUM"] },
        { label: "Not equal", type: "NOT_EQUALS", dataType: ["INTEGER", "CURRENCY", "DATE", "TIME", "DATETIME", "BOOLEAN", "STRING", "ENUM"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["INTEGER", "CURRENCY", "DATE", "TIME", "DATETIME", "FLOAT", "PERCENT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["INTEGER", "CURRENCY", "DATE", "TIME", "DATETIME", "FLOAT", "PERCENT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["INTEGER", "CURRENCY", "DATE", "TIME", "DATETIME", "FLOAT", "PERCENT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["INTEGER", "CURRENCY", "DATE", "TIME", "DATETIME", "FLOAT", "PERCENT"] },
        { label: "In", type: "IN_LIST", dataType: ["STRING", "ENUM"] },
        { label: "Not in", type: "NOT_IN_LIST", dataType: ["STRING", "ENUM"] },
        // { label: "Like", type: "LIKE", dataType: ["STRING", "ENUM"] },
        // { label: "Not like", type: "NOT LIKE", dataType: ["STRING", "ENUM"] },
        { label: "Contains", type: "CONTAINS", dataType: ["STRING", "ENUM"] },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: ["STRING", "ENUM"] },
        { label: "Starts With", type: "STARTS_WITH", dataType: ["STRING", "ENUM"] },
        { label: "Ends With", type: "ENDS_WITH", dataType: ["STRING", "ENUM"] },
        // { label: "Contains all", type: "CONTAINS ALL", dataType: ["STRING", "ENUM"] },
        // { label: "Contains none", type: "CONTAINS NONE", dataType: ["STRING", "ENUM"] },
        // { label: "Is null", type: "IS NULL", dataType: ["STRING", "ENUM"] },
        // { label: "Is not null", type: "IS NOT NULL", dataType: ["STRING", "ENUM"] },
        // { label: "During", type: "DURING", dataType: ["DATE", "TIME", "DATETIME"] },
        // { label: "Between", type: "BETWEEN", dataType: ["DATE", "TIME", "DATETIME"] },
        // { label: "Matches regex", type: "REGEXP_MATCH", dataType: ["STRING", "ENUM"] },
        // { label: "Not matches regex", type: "NOT REGEXP_MATCH", dataType: ["STRING", "ENUM"] },
    ],
    // validFilterBoolOperators: ["AND"],
}
