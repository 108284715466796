import CustomReportIcon from "./custom_report.svg"
import GoogleAdsIcon from "./google_ads.svg"
import FacebookIcon from "./facebook.svg"
import BingIcon from "./bing.svg"
import GoogleAnalyticsIcon from "./google_analytics.svg"
import ShopifyIcon from "./shopify.png"
import LinkedInIcon from "./linkedin.svg"
import TiktokIcon from "./tiktok.svg"
import GA4Icon from "./google_analytics_4.svg"
import BlendIcon from "./blend.svg"
import AllSourcesIcon from "./all_sources.svg"

export const AllIcons = [
    CustomReportIcon,
    GoogleAdsIcon,
    FacebookIcon,
    BingIcon,
    GoogleAnalyticsIcon,
    ShopifyIcon,
    LinkedInIcon,
    TiktokIcon,
    GA4Icon,
    BlendIcon,
    AllSourcesIcon,
]