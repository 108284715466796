import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import React from "react";
import TextFieldElement from "./TextFieldElement";
import { v4 as uuidv4 } from "uuid";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => (option.name ?? option.title) + option.subtitle,
});

export default function MetricList({
  channel,
  metric,
  metrics,
  isEditable,
  defaultTitle = "Add New Metric",
  onClick,
  onDelete,
  draggableProvided,
  value,
  disabled, msg
}) {

  const [open, setOpen] = React.useState(false);

  return (
    <FormControl
      sx={{
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        background: "#FFFFFF",
      }}
    >
      {isEditable || !metric ? (
        <Tooltip arrow placement="bottom-end"
          PopperProps={{
            sx: {
              "& .MuiTooltip-arrow": {
                color: "white",
                left: "-50px !important",
              },
              "& .MuiTooltip-tooltip": {
                backgroundColor: "white",
                borderRadius: "12px",
                color: "black",
                filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                padding: "20px",
                fontSize: "12px", fontWeight: 400
              },
            },
          }}
          title={disabled ? msg : ""}
        >
          <Autocomplete
            id="grouped-dimensions"
            sx={{
              width: "93%",
              opacity: disabled ? 0.6 : 1
            }}
            open={open}
            onOpen={() => { !disabled && setOpen(true) }}
            onClose={() => setOpen(false)}
            value={metric}
            options={metrics}
            size="small"
            className="bg-read-only metric-input"
            groupBy={(metric) => metric.group}
            getOptionLabel={(metric) => metric.name}
            getOptionDisabled={(option) => option.disabled}
            disabled={disabled}
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => {
              return (
                <li
                  style={{
                    background: selected ? "#EBF3FF" : "",
                    margin: "12px",
                    borderRadius: "8px",
                    paddingLeft: "12px",
                  }}
                  {...props}
                  key={uuidv4()}
                >
                  {option.icon && (
                    <img
                      height="20px"
                      width="20px"
                      style={{ marginLeft: "10px" }}
                      src={option.icon}
                      alt={option.id}
                    />
                  )}

                  <p
                    style={{
                      margin: "0px 5px",
                      paddingLeft: "10px",
                      minWidth: "70%",
                    }}
                  >
                    {option.name}
                    {option.subtitle && (
                      <h6 style={{ padding: "5px 0px" }}> {option.subtitle}</h6>
                    )}
                  </p>
                  {option.group === "Customized" && (
                    <span
                      style={{
                        width: "30%",
                        textAlign: "right",
                        color: "#00000088",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Cal{" "}
                    </span>
                  )}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            disableClearable={!onDelete}
            renderInput={(params) => (
              <TextFieldElement
                channel={channel}
                draggableProvided={draggableProvided}
                metric={value}
                label={metric ? channel.title : defaultTitle}
                isEmpty={!metric}
                {...params}
              />
            )}
            onChange={(event, newValue) => onClick(newValue)}
          />
        </Tooltip>
      ) : (
        <TextFieldElement
          channel={channel}
          draggableProvided={draggableProvided}
          metric={value}
          value={metric.metric ? metric.metric.name : metric.name}
          onClick={() => onClick(metric)}
        />
      )}

      {metric && onDelete && (
        <IconButton
          disableRipple
          disableFocusRipple
          sx={{ padding: "0" }}
          aria-label="delete"
          onClick={onDelete}
        >
          <Clear />
        </IconButton>
      )}
    </FormControl>
  );
}
