import { all, put, select, takeLatest } from "redux-saga/effects"
import {
    DELETE_FILTER, DELETE_FILTER_SUCCESS, DELETE_FILTER_FAILURE,
    GET_ALL_FILTERS, GET_ALL_FILTERS_FAILURE, GET_ALL_FILTERS_SUCCESS
} from "./type";
import { api } from ".."

export const getFilter = (filterIds) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/api/filter/get`, { filterIds });
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const createFilter = (filter) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/api/filter/create", filter);
        resolve(response.data?.filterId);
    } catch (error) {
        reject(error.response);
    }
});

export const updateFilter = (filter) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/api/filter/update", filter);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

function* deleteFilter(args) {
    try {
        const filterId = args.payload.filterId;
        yield api.post(`/api/filter/delete/`, { filterId });
        yield put({
            type: DELETE_FILTER_SUCCESS,
            payload: { id: filterId }
        });
    } catch (error) {
        yield put({
            type: DELETE_FILTER_FAILURE
        });
    }
}

function* getAllFilters(args) {
    try {
        const response = yield api.post("/api/filter/list", args.payload);
        const filters = response.data.data;
        yield put({
            type: GET_ALL_FILTERS_SUCCESS,
            payload: filters
        });
    } catch (error) {
        yield put({
            type: GET_ALL_FILTERS_FAILURE
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(DELETE_FILTER, deleteFilter),
        takeLatest(GET_ALL_FILTERS, getAllFilters)
    ])
}
