import {
    Autocomplete,
    createFilterOptions,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import React from "react";
import { v4 as uuidv4 } from "uuid"

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => (option.name ?? option.title) + option.id,
});

export default function DataList({ channel, metric, metrics, isEditable, startAdornment, endAdornment, defaultTitle = "Add New Metric", onClick, onDelete, error, disabled = false, stretch = "80%" }) {
    return (
        <FormControl
            sx={{ marginTop: "10px", display: "flex", flexDirection: "row", width: "95%" }}>
            {(isEditable || !metric) ? (
                <Autocomplete
                    id="grouped-dimensions"
                    autoHighlight
                    sx={{ width: "100%" }}
                    value={metric}
                    options={metrics}
                    size="small"
                    disabled={disabled}
                    className="bg-read-only metric-input"
                    groupBy={(metric) => metric.group}
                    getOptionLabel={(metric) => metric.name ?? metric.title}
                    getOptionDisabled={(option) => option.disabled}
                    filterOptions={filterOptions}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li {...props} key={uuidv4()} style={{ background: selected ? "#EBF3FF" : "", margin: "12px", borderRadius: "8px", paddingLeft: '12px', ...(endAdornment ? { display: "flex", justifyContent: "space-between" } : {}) }}>
                                {option.icon && <img height="20px" width="20px" style={{ marginRight: "16px" }} src={option.icon} alt={option.id} />}
                                <p style={{ margin: "0px 5px", wordBreak: endAdornment ? "break-all" : "break-word", maxWidth: endAdornment ? "80%" : "100%", fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>{option.name}</p>
                                {endAdornment &&
                                    <img height="14px" width="14px" src={endAdornment.src} alt="Link"
                                        onClick={() => {
                                            option.id && window.open(`https://docs.google.com/spreadsheets/d/${option.id}`);
                                        }}
                                    />
                                }
                            </li>
                        );
                    }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    disableClearable={!onDelete}
                    renderInput={(params) => {
                        if (params.InputProps) {
                            params.InputProps.disableUnderline = true;
                        }
                        return <div className="metric-list-form" style={{ width: '100%', display: "flex", flexDirection: "row", alignItems: "center" }}  >
                            {startAdornment
                                ? <InputAdornment>
                                    <img style={{ width: "18px", height: '18px', marginLeft: "10px", zIndex: 50 }} src={startAdornment.src} alt="null" />
                                </InputAdornment>
                                : <></>}
                            <TextField
                                hiddenLabel
                                id="Analytics"
                                variant="filled"
                                size="small"
                                InputProps={{
                                    disableUnderline: true,
                                    readOnly: true
                                }}
                                isEmpty={!metric}
                                placeholder={error ? "" : defaultTitle}
                                {...params}
                                error={error}
                                label={error ? "Required" : ""}
                            />
                        </div>
                    }}
                    onChange={(event, newValue) => onClick(newValue)}
                />)
                :
                <div className="metric-list-form" style={{ width: "100%" }} onClick={startAdornment?.src ? startAdornment.onClick : null}>
                    <TextField
                        hiddenLabel
                        id="Analytics"
                        variant="filled"
                        size="small"
                        style={{ width: stretch }}
                        InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            style: { cursor: "default" },
                            startAdornment: (
                                startAdornment
                                    ? <InputAdornment onClick={startAdornment.onClick} style={{ cursor: "pointer" }}>
                                        <img style={{ width: "18px", marginRight: "10px" }} src={startAdornment.src} alt="Calendar" />
                                    </InputAdornment>
                                    : <></>
                            ),
                        }}
                        disabled={disabled}
                        value={metric.metric ? metric.metric.name : metric.name ?? metric.title}
                    // defaultValue={metric.metric ? metric.metric.name : metric.name ?? metric.title}
                    // onClick={() => onClick(metric)}
                    />
                </div>
            }

            {
                metric && onDelete &&
                <IconButton
                    disableRipple
                    disableFocusRipple
                    style={{ position: "absolute", right: 4 }}
                    aria-label="delete"
                    onClick={onDelete}
                >
                    <Clear />
                </IconButton>
            }
        </FormControl>
    );
}
