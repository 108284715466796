import { all, call, put, select, takeLatest } from "redux-saga/effects"
import {
    CREATE_REPORT, UPDATE_REPORT, DELETE_REPORT,
    CREATE_REPORT_FAILURE, CREATE_REPORT_SUCCESS,
    UPDATE_REPORT_SUCCESS, UPDATE_REPORT_FAILURE,
    DELETE_REPORT_SUCCESS, DELETE_REPORT_FAILURE,
    GET_ALL_REPORTS, GET_ALL_REPORTS_FAILURE, GET_ALL_REPORTS_SUCCESS,
    GET_REPORT, GET_REPORT_FAILURE, GET_REPORT_SUCCESS,
    UPDATE_CHART, UPDATE_CHART_LOCAL, UPDATE_CHART_SUCCESS,
    UPDATE_GRID, UPDATE_GRID_LOCAL, UPDATE_TAB, UPDATE_TAB_LOCAL, UPDATE_TAB_SUCCESS, UPDATE_GRID_SUCCESS,
    DUPLICATE_REPORT, DUPLICATE_REPORT_SUCCESS, DUPLICATE_REPORT_FAILURE, DUPLICATE_TAB_SUCCESS, DUPLICATE_TAB_FAILURE,
    GET_DEFAULT_REPORT, GET_DEFAULT_REPORT_SUCCESS, GET_DEFAULT_REPORT_FAILURE,
    SET_DEFAULT_REPORT, SET_DEFAULT_REPORT_SUCCESS, SET_DEFAULT_REPORT_FAILURE
} from "./type";
import { Tab as ReportTab, Grid as ReportGrid } from "../../models/report";
import { Chart } from "../../models/chart";
import { api } from ".."
import { UPDATE_LOADER } from "../user/type";
import {EVENTS,transformMixPanelData} from "../../utils/mixpanel";

function* createReport(args) {
    try {
        const report = args.payload.report;
        const response = yield api.post("/api/report/create", {
            report: report.toJSON()
        });
        const id = response.data.reportId;
        yield put({
            type: CREATE_REPORT_SUCCESS,
            payload: {
                id: id,
                report: report,
            }
        });
        yield getAllReports();
        if (args.payload.loading) {
            yield args.payload.loading("Success");
        }
        transformMixPanelData(EVENTS.migrate_reports_success,args.payload);
        window.location.href = `/dashboard/${id}`;
    } catch (error) {
        yield put({
            type: CREATE_REPORT_FAILURE
        });
        if (args.payload.loading) {
            yield args.payload.loading("Fail");
        }
    }
}

function* updateReport(args) {
    try {
        yield api.post(`/api/report/update/${args.payload.reportId}`, {
            params: args.payload.params
        });
        yield put({
            type: UPDATE_REPORT_SUCCESS,
            payload: args.payload
        });
    } catch (error) {
        yield put({
            type: UPDATE_REPORT_FAILURE
        });
    }
}

function* deleteReport(args) {
    try {
        const id = args.payload.id;
        yield api.post(`/api/report/delete/${id}`);
        if (args.payload.delete) {
            yield setDefaultReport({
                payload: { defaultReportId: null }
            })
        }
        yield put({
            type: DELETE_REPORT_SUCCESS,
            payload: { id: id }
        });
        yield window.location.href = "/dashboard/";
    } catch (error) {
        yield put({
            type: DELETE_REPORT_FAILURE
        });
    }
}

export const getReportsList = ({ id }) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/api/report/list", {
            organization_id: id
        });
        resolve(response.data.data);
    } catch (error) {
        reject(error.response);
    }
});

export const getReportData = ({ id }) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/api/report/get/${id}`);
        resolve(response.data.data);
    } catch (error) {
        reject(error.response);
    }
});

function* getAllReports() {
    try {
        const response = yield api.post("/api/report/list");
        const reports = response.data.data;
        yield put({
            type: GET_ALL_REPORTS_SUCCESS,
            payload: reports
        });
    } catch (error) {
        yield put({
            type: GET_ALL_REPORTS_FAILURE
        });
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        }
    }
}

function* getReport(args) {
    const id = args.payload.id;
    try {
        const response = yield api.post(`/api/report/get/${id}`);
        yield put({
            type: GET_REPORT_SUCCESS,
            payload: { id: id, report: response.data.data }
        });
        if (args.payload.update) {
            yield args.payload.update(response.data.data);
        }
    } catch (error) {
        yield put({
            type: GET_REPORT_FAILURE
        });
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        }
    }
}
const sendToMixpanel = (reports,args,isEditWidgetOrSave) => {
    if(isEditWidgetOrSave === "CREATE_NEW_CHART"){
        transformMixPanelData(EVENTS.add_new_chart,args.payload);
    }else if(isEditWidgetOrSave === "UPDATE_CHART"){
        transformMixPanelData(EVENTS.edit_chart,args.payload);
    }else if(isEditWidgetOrSave === "REMOVE_CHART"){
        const charts = reports.reports[args.payload.reportId].charts;
        const chart_id = args.payload.updates[0].chart_id;
        const data = charts.find(chart => chart.id === chart_id);
        const temp = {...args.payload};
        temp["updates"][0]["params"] = data;
        transformMixPanelData(EVENTS.delete_chart,temp);
    }
}
function* updateChart(args) {
    if (args.payload.updates.length === 0) { return; }
    try {
        const isEditWidgetOrSave = yield select(state => state.channel.isSaveAndUpdateClick);
        const reports = yield select(state => state.report);
        yield put({
            type: UPDATE_CHART_LOCAL,
            payload: args.payload
        });
        yield api.post(`/api/report/${args.payload.reportId}/chart/update`, {
            updates: args.payload.updates
        });
        yield put({
            type: UPDATE_CHART_SUCCESS,
            payload: args.payload
        });
        sendToMixpanel(reports,args,isEditWidgetOrSave);
    } catch (error) {
        console.log("🚀 ~ file: saga.js ~ line 139 ~ function*updateChart ~ error", error)
    }
}

function* updateGrid(args) {
    if (args.payload.updates.length === 0) { return; }
    try {
        yield put({
            type: UPDATE_GRID_LOCAL,
            payload: args.payload
        });
        yield api.post(`/api/report/${args.payload.reportId}/grid/update`, {
            updates: args.payload.updates
        });
        yield put({
            type: UPDATE_GRID_SUCCESS,
            payload: args.payload
        });
    } catch (error) {
        console.log("🚀 ~ file: saga.js ~ line 157 ~ function*updateGrid ~ error", error)
    }
}

function* updateTab(args) {
    if (args.payload.updates.length === 0) { return; }
    try {
        yield put({
            type: UPDATE_TAB_LOCAL,
            payload: args.payload
        });
        yield api.post(`/api/report/${args.payload.reportId}/tab/update`, {
            updates: args.payload.updates
        });
        yield put({
            type: UPDATE_TAB_SUCCESS,
            payload: args.payload
        });
        if (args.payload.navigate) {
            yield call(args.payload.navigate);
        }
    } catch (error) {
        console.log("🚀 ~ file: saga.js ~ line 176 ~ function*updateTab ~ error", error)
    }
}

function* duplicateTab(args) {
    try {
        let { report, destReport, tab, index, destTabs } = args || {};
        const sourceTab = report.tabs.find(t => t.id === tab.id)
        tab = new ReportTab((tab.newName || tab.name), destTabs.length + index, sourceTab.grids)
        const grids = tab.grids.map(grid => {
            return new ReportGrid(tab.id, grid.title, grid.subtitle, grid.charts, grid.gridStyle)
        });
        const charts = grids.reduce((allCharts, grid) => allCharts.concat(grid.charts.map(chart => {
            return new Chart(
                chart.channelType, chart.title, chart.type, chart.table, chart.gridPosition,
                chart.leftMetrics, chart.rightMetrics, chart.dimensions,
                chart.filter, chart.account, chart.chartStyle,
                grid.id,                        // updated parent grid id
                chart.compareWith
            )
        })), [])

        yield all([
            updateTab({
                payload: {
                    reportId: destReport.id,
                    updates: [{ tab_id: tab.id, update_type: "add", params: tab.toJSON() }],
                }
            }),
            updateGrid({
                payload: {
                    reportId: destReport.id,
                    updates: grids.map(grid => {
                        return { grid_id: grid.id, update_type: "add", params: grid.toJSON() }
                    })
                }
            }),
            updateChart({
                payload: {
                    reportId: destReport.id,
                    updates: charts.map(chart => {
                        return ({ chart_id: chart.id, update_type: "add", params: chart.toJSON() })
                    })
                }
            })
        ])
        yield put({
            type: DUPLICATE_TAB_SUCCESS
        })
    } catch (error) {
        yield put({
            type: DUPLICATE_TAB_FAILURE
        });
        console.log("🚀 ~ file: saga.js ~ line 219 ~ function*duplicateTab ~ error", error)
        throw error;
    }
}

function* duplicateReport(args) {
    try {
        const { report, destReport, tabs, destTabs } = args.payload || {};
        yield all(tabs.map((tab, index) => duplicateTab({ report, destReport, tab, index, destTabs })));
        yield put({
            type: DUPLICATE_REPORT_SUCCESS
        })
        yield args.payload.loading("Success");
        window.location.href = `/dashboard/${destReport.id}`;
    } catch (error) {
        yield put({
            type: DUPLICATE_REPORT_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* getDefaultReport(args) {
    if (args?.payload?.loading) {
        yield put({
            type: UPDATE_LOADER,
            payload: true
        });
    }
    try {
        const response = yield api.post("/api/users/default-report/", {}, true)
        yield put({
            type: GET_DEFAULT_REPORT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: GET_DEFAULT_REPORT_FAILURE
        });
    }
    if (args?.payload?.loading) {
        yield put({
            type: UPDATE_LOADER,
            payload: false
        });
    }
}

function* setDefaultReport(args) {
    try {
        const response = yield api.post("/api/users/update-default-report/", {
            // email: args.payload.email,
            defaultReportId: args.payload.defaultReportId
        }, true);
        yield put({
            type: SET_DEFAULT_REPORT_SUCCESS,
            payload: response.data
        });
        if (args?.payload?.loading) {
            yield args.payload.loading("Report has been set to default");
        }
        //yield getAllReports()
        transformMixPanelData(EVENTS.default_report_set,{});
    } catch (error) {
        yield put({
            type: SET_DEFAULT_REPORT_FAILURE
        });
        if (args?.payload?.loading) {
            yield args.payload.loading("Something went wrong, Try again later");
        }
    }
}

export default function* root() {
    yield all([
        takeLatest(CREATE_REPORT, createReport),
        takeLatest(UPDATE_REPORT, updateReport),
        takeLatest(DELETE_REPORT, deleteReport),
        takeLatest(GET_ALL_REPORTS, getAllReports),
        takeLatest(GET_REPORT, getReport),
        takeLatest(UPDATE_CHART, updateChart),
        takeLatest(UPDATE_GRID, updateGrid),
        takeLatest(UPDATE_TAB, updateTab),
        takeLatest(DUPLICATE_REPORT, duplicateReport),
        takeLatest(SET_DEFAULT_REPORT, setDefaultReport),
        takeLatest(GET_DEFAULT_REPORT, getDefaultReport)
    ])
}
