import React from "react";
import { Box, Button, Modal, Typography } from '@mui/material';
import "../styles/modal.css";
import { ReactComponent as StartTour } from "../../Assets/Icons/StartTour.svg";
import { useDispatch } from "react-redux";
import { updateTourInfo } from "../../api/user/action";

const ProductTourModal = (props) => {
    const { modal, setModal } = props;
    const dispatch = useDispatch();

    const customStyles = {
        bottom: '5%',
        left: '5%',
        right: 'auto',
        top: 'auto',
        backgroundColor: "#ffff",
        borderRadius: "8px",
        width: "30%",
        minHeight: "auto",
        padding: "30px",
        zIndex: 9000000000000,
        position: "absolute",
        fontFamily: "Inter"

    };

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(!modal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={customStyles}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="body1" style={{ textTransform: "uppercase", fontSize: "16px", fontWeight: 600, marginBottom: "10px", color: "#0869FB" }}>
                            Welcome to Pixis Analytics!
                        </Typography>

                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div>
                            <Typography variant="body1" style={{ fontSize: "28px", fontWeight: 600, marginBottom: "10px" }}>
                                Quick Product Tour
                            </Typography>
                            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 400, marginBottom: "10px", color: "#5A5A5A" }}>
                                We're here to give you a quick overview of Pixis Analytics and all its features.
                            </Typography>
                        </div>
                        <div style={{ background: "#F4F9FF", borderRadius: "100%", height: "140px", minWidth: "140px" }}>
                            <StartTour style={{ marginLeft: "10px", marginTop: "20px" }} />
                        </div>
                    </div>
                    <div>
                        <Button style={{ backgroundColor: "#0869FB", color: "white", padding: "10px 20px", borderRadius: "8px" }}
                            onClick={() => {
                                dispatch(updateTourInfo({ run: true, stepIndex: 0 }));
                                setModal(false);
                            }}
                        >Get started</Button>
                        <Button style={{ padding: "10px 20px", borderRadius: "8px", border: "1px solid #0869FB", marginLeft: "20px" }}
                            onClick={() => {
                                dispatch(updateTourInfo({ showTutorial: false }))
                                setModal(false)
                            }}
                        >Show me later</Button>
                    </div>
                </Box>

            </Modal >
        </div>
    )
}
export default ProductTourModal;
