import { combineReducers } from "redux";
import authReducer from "../api/auth/reducer";
import channelReducer from "../api/channel/reducer";
import dashboardReducer from "../api/dashboard/reducer";
import userReducer from "../api/user/reducer";
import reportReducer from "../api/report/reducer";
import blendsReducer from "../api/blends/reducer";
import filterReducer from "../api/filter/reducer";
import extractorReducer from "../api/extractor/reducer"
import { SupportedChannels } from "../models/channel";
import { LOGOUT } from "../api/auth/type";
import { REDUX_VERSION } from "..";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import LZString from 'lz-string';

export const UPDATE_STATE = "UPDATE_STATE";

export const compressTransform = {
    in: (state) => LZString.compress(JSON.stringify(state)),
    out: (state) => JSON.parse(LZString.decompress(state)),
};

const reportPersistConfig = {
    key: 'report',
    storage,
    transforms: [compressTransform],
};

const reducers = {
    auth: authReducer,
    channel: channelReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    report: persistReducer(reportPersistConfig, reportReducer),
    blends: blendsReducer,
    filters: filterReducer,
    extractor: extractorReducer
};

SupportedChannels.forEach(channel => reducers[channel.type] = persistReducer({
    key: channel.type,
    storage,
    transforms: [compressTransform],
}, require(`../api/${channel.type}/reducer`).default));

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        localStorage.clear();
        localStorage.setItem("reduxVersion", REDUX_VERSION);
        return combinedReducer(undefined, action);
    } else if (action.type === UPDATE_STATE) {
        return combinedReducer(require(`./updates/update${action.payload}`).default(JSON.parse(JSON.stringify(state))), action);
    }
    return combinedReducer(state, action);
}

export default rootReducer;
