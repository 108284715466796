import React from "react";
import ErrorIcon from "../../Assets/Images/error_icon.svg"
import LoginIcon from "../../Assets/Images/LoginIcon.svg";
import PixisIcon from "../../Assets/Icons/pixis.svg";
import { connect } from "react-redux";
import { resetError, signup } from "../../api/auth/action";
import { Button, Grid, InputAdornment, Link, TextField, Typography } from "@mui/material";
import MuiPhoneNumber from 'material-ui-phone-number';
import jwtDecode from "jwt-decode";
import { useParams } from "react-router";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';

function SignupPage(props) {
    const { signup, resetError, signupFailureMessage } = props;

    let { invite_token } = useParams();
    const invitedUser = invite_token && jwtDecode(invite_token)

    const isInvited = !!invitedUser;

    const getFirstAndLastName = (name) => {
        const firstName = invitedUser.name.split(' ').slice(0, -1).join(' ');
        const lastName = invitedUser.name.split(' ').slice(-1).join(' ');
        return { firstName, lastName }
    }

    const state = React.useRef({
        firstName: isInvited ? getFirstAndLastName(invitedUser.name).firstName : "",
        lastName: isInvited ? getFirstAndLastName(invitedUser.name).lastName : "",
        phone: "",
        company: isInvited ? invitedUser.organization_name : "",
        email: isInvited ? invitedUser.email : "",
        domain: isInvited ? invitedUser.organization_domain : "",
        password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = React.useState({
        firstName: null,
        lastName: null,
        phone: null,
        company: null,
        email: null,
        domain: null,
        password: null,
        confirmPassword: null,
    });
    const [togglePassword1, setTogglePassword1] = React.useState(false);
    const [togglePassword2, setTogglePassword2] = React.useState(false);


    const validateData = (property, reqVal) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*$/;
        const phoneRegex = /^\+[1-9]{1,3} [0-9-]{3,14}$/;
        const specialCharTest = /[+={}()|[\]:;"'<>,.?/~`]/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_-]+).{8,}$/;

        const values = state.current;

        switch (property) {
            case "firstName":
                if (values.firstName.length === 0) {
                    return "First name is required";
                }
                break;
            case "lastName":
                if (values.lastName.length === 0) {
                    return "Last name is required";
                }
                break;
            case "phone":
                // if (values.phone.length === 0) {
                //     return "Phone is required";
                // }
                // else if (!phoneRegex.test(values.phone)) {
                //     return "Invalid phone";
                // }
                break;
            case "company":
                if (values.company.length === 0) {
                    return "Company is required";
                }
                break;
            case "email":
                if (values.email.length === 0) {
                    return reqVal ? "Email is required" : null;
                } else if (!emailRegex.test(values.email)) {
                    return "Invalid Email";
                } else if (!values.email.endsWith(values.domain.split(".").splice(-2).join("."))) {
                    return "Email Domain must match domain name"
                }
                break;
            case "domain":
                if (values.domain.length === 0) {
                    return reqVal ? "Domain is required" : null;
                } else if (!domainRegex.test(values.domain)) {
                    return "Invalid Domain Name";
                } else if (!values.email.endsWith(values.domain.split(".").splice(-2).join("."))) {
                    return "Domain Name must match email ID domain"
                }
                break;
            case "password":
                if (values.password.length === 0) {
                    return "Password is required";
                } else if (specialCharTest.test(values.password)) {
                    return "Entered character is not supported";
                } else if (!passwordRegex.test(values.password)) {
                    return "Invalid Password (minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)";
                }
                break;
            case "confirmPassword":
                if (values.confirmPassword.length === 0) {
                    return "Confirm password is required";
                } else if (values.password !== values.confirmPassword) {
                    return "Passwords do not match";
                }
                break;
            default:
                return null;
        }
        return null;
    }

    const handleSignup = () => {
        const newErrors = { ...errors };
        Object.keys(state.current).forEach(key => newErrors[key] = validateData(key, true));
        setErrors(newErrors);

        const isValid = Object.values(newErrors).find(v => v !== null) === undefined;
        if (isValid) {
            signup(state.current);
        }
    }

    const handleChange = (property, value) => {
        state.current = { ...state.current, [property]: (property === "email" || property === "domain") ? value.toLowerCase().trim() : value };
        let coProperty = property === "email" ? "domain" : property === "domain" ? "email" : null;
        setErrors({ ...errors, [property]: validateData(property), [coProperty]: coProperty ? validateData(coProperty) : null });
    }
    return (
        <div style={{ flexGrow: 1, height: "100%", overflow: "auto", backgroundColor: "#fff", minHeight: "100vh" }}>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "100vh", position: "relative" }}>
                <Grid sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FCFCFF' }} item sm={12} md={7}>
                    <img style={{ height: '60%' }} src={LoginIcon} alt="Login" />
                </Grid>
                <Grid container spacing={0} sx={{ backgroundColor: "#fff", textAlign: "center", overflow: "auto", height: "100%", display: 'flex', justifyContent: 'center' }} item sm={12} md={5}>
                    <Grid xs={12} md={7} lg={7} >
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <img src={PixisIcon} style={{ width: 100 }} alt="Pixis icon" />
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <Typography style={{ color: "#0869FB" }} variant="h4" >
                                Analytics
                            </Typography>
                        </Grid>
                        <Grid style={{ padding: "4px" }} sx={{ marginTop: '2%' }} item xs={12}>
                            <Typography style={{ color: "#222222", fontSize: 20, fontWeight: 500 }} variant="body1" gutterBottom>
                                Create Account
                            </Typography>
                        </Grid>
                        <Grid sx={{ display: 'flex' }} item xs={12} md={12} lg={12}>
                            <Grid sx={{ display: 'flex' }} item xs={6} md={6} lg={6} style={{ padding: "4px" }}>
                                <TextField
                                    required
                                    defaultValue={state.current.firstName}
                                    error={errors.firstName !== null}
                                    helperText={errors.firstName}
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    onChange={_ => handleChange("firstName", _.target.value)}
                                    onKeyDown={() => {
                                        if (props.signupFailure) { resetError(); }
                                    }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                    InputProps={{ style: { borderRadius: '8px' } }}
                                    variant="outlined" />
                            </Grid>
                            <Grid sx={{ display: 'flex' }} item xs={6} md={6} lg={6} style={{ padding: "4px" }}>
                                <TextField
                                    required
                                    defaultValue={state.current.lastName}
                                    error={errors.lastName !== null}
                                    helperText={errors.lastName}
                                    label="Last Name"
                                    placeholder="Enter Last Name"
                                    style={{ marginBottom: '10px', width: '100%' }}
                                    onChange={_ => handleChange("lastName", _.target.value)}
                                    onKeyDown={() => {
                                        if (props.signupFailure) { resetError(); }
                                    }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                    InputProps={{ style: { borderRadius: '8px' } }}
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <MuiPhoneNumber
                                error={errors.phone !== null}
                                helperText={errors.phone}
                                variant="outlined"
                                label="Phone No."
                                placeholder="Enter Phone Number"
                                defaultCountry={'in'}
                                onChange={_ => handleChange("phone", _)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} style={{ marginBottom: '10px', width: '100%' }}
                                InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px' } }} />
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <TextField
                                required
                                defaultValue={state.current.email}
                                error={errors.email !== null}
                                helperText={errors.email}
                                label="E-Mail ID"
                                placeholder="Enter Your E-Mail"
                                style={{ marginBottom: '10px', width: '100%' }}
                                onChange={_ => handleChange("email", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px' }, readOnly: isInvited }}
                                variant="outlined" />
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <TextField
                                required
                                defaultValue={state.current.domain}
                                error={errors.domain !== null}
                                helperText={errors.domain}
                                label="Domain Name"
                                placeholder="Enter Domain Name (example.com)"
                                style={{ marginBottom: '10px', width: '100%' }}
                                onChange={_ => handleChange("domain", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px' }, readOnly: isInvited }}
                                variant="outlined" />
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <TextField
                                required
                                defaultValue={state.current.company}
                                error={errors.company !== null}
                                helperText={errors.company}
                                label="Company Name"
                                placeholder="Enter Company Name"
                                style={{ marginBottom: '10px', width: '100%' }}
                                onChange={_ => handleChange("company", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px' }, readOnly: isInvited }}
                                variant="outlined" />
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <TextField
                                required
                                error={errors.password !== null}
                                helperText={errors.password}
                                label="Create Password"
                                placeholder="Create Your Password"
                                style={{ marginBottom: '10px', width: '100%' }}
                                type={!togglePassword2 ? "password" : "text"}
                                onChange={_ => handleChange("password", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{
                                    style: { borderRadius: '8px' }, endAdornment: (
                                        <InputAdornment position="end">
                                            {togglePassword2 ? <IconButton onClick={() => setTogglePassword2(!togglePassword2)}><Visibility style={{ color: "#707070" }} /></IconButton> :
                                                <IconButton onClick={() => setTogglePassword2(!togglePassword2)}><VisibilityOff style={{ color: "#707070" }} /></IconButton>}
                                        </InputAdornment>
                                    )
                                }}
                                variant="outlined" />
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <TextField
                                required
                                error={errors.confirmPassword !== null}
                                helperText={errors.confirmPassword}
                                label="Confirm Password"
                                placeholder="Confirm Your Password"
                                style={{ marginBottom: '10px', width: '100%' }}
                                type={!togglePassword1 ? "password" : "text"}
                                onChange={_ => handleChange("confirmPassword", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }}
                                InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{
                                    style: { borderRadius: '8px' }, endAdornment: (
                                        <InputAdornment position="end">
                                            {togglePassword1 ? <IconButton onClick={() => setTogglePassword1(!togglePassword1)}><Visibility style={{ color: "#707070" }} /></IconButton> :
                                                <IconButton onClick={() => setTogglePassword1(!togglePassword1)}><VisibilityOff style={{ color: "#707070" }} /></IconButton>}
                                        </InputAdornment>
                                    )
                                }}
                                variant="outlined" />
                        </Grid>
                        {props.signupFailure ? (<Grid item xs={12} style={{ height: "auto", backgroundColor: "#FF000033", borderRadius: "8px", display: "flex", alignItems: "center", padding: "8px 16px", margin: "4px" }}>
                            <div style={{ display: "flex" }} >
                                <img src={ErrorIcon} alt="Error!" />
                            </div>
                            <div style={{ width: "100%", padding: "0px 10px" }} >
                                <b>Error!</b>
                                <br />{signupFailureMessage}
                            </div>
                        </Grid>) : <></>}
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <Button onClick={() => handleSignup()} style={{ width: "100%", height: "50px", borderRadius: '8px' }} variant="contained" color="primary">
                                Sign Up
                            </Button>
                        </Grid>
                        <Grid style={{ padding: "4px" }} item xs={12}>
                            <Typography style={{ flexGrow: 1, height: "100%", overflow: "auto" }}>
                                <span style={{ marginRight: 8 }}>Already have an account? </span>
                                <Link href="/login" >
                                    SignIn
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    signup: (_) => dispatch(signup(_)),
    resetError: () => dispatch(resetError())
});

const mapStateToProps = state => ({
    signupFailure: state.auth.signupFailure,
    signupFailureMessage: state.auth.signupFailureMessage
});


export default (connect(mapStateToProps, mapDispatchToProps)(SignupPage))
