import React from "react";
import { Button, Grid, TextField,Box } from "@mui/material";
import { connect } from "react-redux";
import { connectAccountApi } from "../../api/adjust/action";

function AdjustConnect({ connectAccountApi }) {
  const [state, setState] = React.useState({
    adjust_access_token: "",
    token_name:""
  });

  const handleChange = (property, value) => {
    setState({ ...state, [property]: value });
  };

  return (
    <Box
      flexDirection="column" 
      display="flex" 
      style={{ height: "100%",width: "85%", margin: "20px auto", overflowY: "auto" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          marginTop: "0px"
        }}
      >
        <Grid
          xs={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            margin: "0px",
            marginTop: "0",
          }}
        >
          <p style={{margin:2}}>Follow these <a href="https://help.adjust.com/en/article/api-authentication" target="_blank">instructions</a> to connect your adjust account.</p>
          <ul style={{textAlign:"left",padding:"0px 0px 0px 15px"}}>
            <li>Select the settings icon in the bottom left corner.</li>
            <li>Select Account settings. Your account settings page opens.</li>
            <li>Select the My profile tab.</li>
            <li>Your API token is shown with your User details.</li>
          </ul>
          <h4 style={{ fontSize: "14px",fontWeight:600 }}>New Adjust account</h4>
          <h5 style={{ fontSize: "12px", marginTop: "5px" }}>Access token name</h5>
          <TextField
            style={{ borderColor: "red", width: "100%" }}
            placeholder="Enter access token name"
            onChange={(e) =>
              handleChange("token_name", e.target.value)
            }
            error={ !state.token_name }
            type="text"
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            variant="outlined"
            size="small"
          />
          <h5 style={{ fontSize: "12px", marginTop: "5px" }}>Access token</h5>
          <TextField
            style={{ borderColor: "red", width: "100%" }}
            placeholder="*************************************"
            onChange={(e) =>
              handleChange("adjust_access_token", e.target.value)
            }
            error={ !state.adjust_access_token  }
            type="password"
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            variant="outlined"
            size="small"
          />
          <Button
            style={{
              backgroundColor: "#0869FB",
              color: "white",
              padding: "5px 15px",
              width: "100%",
              marginTop: "8px",
            }}
            onClick={() =>
              (state.token_name.length !== 0 && state.adjust_access_token.length !== 0) && connectAccountApi({
                redirect_url: "managesources",
                ...state,
              })
            }
          >
            Connect an account
          </Button>
        </Grid>
      </div>
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  connectAccountApi: (_) => dispatch(connectAccountApi(_)),
});

export default connect(null, mapDispatchToProps)(AdjustConnect);
