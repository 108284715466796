import { all, put, select, takeLatest } from "redux-saga/effects";
import { api } from "..";
import { EVENTS,transformMixPanelData } from "../../utils/mixpanel";

function* blendsList(args) {
    try {
        const response = yield api.post("/api/blends/list");
        yield put({
            type: "BLENDS_LIST_SUCCESS",
            payload: response.data
        })
    } catch (error) {
        yield put({
            type: `BLENDS_LIST_FAILURE`,
            payload: error.response
        });
    }
    if (args?.payload?.update) {
        yield args.payload.update()
    }
}

function* blendsCreate(args) {
    try {
        const response = yield api.post("/api/blends/create", args.payload.blendData);
        yield put({
            type: "BLENDS_CREATE_SUCCESS",
            payload: response.blends
        })
        yield put({
            type: 'BLENDS_LIST',
        })
        transformMixPanelData(EVENTS.create_new_source,{...args.payload.blendData});
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: 'BLENDS_CREATE_FAILURE',
            payload: error.response
        })
        yield args.payload.loading("Fail");
    }
}

function* updateBlend(args) {
    try {
        yield api.post(`/api/blends/${args.payload.id}/update`, args.payload.obj);
        yield put({
            type: 'BLENDS_LIST',
        })
        yield args.payload.loading("Success");
        transformMixPanelData(EVENTS.edit_blend_success,args.payload.obj);
    } catch (error) {
        yield args.payload.loading("Fail");
    }
}

function* deleteBlend(args) {
    try {
        yield api.post(`/api/blends/${args.payload.id}/delete`);
        yield put({
            type: 'BLENDS_LIST',
        })
        yield args.payload.loading("Success");
    } catch (error) {
        yield args.payload.loading("Fail");
    }
}

export const getBlendColumns = (blendId) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/api/blends/${blendId}/columns`);
        resolve(response?.data);
    } catch (error) {
        reject(error.response);
    }
});

export const getBlendWidgetData = (blendId, apiConfigs) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/api/blends/extract", {
            blendId: blendId,
            api_configs: JSON.stringify(apiConfigs),
        });
        resolve([{ ...response.data, rowCount: response.data.values.length }]);
    } catch (error) {
        reject(error.response);
    }
});

export default function* root() {
    yield all([
        takeLatest("BLENDS_LIST", blendsList),
        takeLatest("BLENDS_CREATE", blendsCreate),
        takeLatest("BLENDS_UPDATE", updateBlend),
        takeLatest("BLENDS_DELETE", deleteBlend),
    ]);
}
