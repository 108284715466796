import { useEffect, useState,useMemo } from "react";
import Appbar from "../../Dashboard/AppBar";
import NavigationPane from "./NavigationPane";
import Extractor from "../Extractor/Extractor";
import { useSelector, useDispatch } from "react-redux";
import {  allUniqueDates, allUniqueSheets, transformToSuperMetricDataObject } from "../Utils";
import { Box, Button } from "@mui/material";
import ConfirmModal from "../../Modal/ConfirmModal";
import { updateBulkTrigger } from "../../../api/extractor/action";
import SuccessModal from "../../Modal/SuccessModal";
import FailureModal from "../../Modal/FailureModal";
import { updateLoader } from "../../../api/user/action";

const transformedData = (triggers=[],changedTriggers={}) => {
  const bulkTriggers = [];
  triggers.forEach(trigger => {
    if(changedTriggers[trigger._id]){
      const id = { _id: trigger._id };
      bulkTriggers.push(
        {
          ...id,
          ...transformToSuperMetricDataObject({
            name: trigger.name,
            channel: trigger.channelType,
            table: trigger.table,
            dataLevel: null,
            accounts: trigger.accounts,
            dateRange: trigger.dateRange,
            sendOverEmail: trigger.sendOverEmail,
            status: trigger.status,
            triggerData: trigger.triggerData,
            scheduleData: trigger.scheduleData,
            storageData: trigger.storageData}
        )}
      );
    }
  })
  return bulkTriggers;
};

export default function BulkEditing() {
  const listBulkEditing = useSelector((state) => state.extractor.listBulkEditing);
  const [bulkTriggers, setBulkTriggers] = useState(listBulkEditing);
  const [currentTrigger, setCurrentTrigger] = useState(listBulkEditing[0] ?? {});
  const dates = useMemo(() => allUniqueDates(listBulkEditing) ?? {});
  const sheets  = useMemo(() => allUniqueSheets(listBulkEditing) ?? {});
  const [confirmBulkUpdate, setConfirmBulkUpdate] = useState("DEFAULT");
  const [infoComplete,setInfoComplete] = useState({triggerComplete: false, scheduleComplete: false, storageComplete: false});
  const dispatch = useDispatch();
  const [completion, setCompletion] = useState({ message: "none",operation: "none" });
  const [bulkSuccessModal,setBulkSuccessModal] = useState(false);
  const [changedTriggers,setChangedTriggers] = useState({});
  const user = useSelector((state) => state.user.user);
  const [changedTriggersCount,setChangedTriggersCount] = useState(0);

  useEffect(() => {
    if (listBulkEditing?.length < 2) {
      window.location.href = "/extractor";
    }
  }, []);

  const replaceBulkApi = async (bulkTriggers) => {
    try{
      dispatch(updateLoader(true));
      dispatch(
        updateBulkTrigger({
          payload : { "triggers": transformedData(bulkTriggers,changedTriggers) },
          loading: (msg) => {
            dispatch(updateLoader(false));
            setCompletion({ message: msg, operation: "Success" });
          },
          setBulkSuccessModal: setBulkSuccessModal
        })
      );
    }catch(err){
      setCompletion({ message:"Fail",operation:"" });
    }
  }

  useEffect(() => {
    if(bulkSuccessModal){
      setTimeout(() => {
        setBulkSuccessModal(false);
        window.location.href="/extractor";
      },5*1000);
    }
  },[bulkSuccessModal]);

  const handleBulkEditing = (type, payload) => {
    switch (type) {
      case "SET_CURRENT_TRIGGER":
        setCurrentTrigger({ ...payload }); 
        break;
      case "UPDATE_BULK":
        const { currentData, replaceWith } = payload;
        const obj = { ...changedTriggers };
        let count = 0;
        let currentTriggerTemp = null;
        let updatedTriggers = [...bulkTriggers];
        if (payload.attributeType === "UPDATE_BULK_DATES") {
          const [fromDate, toDate] = currentData.id.split("###");
          const new_date = { fromDate: replaceWith.startDate, toDate: replaceWith.endDate, dateType: replaceWith.label };
          updatedTriggers = updatedTriggers.map((trigger,index) => {
            let updateDate = false;
            const originalTrigger = listBulkEditing[index];
            //dynamic date check
            if(fromDate && !toDate && !fromDate.toLowerCase().includes("custom") && fromDate.trim().toLowerCase() === originalTrigger?.dateRange?.dateType){
              updateDate = true;
            }else if(originalTrigger.dateRange.fromDate === fromDate && originalTrigger.dateRange.toDate === toDate){
              updateDate = true;
            }
            if(updateDate){
              if(trigger._id === currentTrigger._id){
                currentTriggerTemp = { ...trigger, dateRange: { ...new_date } };
              }
              obj[trigger._id] = trigger._id;
              count += 1;
              return { ...trigger, dateRange: { ...new_date } };
            }
            return trigger;
          });
        } else {
          const { id, name } = replaceWith;
          updatedTriggers = updatedTriggers.map((trigger,index) => {
            const originalTrigger = listBulkEditing[index];
            if(originalTrigger?.storageData?.existingSheet?.id === currentData?.id && (!["new-sheet"].includes(originalTrigger?.storageData?.writeMode?.id))){
              const updatedTrigger =  { ...trigger, storageData: { ...trigger.storageData, existingSheet: { id,name }, sheetNomenclature: name } };
              if(trigger._id === currentTrigger._id){
                currentTriggerTemp = { ...updatedTrigger };
              }
              obj[trigger._id] = trigger._id;
              count += 1;
              return { ...updatedTrigger };
            }
            return trigger;
          });
        }
        setBulkTriggers(updatedTriggers);
        currentTriggerTemp && setCurrentTrigger({ ...currentTriggerTemp });
        setChangedTriggers(obj);
        setConfirmBulkUpdate("APPLY_CHANGES");
        setChangedTriggersCount(count);
        break;
    }
  }

  useEffect(() => {
    if(confirmBulkUpdate === "CHANGES_APPLIED"){
      const timeout = setTimeout(() => setConfirmBulkUpdate("DEFAULT"), 5 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [confirmBulkUpdate]);

  return (
    <>
      <Appbar />
      <Box sx={{ width: "100%", padding: "124px 72px 10px 72px", height: "100vh", overflow: "scroll" }}>
        <NavigationPane
          bulkTriggers={bulkTriggers}
          sheets={sheets} 
          dates={dates}
          handleBulkEditing={handleBulkEditing}
          changedTriggers={changedTriggers}
          infoComplete = {infoComplete}
        />
        <Extractor
          updateId={currentTrigger?._id ?? ""}
          extractData={currentTrigger ?? {}}
          setExtractData={() => { }}
          setTriggerId={() => { }}
          setCompletion={setCompletion}
          extra={{
            type: "BULK_EDITING",
            handler: handleBulkEditing,
            listBulkEditing: bulkTriggers,
            changedTriggers: changedTriggers,
            confirmBulkUpdate: confirmBulkUpdate,
            setInfoComplete: setInfoComplete
          }}
        />
        <div style={{ height: "64px", width: "100%", background: "#FFF", padding: "12px", borderRadius: "12px ", marginTop: "12px", display: "flex", justifyContent: "end" }}>
          <Button disableRipple
            sx={{
              backgroundColor: "#0869FB",
              variant: "contained",
              "&:hover": {
                backgroundColor: "#0869FB",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "#ffffff",
              ":disabled": { color: "#0869FB88", backgroundColor: "#EBF3FF" },
            }}
            disabled={Object.keys(changedTriggers).length === 0 || Object.values(infoComplete).includes(false) || user.role === "viewer"}
            onClick={() => replaceBulkApi(bulkTriggers)}
          >
            Update Trigger
          </Button>
        </div>
      </Box>
      <FailureModal
        modal={completion.message === "Fail"}
        setModal={() => setCompletion({ message: "none", operation: "none" })}
        message={`Bulk updation failed`}
      />
      <ConfirmModal
        modal={confirmBulkUpdate}
        setModal={(args) => setConfirmBulkUpdate(args)}
        message={`Your changes have been applied to ${changedTriggersCount} triggers.`}
      />
      <SuccessModal
        modal={bulkSuccessModal}
        setModal={(arg) => {
          setBulkSuccessModal(arg);
          window.location.href="/extractor";
        }}
        message={`All updates have been saved successfully.`}
      />
    </>
  );
}
