import React from "react";
import { Drawer, IconButton, Typography, Box, Tab, Tabs } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../styles/editwidget.css";
import "../../styles/sidebar.css";
import { styled } from "@mui/material/styles";
import ChartSettings from "./ChartSettings";
import EditChart from "./EditChart";
import GridSettings from "../EditHeader/GridSettings";
import EditHeader from "../EditHeader/EditHeader";
import { EVENTS, transformMixPanelData } from "../../utils/mixpanel";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/boxless-close.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Sidebar({
  editDrawerState,
  onClose,
  drawerWidth,
  handleAllChartsForceReload,
}) {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { chart, handleChartChange } = editDrawerState;
  const [chartType, setChartType] = React.useState("");
  const [newChart, setNewChart] = React.useState(false);
  const [renderNew, setRenderNew] = React.useState(false);
  const isSaveAndUpdateClick = useSelector(
    (state) => state.channel.isSaveAndUpdateClick
  );

  const updateChart = (newChartType) => {
    if (newChartType !== chartType || newChartType !== chart.type) {
      handleChartChange("type", newChartType, true);
      setChartType(newChartType);
    }
    setNewChart(true);
    setRenderNew(!renderNew);
  };

  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingBottom: "90px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  return (
    <Drawer
      data-tut="sidebar_widget"
      sx={{
        width: drawerWidth,
        background: "#f8f9fd00",
        position: "relative !important",
        left: "auto",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          // height: "calc(100% - 110px) ",
          top: "124px",
          borderRadius: "12px",
          boxShadow: "12px 12px 48px 0px rgba(80, 80, 159, 0.04)",
          marginRight: "70px",
          // border: '1px solid #EAEAEC',
        },
      }}
      hideBackdrop={true}
      variant="temporary"
      anchor="right"
      open={editDrawerState.isOpen}
      onClose={() => {
        if (chart) {
          handleChartChange("close");
        }
        setNewChart(false);
        onClose();
      }}
    >
      {/* <DrawerHeader /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "26px",
          position: "relative",
          padding: "0px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Lato",
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "26px",
          }}
        >
          {chart ? "Edit Widget" : "Edit Header"}
        </Typography>
        <IconButton
          data-tut="close_sidebar"
          onClick={() => {
            if (chart) {
              handleChartChange("close");
            }
            setNewChart(false);
            onClose();
          }}
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Box sx={{ width: "100%", padding: "0px 16px" }}>
        <Box className="sidebar-tabs">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="Edit Widget"
            sx={{
              // padding: '0px 16px',
              "&  .Mui-selected": {
                backgroundColor: "#0869FB",
                borderRadius: "6px",
                color: "#FFF !important",
              },
              "& .MuiTabs-indicator": { display: "none" },
            }}
          >
            <Tab label="Data" />
            <Tab
              label="Settings"
              disabled={false}
              onClick={() =>
                isSaveAndUpdateClick === "UPDATE_CHART" &&
                transformMixPanelData(EVENTS.view_chart_settings, chart)
              }
            />
          </Tabs>
        </Box>
        <TabPanel
          style={{
            width: "100%",
          }}
          value={currentTab}
          index={0}
        >
          {chart ? (
            <EditChart
              key={chartType + newChart + renderNew}
              handleAllChartsForceReload={handleAllChartsForceReload}
              editDrawerState={editDrawerState}
              setChartType={updateChart}
              newChart={editDrawerState.chart?.type === "ADD" ? true : newChart}
            />
          ) : (
            <EditHeader key={chartType} editDrawerState={editDrawerState} />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {chart ? (
            <ChartSettings editDrawerState={editDrawerState} />
          ) : (
            <GridSettings editDrawerState={editDrawerState} />
          )}
        </TabPanel>
      </Box>
    </Drawer>
  );
}
