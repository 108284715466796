import { useEffect, useState } from "react";
import { Grid, Button, TextField, Tooltip } from "@mui/material";
import { AdsList, AttributionList, ChannelType, GetChannel } from "../../../models/channel";
import DataList from "../BlendDataSource/DataList";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PipeIcon } from "../../../Assets/Images/pipe.svg";
import CreateCustomDim from "./CreateCustomDim";
import { fetchMapping, getAccountBasedColumns, refreshMapping } from "../../../api/channel/saga";
import CustomLoader from "../../Common/CustomLoader";
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Scrollbars from "react-custom-scrollbars";
import { capitalizeString } from "../../CustomizingSources/Utils";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit-icon.svg";
import { updateLoader } from "../../../api/user/action";


export default function SelectDimSources({ updateId, columnData, setColumnData, resetData, setCompletion }) {
    const selectedAccounts = useSelector(state => state.channel.selectedAccounts);
    const authenticatedAccounts = useSelector(state => state.channel.authenticatedAccounts);
    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelAccountSelected = channel => channel.type in selectedAccounts;

    const [error, setError] = useState("");
    const [mappingData, setMappingData] = useState(null);
    const [srcChannel, setSrcChannel] = useState(GetChannel(columnData?.data_source));
    const [refChannel, setRefChannel] = useState(GetChannel(columnData?.mapped_ds));
    const [srcTable, setSrcTable] = useState((columnData?.table || columnData?.base_dimension?.reportType) ?? null);
    const [refTable, setRefTable] = useState((columnData?.refTable || columnData?.mapped_dimension?.reportType) ?? null);
    const [srcDimension, setSrcDimension] = useState(columnData?.base_dimension);
    const [refDimension, setRefDimension] = useState(columnData?.mapped_dimension);
    const [dataLevel, setDataLevel] = useState(columnData?.base_dimension?.dataLevel ? { id: columnData.base_dimension.dataLevel?.toUpperCase(), name: columnData.base_dimension.dataLevel?.toUpperCase() } : null);

    const dispatch = useDispatch();
    const state = useSelector(state => srcChannel?.getState(state));
    const tables = state?.tables;
    const dataLevels = (state?.dataLevels?.[srcTable?.id] ?? []).map(level => ({ id: level, name: level }));
    const srcAccountList = (state?.accountData?.accountList ?? []).map((acc, index) => ({ id: index, name: acc.title, ...acc }));
    const [srcAccount, setSrcAccount] = useState(selectedAccounts[srcChannel?.type]);
    // const [srcAccount, setSrcAccount] = useState(columnData?.srcAccount);
    const [srcAccountCols, setSrcAccountCols] = useState(null);
    const baseDimensions = state?.dimensions ? (Array.isArray(state.dimensions) ? state.dimensions : state.dimensions[srcTable?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ?? []) : [];
    const customDimensions = srcAccountCols ? srcAccountCols.dimensions : state?.customDimensions ? (Array.isArray(state.customDimensions) ? state.customDimensions : state.customDimensions?.[srcTable?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ?? []) : [];
    const dimensions = baseDimensions.concat(customDimensions);

    const refState = useSelector(state => refChannel?.getState(state));
    const refTables = refState?.tables;
    const refAccountList = (refState?.accountData?.accountList ?? []).map((acc, index) => ({ id: index, name: acc.title, ...acc }));
    const [refAccount, setRefAccount] = useState(selectedAccounts[refChannel?.type]);
    // const [refAccount, setRefAccount] = useState(columnData?.refAccount);
    const [refAccountCols, setRefAccountCols] = useState(null);
    const refBaseDimensions = refState?.dimensions ? (Array.isArray(refState.dimensions) ? refState.dimensions : refState.dimensions[refTable?.id] ?? []) : [];
    const refCustomDimensions = refAccountCols ? refAccountCols.dimensions : refState?.customDimensions ? (Array.isArray(refState.customDimensions) ? refState.customDimensions : refState.customDimensions?.[refTable?.id] ?? []) : [];
    const refDimensions = refBaseDimensions.concat(refCustomDimensions);

    const [displayNext, setDisplayNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [initialChannelUpdate, setInitialChannelUpdate] = useState(true);
    const [initialTableUpdate, setInitialTableUpdate] = useState(true);
    const [initialRefChannelUpdate, setInitialRefChannelUpdate] = useState(true);
    const [initialRefTableUpdate, setInitialRefTableUpdate] = useState(true);

    const [dimError, setDimError] = useState(false);
    useEffect(() => {
        var dimensionsIds = baseDimensions.map((e) => e.id);
        var refDimensionsIds = refDimensions.map((e) => e.id);

        if (srcDimension && !(dimensionsIds.indexOf(srcDimension?.id) > -1)) {
            setDimError("Base");
        } else if (refDimension && !(refDimensionsIds.indexOf(refDimension?.id) > -1)) {
            setDimError("Reference");
        } else {
            setDimError(false);
        }
    }, [srcAccountCols, refAccountCols, srcDimension, refDimension])

    useEffect(() => {
        if (!srcChannel?.isBlend && srcAccount) {
            dispatch(updateLoader(true))
            getAccountBasedColumns(srcAccount, srcChannel).then(data => {
                let columns = data?.columns ?? [];
                setSrcAccountCols({
                    metrics: columns.filter(c => c.type === "METRIC"),
                    dimensions: columns.filter(c => c.type === "DIMENSION")
                });
            }).catch(err => {
                console.log("🚀 ~ Custom Dim Src ~ getAccountBasedColumns ~ err:", err)
                setSrcAccountCols({ metrics: [], dimensions: [] })
            }).finally(() => {
                dispatch(updateLoader(false))
            });

        } else {
            setSrcAccountCols(null);
        }
    }, [srcAccount]);

    useEffect(() => {
        if (!refChannel?.isBlend && refAccount) {
            dispatch(updateLoader(true))
            getAccountBasedColumns(refAccount, refChannel).then(data => {
                let columns = data?.columns ?? [];
                setRefAccountCols({
                    metrics: columns.filter(c => c.type === "METRIC"),
                    dimensions: columns.filter(c => c.type === "DIMENSION")
                });
            }).catch(err => {
                console.log("🚀 ~ Custom Dim Ref ~ getAccountBasedColumns ~ err:", err)
                setRefAccountCols({ metrics: [], dimensions: [] })
            }).finally(() => {
                dispatch(updateLoader(false))
            });
        } else {
            setRefAccountCols(null);
        }
    }, [refAccount]);


    useEffect(() => {
        if (srcChannel && srcDimension && !srcAccount) {
            setError(`Account not selected for base source - ${srcChannel.type}`);
        } else if (refChannel && refDimension && !refAccount) {
            setError(`Account not selected for reference source - ${refChannel.type}`);
        } else {
            setError("");
        }
    }, [columnData])

    useEffect(() => {
        if (initialChannelUpdate) {
            setInitialChannelUpdate(false);
        } else {
            setDataLevel(null);
            setSrcTable(null);
            setSrcDimension(null);
            setDisplayNext(false);
            setColumnData({
                ...columnData, data_source: srcChannel.type, base_dimension: null
            });
            // setSrcAccount(null);
            setSrcAccount(selectedAccounts[srcChannel.type]);
        }
    }, [srcChannel])

    useEffect(() => {
        if (initialRefChannelUpdate) {
            setInitialRefChannelUpdate(false);
        } else {
            setRefTable(null);
            setRefDimension(null);
            setDisplayNext(false);
            setColumnData({
                ...columnData, mapped_ds: refChannel.type, mapped_dimension: null
            });
            // setRefAccount(null);
            setRefAccount(selectedAccounts[refChannel.type]);
        }
    }, [refChannel])

    useEffect(() => {
        if (initialTableUpdate) {
            setInitialTableUpdate(false);
        } else {
            setDataLevel(null);
            setDisplayNext(false);
            setSrcDimension(null);
            setColumnData({
                ...columnData, table: srcTable, base_dimension: null
            })
        }
        // if (srcTable && state?.tablesUpdated && !state?.tablesUpdated?.[srcTable.id]) {
        //     dispatch(srcChannel.actions?.getColumns(srcTable));
        // }
    }, [srcTable])

    useEffect(() => {
        if (initialTableUpdate) {
            setInitialTableUpdate(false);
        } else {
            setDisplayNext(false);
            setSrcDimension(null);
            setColumnData({
                ...columnData, table: srcTable, base_dimension: null
            })
        }
    }, [dataLevel])

    useEffect(() => {
        if (initialRefTableUpdate) {
            setInitialRefTableUpdate(false);
        } else {
            setDisplayNext(false);
            setRefDimension(null);
            setColumnData({
                ...columnData, refTable, mapped_dimension: null
            })
        }
    }, [refTable])

    useEffect(() => {
        setDisplayNext(false);
        setMappingData(null);
    }, [srcChannel, refChannel, srcAccount, refAccount, srcTable, refTable, dataLevel, srcDimension, refDimension])

    const fetchData = ({ refresh }) => {
        if (columnData.name &&
            srcChannel &&
            (!tables || srcTable) && srcDimension &&
            refChannel
            && (!refTables || refTable) && refDimension
        ) {
            setIsLoading(true);
            setDisplayNext(true);
            const dimesionData = {
                ...columnData,
                base_dimension: { ...columnData.base_dimension, reportType: columnData.table || columnData?.base_dimension?.reportType },
                mapped_dimension: { ...columnData.mapped_dimension, reportType: columnData.refTable || columnData?.mapped_dimension?.reportType }
            }
            delete dimesionData.table;
            delete dimesionData.refTable;

            const metadata = {
                customDimension: dimesionData,
                miscBaseInfo: {
                    ...srcChannel.transformer.getAccountId(srcAccount),
                    ...(srcChannel.transformer.getTableObject?.(srcTable) ?? {}),
                    dataLevel: dataLevel?.id
                },
                miscRefInfo: {
                    ...refChannel.transformer.getAccountId(refAccount),
                    ...(refChannel.transformer.getTableObject?.(refTable) ?? {})
                },
                baseAccountId: srcChannel.type === ChannelType.GoogleAds ? srcAccount.customerId : srcAccount.accountId.toString(),
                refAccountId: refAccount.viewId
            }

            let promise = !refresh ? fetchMapping(metadata) : refreshMapping(metadata);
            promise.then(data => {
                setMappingData({ ...data, refresh: refresh ?? data.refresh, metadata });
            }).catch(err => {
                setError("Fetch mapping failed for above source dimensions!");
                setDisplayNext(false);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setError("Missing data!")
        }
    }
    useEffect(() => {
        if (initialChannelUpdate) {
            setInitialChannelUpdate(false);
        } else {
            setColumnData({
                ...columnData, dataLevel: dataLevel
            })
        }
    }, [dataLevel]);

    return (
        <Grid container item xs={12} style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", overflowX: "scroll" }}>
            <Grid container item xs={4} className="displayRowCenter" style={{ paddingTop: 0, minHeight: "100%", minWidth: "35%" }}>
                <div className="gridItem" style={{ height: "90vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                    {/* custom dimension name type */}
                    <div className="displayRowCenter">
                        <EditIcon width={20} height={20} />
                        <TextField
                            className="filter-name"
                            sx={{ marginLeft: "12px", height: "50px", maxHeight: "50px", justifyContent: "center", width: "100%" }}
                            variant="standard"
                            placeholder="Name Custom Dimension*"
                            defaultValue={columnData.name}
                            required
                            onChange={e => setColumnData({ ...columnData, name: e.target.value })}
                            InputProps={{ disableUnderline: true, style: { fontSize: "10px" } }}
                            inputProps={{ style: { fontSize: "18px" } }}
                        />
                        <CloseIcon className="pointer" onClick={resetData} />
                    </div>
                    <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px 15px" }} />
                    <Scrollbars autoHide style={{ height: "80%" }}>
                        {/* source type */}
                        <div style={{ marginBottom: "25px" }}>
                            <h4 className="inter bold" >
                                Where do you want to create custom dimension?
                            </h4>
                            <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Data Source
                            </h5>
                            <DataList
                                channel={srcChannel}
                                metric={srcChannel && { id: srcChannel?.type, name: srcChannel?.title }}
                                metrics={AdsList
                                    .filter(channel => (channel.type !== refChannel?.type && isChannelAuthenticated(channel)))
                                    .map(channel => ({
                                        id: channel.type, name: channel.title, icon: channel.icon, isBlend: channel.isBlend,
                                        // disabled: !isChannelAccountSelected(channel)
                                    }))
                                }
                                isEditable={true}
                                onClick={channelObject => setSrcChannel(GetChannel(channelObject.isBlend ? channelObject : channelObject.id))}
                                defaultTitle="Choose Source"
                            />
                        </div>

                        {/* source account */}
                        {srcAccount && <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                                    Selected Account
                                </h4>
                            </div>
                            <DataList
                                channel={srcChannel}
                                metric={srcAccount}
                                metrics={[]}
                                isEditable={false}
                                width={200}
                                defaultTitle="Add Account name"
                            />
                        </div>}

                        {/* <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px" }}>
                                <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Account Id
                                </h5>
                            </div>
                            <DataList
                                key={srcAccount?.title + srcAccount?.subtitle}
                                channel={srcChannel}
                                metric={srcAccount}
                                metrics={srcAccountList}
                                isEditable={true}
                                onClick={accObj => {
                                    setSrcAccount(accObj);
                                    setColumnData({ ...columnData, srcAccount: accObj });
                                }}
                                defaultTitle="Add Account"
                            />
                        </div> */}

                        {/* report type */}
                        {tables && <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px", }}>
                                <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Report Type
                                </h5>
                            </div>
                            <DataList
                                channel={srcChannel}
                                metric={srcTable}
                                metrics={tables}
                                isEditable={!updateId}
                                width={200}
                                onClick={setSrcTable}
                                defaultTitle="Add table name"
                            />
                        </div>}

                        {/* data level */}
                        {srcChannel?.type === "tiktok-ads" && tables && <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px", }}>
                                <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Data Level
                                </h5>
                            </div>
                            <DataList
                                channel={srcChannel}
                                metric={dataLevel}
                                metrics={dataLevels}
                                isEditable={!updateId}
                                width={200}
                                onClick={setDataLevel}
                                defaultTitle="Add data level"
                            />
                        </div>}

                        {/* source dimension */}
                        <div style={{ marginBottom: "25px" }}>
                            <h4 className="inter bold"  >
                                Which is the base column?
                            </h4>
                            <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Base Column
                            </h5>
                            <DataList
                                channel={srcChannel}
                                metric={srcDimension}
                                metrics={dimensions}
                                isEditable={!updateId}
                                onClick={(dimension) => {
                                    setSrcDimension(dimension);
                                    setColumnData({ ...columnData, base_dimension: dimension });
                                }}
                                defaultTitle="Select Dimension"
                            />
                        </div>

                        {/* reference type */}
                        <div style={{ marginBottom: "25px" }}>
                            <h4 className="inter bold" >
                                Where do you want to create custom Dimension?
                            </h4>
                            <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Reference Source
                            </h5>
                            <DataList
                                channel={refChannel}
                                metric={refChannel && { id: refChannel?.type, name: refChannel?.title }}
                                metrics={AttributionList
                                    .filter(channel => (channel.type === ChannelType.GoogleAnalytics && isChannelAuthenticated(channel)))
                                    .map(channel => ({
                                        id: channel.type, name: channel.title, icon: channel.icon, isBlend: channel.isBlend,
                                        // disabled: !isChannelAccountSelected(channel)
                                    }))
                                }
                                isEditable={true}
                                onClick={channelObject => setRefChannel(GetChannel(channelObject.isBlend ? channelObject : channelObject.id))}
                                defaultTitle="Choose Source"
                            />
                        </div>

                        {/* reference account */}
                        {refAccount && <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px", display: "flex" }}>
                                <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                                    Selected Account
                                </h4>
                            </div>
                            <DataList
                                channel={refChannel}
                                metric={refAccount}
                                metrics={[]}
                                isEditable={false}
                                width={200}
                                defaultTitle="Add Account name"
                            />
                        </div>}
                        {/* <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px" }}>
                                <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Reference Account Id
                                </h5>
                            </div>
                            <DataList
                                key={refAccount?.title + refAccount?.subtitle}
                                channel={refChannel}
                                metric={refAccount}
                                metrics={refAccountList}
                                isEditable={true}
                                onClick={accObj => {
                                    setRefAccount(accObj);
                                    setColumnData({ ...columnData, refAccount: accObj });
                                }}
                                defaultTitle="Add Account"
                            />
                        </div> */}
                        {/* reference report type */}
                        {refTables && <div style={{ marginBottom: "25px" }}>
                            <div style={{ margin: "5px 0px", }}>
                                <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                    Reference Report Type
                                </h5>
                            </div>
                            <DataList
                                channel={refChannel}
                                metric={refTable}
                                metrics={refTables}
                                isEditable={!updateId}
                                onClick={setRefTable}
                                defaultTitle="Add table name"
                            />
                        </div>}

                        {/* reference dimension */}
                        <div style={{ marginBottom: "25px" }}>
                            <h5 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Reference Column
                            </h5>
                            <DataList
                                channel={refChannel}
                                metric={refDimension}
                                metrics={refDimensions}
                                isEditable={!updateId}
                                onClick={(dimension) => {
                                    setRefDimension(dimension);
                                    setColumnData({ ...columnData, mapped_dimension: dimension });
                                }}
                                defaultTitle="Select Dimension"
                            />
                        </div>
                    </Scrollbars>

                    <div style={{ position: "relative" }} >
                        <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px 20px", width: "calc(100% + 40px)" }} />
                        {
                            error && <h4 style={{
                                color: "red", textAlign: "right", borderRadius: "10px",
                                width: "100%", fontSize: "12px", marginTop: "15px"
                            }}><b>{error}</b>
                            </h4>
                        }
                        {dimError &&
                            <Tooltip
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-arrow': { color: 'white' },
                                        "& .MuiTooltip-tooltip": {
                                            backgroundColor: "white",
                                            color: '#333',
                                            borderRadius: "12px",
                                            filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                                            padding: "10px 25px"
                                        }
                                    }
                                }}
                                title={`${dimError} Dimension selected does not exist for the selected account! To continue, change the account ID or ${updateId ? "create a new custom dimension." : "change the selected dimension"}`}
                                arrow placement='top'
                            >
                                <WarningAmberIcon style={{ color: "#F3807D", position: "absolute", bottom: 8 }} />
                            </Tooltip>}
                        <Button
                            sx={{ backgroundColor: "#0869FB" }}
                            style={{ width: "50%", height: "40px", borderRadius: '8px', float: "right" }}
                            variant="contained"
                            color="primary"
                            disabled={dimError || !columnData.name ||
                                !srcChannel || !srcAccount || (tables && !srcTable) || !srcDimension ||
                                !refChannel || !refAccount || (refTables && !refTable) || !refDimension}
                            onClick={fetchData}
                        >
                            Proceed
                        </Button>
                    </div>

                </div>
            </Grid>
            {
                (displayNext && srcChannel && refChannel) ?
                    <>
                        {!isLoading && <PipeIcon width="100%" height="auto" />}
                        <Grid container item xs={8} flexDirection={"column"} className="displayRowCenter" style={{ paddingTop: 0, minHeight: "100%", minWidth: "60%" }}>
                            {
                                !isLoading && mappingData ?
                                    <div className="gridItem" style={{ minWidth: "200%", height: "90vh" }}>
                                        <CreateCustomDim
                                            updateId={updateId}
                                            channel={srcChannel}
                                            refChannel={refChannel}
                                            columnData={columnData}
                                            mappingData={mappingData}
                                            resetData={resetData}
                                            refresh={fetchData}
                                            setCompletion={setCompletion}
                                        />
                                    </div>
                                    : <CustomLoader />
                            }
                        </Grid>
                    </> : null
            }
        </Grid >
    )
}
