import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BlendDataSource from './BlendDataSource/BlendDataSource';
import CustomizeColumns from './CustomizeColumns/CustomizeColumns';
import { useSelector } from 'react-redux';
import IntroTour from '../Common/IntroTour';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function NavigationMenu() {
    const [value, setValue] = React.useState(0);

    const { showTutorial, run } = useSelector(state => state.user.tutorial) || {};

    React.useEffect(() => {
        if (showTutorial && run === true) {
            setValue(1);
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {showTutorial && <IntroTour source={"customizing-sources"} />}
            <Box sx={{ width: "100%", padding: "124px 72px 10px 72px" }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: "100%",
                    background: '#FFFFFF',
                    borderRadius: '12px',
                    padding: '0px 8px',
                    position: 'relative',
                    boxShadow: '12px 12px 48px 0px rgba(80, 80, 159, 0.04)'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab-Bar'>
                        <Tab label="Data Blend" {...a11yProps(0)} />
                        <Tab label="Custom Column" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <BlendDataSource />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CustomizeColumns />
                </TabPanel>
            </Box>
        </>
    );
}
