import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { NEW_FILTER } from "../../../models/chart";

export default function AccountsBox({ accounts = [], currAccount, setCurrAccount, accountError, dimensionFiltersRef }) {

    return (
        <Box
            sx={{
                marginTop: "20px",
                padding: "5px 10px 10px 0px"
            }}>
            <p>Select Account</p>
            <Autocomplete
                sx={{ m: 1, width: 300 }}
                style={{ marginBottom: 8, marginLeft: 0 }}
                options={accounts}
                getOptionLabel={(option) => option.name ?? option.title}
                value={currAccount}
                onChange={(event, newValue) => {
                    dimensionFiltersRef.current = [NEW_FILTER];
                    setCurrAccount(newValue);
                }}
                disableClearable={true}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => {
                    return <TextField
                        {...params}
                        size="small"
                        error={accountError}
                        label={accountError ? "Required" : "Choose Account"}
                    />
                }}
            />
        </Box>
    );
}
