import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "../../../styles/filterpopup.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import DataList from "../../CustomizingSources/BlendDataSource/DataList";
import CalendarIcon from "../../../Assets/Icons/calendar-icon.svg";
import { ReactComponent as AddIcon } from "../../../Assets/Icons/add_icon.svg";
import { ReactComponent as RemoveIcon } from "../../../Assets/Images/logos_remove_chart.svg";
import { ReactComponent as AndIcon } from "../../../Assets/Images/and.svg";
import { ReactComponent as Preview } from "../../../Assets/Icons/preview-icon.svg";
import { ReactComponent as FilterNew } from "../../../Assets/Icons/filter-icon.svg";
import DateRangeModal from "../../Modal/DateRangeModal";
import ToggleList from "../../Common/ToggleList";
import FilterPopup from "../../Filteration/filterpopup/FilterPopup";
import { Filter } from "../../../models/chart";
import "../../styles/profile.css";
import MultipleSelectCheckList from "../../Common/MultipleSelectCheckList";
import { ReactComponent as DeleteIcon } from "../../../Assets/Images/Delete.svg";
import { ReactComponent as EditFilterIcon } from "../../../Assets/Icons/openFilter.svg";
import { getWidgetData } from "../../../api/channel/saga";
import PreviewTable from "./PreviewTable";
import { ORDER_OPTIONS } from "../Utils";
import { transformFromFilterDataObject } from "../../Filteration/Utils";
import CustomLoader from "../../Common/CustomLoader";
import { EVENTS, transformMixPanelData } from "../../../utils/mixpanel";
import moment from "moment";

export const defaultOrderBy = {
  fieldName: null,
  sortOrder: ORDER_OPTIONS[0],
  fieldError: false,
};

const endDate = new Date();
endDate.setDate(endDate.getDate() - 1);
const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);
const viewDate = (date) => moment(date).format("MMM DD, YYYY");

export default function TriggerParameters({
  nameRef,
  channel,
  table,
  dataLevel,
  accounts,
  accountCols,
  loading,
  dateRange,
  updateDateRange,
  infoComplete,
  triggerData,
  setTriggerData,
  openScheduleBox,
  setOpenScheduleBox,
  extra
}) {
  // nameRef, dateRange, updateDateRange, infoComplete, triggerData, setTriggerData, setOpenScheduleBox
  // updateId, extractData, resetData, setCompletion
  const user = useSelector((state) => state.user.user);
  const state = useSelector((state) => channel.getState(state));
  const tableId = table?.id;
  const baseMetrics =
    (Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics[tableId + (dataLevel?.id ? `:${dataLevel.id}` : "")]) ??
    [];
  const baseDimensions =
    (Array.isArray(state.dimensions)
      ? state.dimensions
      : state.dimensions[
      tableId + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ]) ?? [];
  const customMetrics = accounts.reduce(
    (allCols, account) =>
      allCols.concat(accountCols[account.title]?.metrics ?? []),
    []
  );
  const customDimensions = accounts.reduce(
    (allCols, account) =>
      allCols.concat(accountCols[account.title]?.dimensions ?? []),
    []
  );
  const metrics = baseMetrics.concat(customMetrics);
  const dimensions = baseDimensions.concat(customDimensions);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [preview, setPreview] = useState({ open: false, data: null });
  const [currentFilter, setCurrentFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const updateTriggerData = () => {
    let customMetrics = accounts.reduce(
      (allCols, account) =>
        allCols.concat(accountCols[account.title]?.metrics ?? []),
      []
    );
    let customDimensions = accounts.reduce(
      (allCols, account) =>
        allCols.concat(accountCols[account.title]?.dimensions ?? []),
      []
    );

    var baseMetricsIds = baseMetrics.map((e) => e.id);
    var customMetricsIds = customMetrics.map((e) => e.id);
    var baseDimensionsIds = baseDimensions.map((e) => e.id);
    var customDimensionsIds = customDimensions.map((e) => e.id);

    var newMetrics = [],
      newAggregates = [];
    triggerData.metrics?.map((e, index) => {
      if (
        baseMetricsIds?.indexOf(e?.id) > -1 ||
        customMetricsIds?.indexOf(e?.id) > -1
      ) {
        newMetrics.push(e);
        newAggregates.push(triggerData.aggregates[index]);
      }
    });

    var newDimensions = [];
    triggerData.dimensions?.map((e, index) => {
      if (
        baseDimensionsIds?.indexOf(e?.id) > -1 ||
        customDimensionsIds?.indexOf(e?.id) > -1
      )
        newDimensions.push(e);
    });

    var newOrderBys = [];
    triggerData.orderBys?.map((e, index) => {
      if (
        baseDimensionsIds?.indexOf(e?.fieldName?.id) > -1 ||
        customDimensionsIds?.indexOf(e?.fieldName?.id) > -1
      )
        newOrderBys.push(e);
    });

    var allowedKeys = accounts?.map((e) => e.title);
    var existingKeys = Object.keys(triggerData.filters);

    var newFilters = {};
    existingKeys?.map((e) => {
      if (allowedKeys?.indexOf(e) > -1)
        newFilters = { ...newFilters, [e]: triggerData.filters[e] };
    });

    setTriggerData({
      ...triggerData,
      metrics: newMetrics,
      aggregates: newAggregates,
      dimensions: newDimensions,
      orderBys: newOrderBys,
      filters: newFilters,
    });
  };

  useEffect(() => {
    if (firstLoad) {
      if (Object.keys(accountCols).length === accounts.length) {
        setFirstLoad(false);
      }
    } else {
      updateTriggerData();
    }
  }, [accountCols, accounts]);

  const updateDimRows = (value) => {
    if (!isNaN(value)) {
      let dimensionRows = Number(value);
      if (dimensionRows > 0 && dimensionRows <= 1000000) {
        setTriggerData({ ...triggerData, numberOfRows: dimensionRows });
      }
    }
  };

  const previewData = () => {
    setPreview({ ...preview, open: true });
    transformMixPanelData(EVENTS.view_preview_extractor, nameRef.current);
    if (!preview.data) {
      setIsLoading(true);
      getWidgetData(nameRef.current, [
        {
          startDate: dateRange.fromDate,
          endDate: dateRange.toDate,
          ...channel.transformer.getAccountId(accounts[0]),
          ...channel.transformer.getApiObject(
            triggerData.metrics.map((metric) => metric.id),
            triggerData.dimensions.map((dimension) => dimension.id),
            triggerData.filters[accounts[0].accountId] ??
            Filter.new({ channelType: channel.type }),
            table,
            dataLevel?.id
          ),
          dataSource: channel.type,
        },
      ])
        .then(([data]) => {
          let headers = triggerData.dimensions.concat(triggerData.metrics);
          let rows = data.values.map((row) => {
            return headers.map(
              (head) => row[data.columns.findIndex((col) => col === head.id)]
            );
          });
          setPreview({ open: true, data: { headers, rows } });
        })
        .catch((err) => {
          console.log("🚀 ~ file: CreateTrigger.js:192 ~ ]).then ~ err:", err);
          setPreview({ ...preview, open: false });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const metricListParams =
    accounts.length > 1 && !triggerData.segregate
      ? {
        aggregate: triggerData.aggregates,
        subMenu: ["Sum", "Average", "Minimum", "Maximum"],
        onSubListChange: (aggregates) =>
          setTriggerData({ ...triggerData, aggregates }),
      }
      : {};

  useEffect(() => {
    if (!triggerData.segregate) {
      const diff = triggerData.metrics.length - triggerData.aggregates.length;
      if (diff > 0) {
        setTriggerData({
          ...triggerData,
          aggregates: triggerData.aggregates.concat(Array(diff).fill("Sum")),
        });
      }
    }
  }, [triggerData.segregate, accounts.length]);

  useEffect(() => {
    setPreview({ ...preview, data: null });
    const mappedColumn = [...triggerData.metrics, ...triggerData.dimensions].reduce((res, curr) => { res[curr.id] = curr.id; return res; }, {});
    const ordersByTemp = triggerData.orderBys.filter(orderBy => mappedColumn[orderBy?.fieldName?.id]);
    setTriggerData({
      ...triggerData,
      orderBys:  ordersByTemp.length === 0 ? [{...defaultOrderBy}] : ordersByTemp
    });
  }, [triggerData.metrics, triggerData.dimensions]);

  const filterAttribues = (attribute) => {
    return !triggerData.orderBys
      .map((order) => order.fieldName?.id)
      .includes(attribute.id);
  };
  useEffect(() => {
    extra?.type === "BULK_EDITING" && setFirstLoad(true);
  },[extra?.extractData ?? {}]);
  const isBulkEditing = extra?.type === "BULK_EDITING";
  return loading ? (
    <Box className="loader">
      <CustomLoader
        heading={"Waiting for account columns data"}
        subHeading=""
      />
    </Box>
  ) : (
    <Box className="trigger-form">
      <div
        className="extractor_name"
        style={{
          borderBottom: "1px solid #eee",
          paddingBottom: "12px",
          paddingLeft: "16px",
          paddingTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className="extractorHeaders">Extractor</Typography>
        <IconButton
          style={{ width: 35, height: 35, marginRight: "16px", padding: "0px" }}
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={() => previewData()}
          disabled={
            !triggerData.metrics.length
          }
        >
          <Preview
            fill={
              !triggerData.metrics.length
                ? "#EBF3FF"
                : "white"
            }
            stroke={
              !triggerData.metrics.length
                ? "#0869FB88"
                : "#0869FB"
            } // #EAEAEC, #6D6E7C
          />
        </IconButton>
      </div>

      <Scrollbars autoHide style={{ minHeight: "388px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "15px",
            height: "100%",
            padding: "10px 20px",
          }}
        >
          <Grid container xs={12} style={{ marginBottom: "10px" }}>
            <Grid item xs={11.5}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">Metric*</Typography>
              </div>
              <MultipleSelectCheckList
                optionsList={metrics.filter(
                  (metric) => metric.group !== "Customized"
                )}
                value={triggerData.metrics}
                onChange={(metric) =>
                  setTriggerData({ ...triggerData, metrics: metric })
                }
                defaultTitle="Select Metric"
                {...metricListParams}
                channelType={channel?.type ?? ""}
                disabled={isBulkEditing}
              />
            </Grid>
          </Grid>

          {accounts.length > 1 && (
            <Grid item xs={5}>
              <FormControl sx={{ justifyContent: "center" }} size="small">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={triggerData.segregate}
                      onChange={(e) => {
                        setTriggerData({
                          ...triggerData,
                          segregate: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography className="newText">Segregate Rows</Typography>
                  }
                />
              </FormControl>
            </Grid>
          )}

          <Grid container xs={12} style={{ marginBottom: "10px" }}>
            <Grid item xs={11.5}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">
                  Dimension/Split By Rows
                </Typography>
              </div>
              <MultipleSelectCheckList
                optionsList={dimensions}
                value={triggerData.dimensions}
                onChange={(dimensions) =>
                  setTriggerData({ ...triggerData, dimensions })
                }
                defaultTitle="Select Dimension"
                disabled={isBulkEditing}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginBottom: "10px" }}>
            <Grid item xs={5}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">Date Range</Typography>
              </div>
              <DataList
                key={
                  viewDate(dateRange.fromDate) +
                  " - " +
                  viewDate(dateRange.toDate)
                }
                stretch={"100%"}
                channel={channel}
                metric={{
                  name:
                    viewDate(dateRange.fromDate) +
                    " - " +
                    viewDate(dateRange.toDate),
                }}
                startAdornment={{
                  onClick: () => !isBulkEditing && setIsDatePickerOpen(true),
                  src: CalendarIcon,
                }}
                isEditable={false}
                defaultTitle="Date Range"
                disabled={isBulkEditing}
              />
            </Grid>
            <Grid item xs={5}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText"># Of Rows To Fetch</Typography>
              </div>
              <ToggleList
                value={triggerData.numberOfRows}
                onUp={() => updateDimRows(triggerData.numberOfRows + 1)}
                onDown={() => updateDimRows(triggerData.numberOfRows - 1)}
                onChange={(value) => updateDimRows(value)}
                isEditable
                disabled={isBulkEditing}
              />
            </Grid>
          </Grid>

          {triggerData.orderBys.map((orderBy, orderIndex) => (
            <Grid container xs={12} style={{ marginBottom: "10px" }}>
              <Grid item xs={5}>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography className="newText">
                    {orderIndex === 0 ? "Sort By" : "Then By"}
                  </Typography>
                </div>
                <DataList
                  metric={orderBy.fieldName}
                  metrics={triggerData.dimensions
                    .concat(triggerData.metrics)
                    .filter((att) => filterAttribues(att))}
                  isEditable={true}
                  error={orderBy.fieldError}
                  onClick={(attribute) => {
                    triggerData.orderBys[orderIndex].fieldError = false;
                    triggerData.orderBys[orderIndex].fieldName = attribute;
                    setTriggerData({ ...triggerData });
                  }}
                  defaultTitle="Chooose Attribute"
                  disabled={isBulkEditing}
                />
              </Grid>
              <Grid item xs={5}>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography className="newText">Order</Typography>
                </div>
                <DataList
                  metric={orderBy.sortOrder}
                  metrics={ORDER_OPTIONS}
                  isEditable={true}
                  onClick={(sortOrder) => {
                    triggerData.orderBys[orderIndex].sortOrder = sortOrder;
                    setTriggerData({ ...triggerData });
                  }}
                  defaultTitle="Chooose Order"
                  disabled={isBulkEditing}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  padding: "5px",
                }}
              >
                {orderIndex === triggerData.orderBys.length - 1 ? (
                  <>
                    <AddIcon
                      style={{ height: "28px", width: "28px",pointerEvents: isBulkEditing ? "none" : "all" }}
                      onClick={() => {
                        if (orderBy.fieldName?.id) {
                          triggerData.orderBys.push({ ...defaultOrderBy });
                        } else {
                          triggerData.orderBys[orderIndex].fieldError = true;
                        }
                        setTriggerData({ ...triggerData });
                      }}
                    />
                    {orderIndex !== 0 && (
                      <RemoveIcon
                        style={{
                          height: "28px",
                          width: "28px",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          setTriggerData({
                            ...triggerData,
                            orderBys: triggerData.orderBys.slice(
                              0,
                              triggerData.orderBys.length - 1
                            ),
                          });
                        }}
                      />
                    )}
                  </>
                ) : (
                  <AndIcon style={{ height: "28px", width: "28px",pointerEvents: isBulkEditing ? "none" : "all" }} />
                )}
              </Grid>
            </Grid>
          ))}

          <Grid container xs={12} style={{}}>
            <Grid item xs={12}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">Filter</Typography>
              </div>
              <Button
                disabled={
                  !accounts.filter(
                    (acc) =>
                      !Object.keys(triggerData.filters).includes(acc.title)
                  ).length
                }
                sx={{
                  fontFamily: "Inter",
                  justifyContent: "flex-start",
                  backgroundColor: "#FFF",
                  border: "1px solid #EAEAEC",
                  borderRadius: "8px",
                  height: "40px",
                  width: "95%",
                  padding: "12px",
                  margin: "8px 0px",
                  textTransform: "inherit",
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#ABACB4",
                }}
                fullWidth
                variant="outlined"
                size="small"
                startIcon={<FilterNew />}
                onClick={() => {
                  setIsFilterOpen(true);
                  setCurrentFilter(null);
                }}
                disabled={isBulkEditing}
              >
                Add New Filter
              </Button>
            </Grid>
            {Object.values(triggerData.filters).length ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "1px solid #DDDDDD",
                  borderRadius: "4px",
                  padding: "10px",
                }}
              >
                {Object.values(triggerData.filters).map(
                  (appliedFilter, index, filters) => {
                    const name = appliedFilter.filterName;
                    const filterGroups =
                      appliedFilter.filterGrouping?.filterGroups ?? [];
                    const segments =
                      appliedFilter.additionalFilters[0]?.values?.filter(
                        (val) => val
                      );
                    const unify =
                      appliedFilter.additionalFilters[0]?.unify_data;
                    return (
                      <div style={{ padding: "10px", width: "100%", borderBottom: filters.length < index + 2 ? "0px" : "1px solid rgba(0, 0, 0, 0.1)", }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              marginBottom: "10px",
                              width: "100%",
                              overflowWrap: "break-word"
                            }}
                          >
                            {name}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#787878",
                              marginRight: "20px",
                              overflowWrap: "anywhere"
                            }}
                          >
                            Account: {appliedFilter.account.title}, Report
                            Type: {appliedFilter.reportType ?? "-"}, Filter:{" "}
                            {filterGroups.reduce(
                              (filterString, filterGroup, index) => {
                                filterString +=
                                  " " +
                                  filterGroup.filters.reduce(
                                    (filterString, filter, index) => {
                                      return (
                                        filterString +
                                        " " +
                                        filter.fieldName +
                                        " " +
                                        (filter.not ? "NOT " : "") +
                                        filter.operator +
                                        " " +
                                        filter.expressions +
                                        " " +
                                        (filterGroup.filters.length ===
                                          index + 1
                                          ? ""
                                          : filterGroup.operator)
                                      );
                                    },
                                    ""
                                  );
                                if (filterGroups.length !== index + 1) {
                                  filterString +=
                                    " " + appliedFilter.operator;
                                }
                                return filterString;
                              },
                              ""
                            )}
                            {segments && segments.length ? (
                              <span>
                                <br />
                                Segments: {segments.join(", ")}
                                {unify && " Unify: " + unify.value}
                              </span>
                            ) : null}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              minWidth: "70px",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                delete triggerData.filters[
                                  appliedFilter.account.title
                                ];
                                setTriggerData({ ...triggerData });
                              }}
                              style={{
                                background: "#F8F8F8",
                                borderRadius: 0,
                                height: 30,
                                width: 30,
                              }}
                            >
                              <DeleteIcon style={{ height: "16px" }} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setIsFilterOpen(true);
                                setCurrentFilter({
                                  ...appliedFilter,
                                  filterGroups,
                                  name,
                                });
                              }}
                              style={{
                                background: "#F8F8F8",
                                borderRadius: 0,
                                height: 30,
                                width: 30,
                              }}
                            >
                              <EditFilterIcon
                                style={{ height: "16px", width: "16px" }}
                              />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Scrollbars>
      {!openScheduleBox && (
        <div
          className="footer_para"
          style={{
            width: "100%",
            height: "72px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 16px",
          }}
        >
          <Button
            onClick={() => {
              setOpenScheduleBox(true);
            }}
            disabled={user.role === "viewer" || !infoComplete.triggerComplete}
            variant="contained"
            sx={{
              backgroundColor: "#0869FB",
              variant: "contained",
              "&:hover": {
                backgroundColor: "#0869FB",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "#ffffff",
              ":disabled": { color: "#0869FB88", backgroundColor: "#EBF3FF" },
            }}
          >
            Next
          </Button>
        </div>
      )}
      {isDatePickerOpen && <DateRangeModal
        dateRange={dateRange}
        setDateRange={updateDateRange}
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
      />}
      {isFilterOpen && (
        <FilterPopup
          accounts={
            currentFilter
              ? []
              : accounts.filter(
                (acc) => !Object.keys(triggerData.filters).includes(acc.title)
              )
          }
          accountCols={accountCols}
          chart={{
            channelType: channel.type,
            table,
            dataLevel: dataLevel?.id,
            reportFilter: true,
          }}
          onClose={() => setIsFilterOpen(false)}
          currentFilter={
            currentFilter
              ? transformFromFilterDataObject(currentFilter)
              : Filter.new({ channelType: channel.type })
          }
          onChange={(filter) =>
            setTriggerData({
              ...triggerData,
              filters: {
                ...triggerData.filters,
                [filter.account.title]: filter,
              },
            })
          }
        />
      )}
      {preview.open && (
        <PreviewTable
          heading={nameRef.current}
          preview={preview}
          isLoading={isLoading}
          onClose={() => setPreview({ ...preview, open: false })}
        />
      )}
    </Box>
  );
}
